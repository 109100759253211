import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";

import IconButton from "@mui/material/IconButton";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import Barra from "../componentes/barra_usuarioEdit";
import {
  getusersID,
  putUsers,
  getGrupoMensagem,
  deleteGrupoMensagem,
  deleteGrupooperacoes,
} from "../service/CRUD_Service";

import api from "../api";

export default function UsuarioEdit({ history }) {
  const { id } = history.location.state;
  const [users, setUsers] = useState({});
  const [usersErro, setUsersErro] = useState("");
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [contato, setContato] = useState("");
  const [tipo, setTipo] = useState("");
  const [list, setList] = useState([{}]);
  const [tipomensagem, setTipomensagem] = useState();
  const [grupoMensagem, setGrupoMensagem] = useState();
  const [btntipomensagem, setBtntipomensagem] = useState();
  const [btntoperacao, setBtnoperacao] = useState();
  const [tipoperacao, setTipoperacao] = useState();
  const [loading, setLoading] = useState(false); //set para antes de carregar a pagina aparecer loading

  const [tpoperacoes, setTpoperacoes] = useState();

  //tipo de operacao
  useEffect(() => {
    api
      .get("/operacoes")
      .then((res) => {
        setTipoperacao(res.data);
        console.log("operacoes", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //tipo de mensagem
  useEffect(() => {
    api
      .get("/tipomensagem")
      .then((res) => {
        setTipomensagem(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //agrupamento de mensagem
  useEffect(() => {
    api
      .get("/agrupamensagem")
      .then((res) => {
        setGrupoMensagem(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getusersID(id)
      .then((res) => {
        setUsers(res);
        setName(res.name);
        setCpf(res.cpf);
        setTipo(res.tipo);
        setContato(res.contato);
        setTpoperacoes(res.Cad_operacoes);
      })
      .catch((err) => setUsersErro(err));
  }, [id]);

  useEffect(() => {
    reloadMensagem();
  }, []);

  const reloadMensagem = () => {
    setLoading(true);
    getGrupoMensagem()
      .then((res) => {
        setGrupoMensagem(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  ///atualiza a pagina
  const refreshPage = () => {
    window.location.reload();
  };

  // name
  const onChangename = (event) => {
    setName(event.target.value);
  };

  // cpf
  const onChangecpf = (event) => {
    setCpf(event.target.value);
  };
  // contato
  const onChangecontato = (event) => {
    setContato(event.target.value);
  };

  //tipo
  const onChangetipo = (event) => {
    setTipo(event.target.value);
  };

  //tipo
  const onChangetipoMensagem = (event) => {
    setBtntipomensagem(event.target.value);
  };

  // //tipo operacao
  const onChangeoperacao = (event) => {
    setBtnoperacao(event.target.value);
  };

  //comando para editar e salvar o usuario
  const toEdit = (event) => {
    event.preventDefault();

    users.name = name;
    users.cpf = cpf;
    users.tipo = tipo;
    users.contato = contato;

    putUsers(id, users)
      .then(() => history.push("/ListarUsers"))
      .catch((err) => setUsersErro(err));
  };

  const onList = () => {
    let ids = id; //id do usuario
    let filtrausers = grupoMensagem?.filter((x) => x.idusers == ids);

    let agrupamensagem = filtrausers?.map((item) => item.idmensagem);
    let filtramensagem = tipomensagem?.filter((x) =>
      agrupamensagem?.includes(x.id)
    );
    // setList([...list]);

    return filtramensagem;
  };

  let mensagem = onList();

  const onDelete = (mensagem) => {
    let idsuser = id;
    let filt = tipomensagem?.filter((x) => x.tipo == mensagem);
    let idmensagem = filt.map((item) => item.id);

    let filtraagrupa = grupoMensagem.filter((x) =>
      idmensagem.includes(x.idmensagem)
    );

    let filtrausers = filtraagrupa.filter((x) => x.idusers == idsuser);
    let idagrupamensagem = filtrausers.map((item) => item.id);

    deleteGrupoMensagem(idagrupamensagem)
      .then(() => {
        reloadMensagem();
      })
      .catch((err) => console.log(err));
  };

  const onDeleteAgrupoperacao = (id) => {
    deleteGrupooperacoes(id)
      .then(() => {
        refreshPage();
      })
      .catch((err) => console.log(err));
  };

  function metodoFiltraMensagem() {
    let dados = tipomensagem?.filter((x) => btntipomensagem?.includes(x.tipo));
    let id = dados?.map((item) => item.id);

    return id;
  }

  const Salvarmensagem = () => {
    api
      .post("agrupamensagem", {
        idusers: id,
        idmensagem: metodoFiltraMensagem(),
      })
      .then((error) => {
        reloadMensagem();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //salvar operacao
  const Salvaroperacao = () => {
    let filtraroperacao = tpoperacoes.filter((x) =>
      btntoperacao.includes(x.id)
    );

    if (filtraroperacao.length > 0) {
      return alert(
        "Atencao !!!, nao pode vincular operacao ja existente no usuario"
      );
    } else {
      api
        .post("agrupaoperacoesusers", {
          fkusers: id,
          fkoperacoes: btntoperacao,
        })
        .then((error) => {
          refreshPage();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="Users">
      <Paper
        square
        elevation={1}
        variant="outlined"
        style={{
          backgroundColor: "#FFFF",
          marginLeft: 100,
          marginRight: 100,
          marginTop: 50,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#abb8c3",
          borderRadius: 10,
          minWidth: 410,
        }}
      >
        <form

        // onSubmit={this.submitHandler}
        >
          <div
            style={{
              display: "flex",
              backgroundColor: "#191E4D",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              height: 50,
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                color: "#cccccc",
              }}
            >
              Editar usuarios
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              //  alignItems: 'center',
              //  justifyContent: 'center',
              //  minWidth: 660,
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            <TextField
              style={{
                flex: 1,
                marginTop: 25,
                marginRight: 60,
                minWidth: 350,
              }}
              id="filled-basic"
              size="small"
              variant="filled"
              label="Nome"
              type="text"
              name="name"
              value={name}
              onChange={onChangename}
            />
            <TextField
              style={{
                marginTop: 25,
                width: 225,
                flex: 1,
                maxWidth: 390,
                minWidth: 350,
              }}
              //   id='filled-basic'
              size="small"
              variant="filled"
              label="CPF"
              type="text"
              name="cpf"
              value={cpf}
              onChange={onChangecpf}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                width: 225,
                flex: 1,
                maxWidth: 420,
                minWidth: 350,
                marginRight: 50,
              }}
              // id='filled-basic'
              size="small"
              variant="filled"
              label="Telefone"
              type="text"
              name="contato"
              value={contato}
              onChange={onChangecontato}
            />

            <FormControl
              id="standard-basic"
              size="small"
              variant="filled"
              style={{
                marginTop: 30,
                width: 225,
                flex: 1,
                maxWidth: 490,
                minWidth: 350,
                marginRight: 50,
              }}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Tipo Usuario
              </InputLabel>
              <Select
                native
                value={tipo}
                onChange={onChangetipo}
                label="Tipo"
                inputProps={{
                  name: "tipo",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="None" value="" />
                <option value={1}>Administrador</option>
                <option value={2}>Motorista</option>
                <option value={3}>Cliente Apenas visao web</option>
                <option value={4}>Conferente STR</option>
                <option value={5}>ADM STR(CTE)</option>
                <option value={6}>Cliente Acesso web e APP</option>
              </Select>
            </FormControl>

            <TextField
              style={{
                marginTop: 30,
                width: 225,
                flex: 1,
                maxWidth: 390,
                minWidth: 350,
              }}
              // id='filled-basic'
              size="small"
              variant="filled"
              label="Password"
              name="password"
              type="password"
              // value={password}
              //  onChange={this.changeHandler}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              //  alignItems: 'center',
              //  justifyContent: 'center',
              justifyContent: "space-evenly",
              paddingLeft: 50,
              paddingRight: 50,
              marginTop: 25,
            }}
          >
            {/*CARD DE MENSAGEM AUTOMATICA  */}
            <Card
              style={{
                // display: "flex",
                //maxWidth: 390,
                flex: 1,
                marginRight: 30,
                minWidth: 350,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cccccc",
                  marginBottom: 15,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                  }}
                >
                  Adicionar mensagem automatica
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 500,
                    marginTop: 10,
                    marginBottom: 30,
                    // backgroundColor: 'red',
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 10,
                      marginLeft: 5,
                      fontSize: 14,
                    }}
                  >
                    Mensagem:
                  </Typography>
                  <FormControl
                    id="standard-basic"
                    style={{ maxWidth: 225, minWidth: 200, flex: 1 }}
                  >
                    <Select
                      native
                      value={btntipomensagem}
                      onChange={onChangetipoMensagem}
                      label="Tipo"
                      inputProps={{
                        name: "tipo",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      <option value={1}>Saida de Loja</option>
                      <option value={2}>Entrega Finalizada</option>
                      <option value={3}>Carga Gerada</option>
                      <option value={4}>Documento Gerado</option>
                      <option value={5}>Entrega Gerada</option>
                      <option value={6}>Tempo de Loja</option>
                      <option value={7}>Saida CD</option>
                    </Select>
                  </FormControl>
                  <IconButton
                    style={{
                      marginTop: 5,
                      color: "#2d8659",
                      height: 40,
                      width: 40,
                      borderRadius: 40 / 2,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                    onClick={Salvarmensagem}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: 30,
                        color: "#2d8659",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                <List
                  dense
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#abb8c3",
                    borderRadius: 5,
                    maxWidth: 500,
                    minWidth: 350,
                    width: "100%",
                  }}
                >
                  {mensagem?.map((value) => (
                    <ListItem
                      key={value}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() => onDelete(value.tipo)}
                        >
                          <DeleteIcon
                            style={{
                              color: "#862d2d",
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <Typography>{value.tipo}</Typography>
                      </ListItemText>

                      <ListItemText>
                        {(() => {
                          if (value.tipo == 1) {
                            return <Typography>Saida de Loja</Typography>;
                          } else if (value.tipo == 2) {
                            return <Typography>Entrega Finalizada</Typography>;
                          } else if (value.tipo == 3) {
                            return <Typography>Carga Gerada</Typography>;
                          } else if (value.tipo == 4) {
                            return <Typography>Documento Gerado</Typography>;
                          } else if (value.tipo == 5) {
                            return <Typography>Entrega Gerada</Typography>;
                          } else if (value.tipo == 6) {
                            return <Typography>Tempo de Loja</Typography>;
                          } else if (value.tipo == 7) {
                            return <Typography>Saida CD</Typography>;
                          }
                        })()}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Card>

            {/*CARD PARA DEFINIR A OPERACAO QUE ELE PERTENCE    */}
            <Card
              style={{
                // display: "flex",
                //maxWidth: 390,
                flex: 1,
                minWidth: 350,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cccccc",
                  marginBottom: 15,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                  }}
                >
                  Adicionar operacao
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 500,
                    marginTop: 10,
                    marginBottom: 30,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 10,
                      marginLeft: 5,
                      fontSize: 14,
                    }}
                  >
                    Mensagem :
                  </Typography>
                  <FormControl
                    id="standard-basic"
                    style={{ maxWidth: 225, minWidth: 200, flex: 1 }}
                  >
                    <Select
                      native
                      value={btntoperacao}
                      onChange={onChangeoperacao}
                      label="operacao"
                      inputProps={{
                        name: "operacao",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {tipoperacao?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tpoperacao}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    style={{
                      marginTop: 5,
                      color: "#2d8659",
                      height: 40,
                      width: 40,
                      borderRadius: 40 / 2,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                    onClick={Salvaroperacao}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: 30,
                        color: "#2d8659",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //backgroundColor: 'red',
                  marginBottom: 15,
                }}
              >
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#abb8c3",
                    borderRadius: 5,
                    //backgroundColor: 'red',
                  }}
                >
                  {tpoperacoes?.map((value) => (
                    <ListItem
                      key={value}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() =>
                            onDeleteAgrupoperacao(value.agrupaoperacoesusers.id)
                          }
                        >
                          <DeleteIcon
                            style={{
                              color: "#862d2d",
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <Typography>{value.id}</Typography>
                      </ListItemText>

                      <ListItemText>
                        <Typography>{value.tpoperacao}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginTop: 50,
                width: 360,
                marginBottom: 30,
              }}
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              onClick={toEdit}
            >
              Salvar
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
