import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Barra from './barra_MensagemEdit';
import { getmensagemID, putMensagem } from '../service/CRUD_Service';

export default function MensagemEdit({ history }) {
  const { id } = history.location.state;
  const [mensagem, setMensagem] = useState({});
  const [usersErro, setUsersErro] = useState('');

  const [tipo, setTipo] = useState('');
  const [list, setList] = useState([{}]);
  const [tipomensagem, setTipomensagem] = useState('');

  useEffect(() => {
    getmensagemID(id)
      .then((res) => {
        setMensagem(res);
        setTipo(res.tipo);
        setTipomensagem(res.descricaomensagem);
      })
      .catch((err) => setUsersErro(err));
  }, [id]);

  //tipo
  const onChangetipo = (event) => {
    setTipo(event.target.value);
  };

  //tipo
  const onChangetipoMensagem = (event) => {
    setTipomensagem(event.target.value);
  };
  /*editar mensagem */
  const toEdit = (event) => {
    event.preventDefault();

    mensagem.tipo = tipo;
    mensagem.descricaomensagem = tipomensagem;

    putMensagem(id, mensagem)
      .then(() => history.push('/ListarMensagem'))
      .catch((err) => setUsersErro(err));
  };

  return (
    <div className='Users'>
      <Paper
        style={{
          backgroundColor: '#ffff',
          marginTop: 10,
          marginBlockEnd: 17,
          marginLeft: 15,
          marginRight: 10,
        }}
      >
        <Barra />
      </Paper>
      <Paper
        square
        elevation={1}
        variant='outlined'
        style={{
          backgroundColor: '#FFFF',
          marginLeft: 15,

          marginRight: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <form
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#abb8c3',
              borderRadius: 10,
              minWidth: 660,
              marginTop: 10,
              marginBottom: 20,
            }}
            // onSubmit={this.submitHandler}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <FormControl
                id='standard-basic'
                style={{ marginLeft: 40, marginTop: 30, width: 225 }}
              >
                <InputLabel htmlFor='outlined-age-native-simple'>
                  Tipo Mensagem
                </InputLabel>
                <Select
                  native
                  value={tipo}
                  onChange={onChangetipo}
                  label='Tipo'
                  inputProps={{
                    name: 'tipo',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label='None' value='' />
                  <option value={1}>Saida de Loja</option>
                  <option value={2}>Entrega Finalizada</option>
                  <option value={3}>Carga Gerada</option>
                  <option value={4}>Documento Gerado</option>
                  <option value={5}>Entrega Gerada</option>
                  <option value={6}>Tempo de Loja</option>
                  <option value={7}>Saida CD</option>
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextField
                style={{
                  width: 590,
                  marginTop: 25,
                  //marginRight: 40,
                }}
                id='outlined-uncontrolled'
                multiline
                rows={4}
                variant='outlined'
                label='Descreva a Mensagem'
                type='text'
                name='tipomensagem'
                value={tipomensagem}
                onChange={onChangetipoMensagem}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  marginTop: 50,
                  width: 360,
                  marginBottom: 30,
                }}
                type='submit'
                variant='contained'
                color='primary'
                disableElevation
                onClick={toEdit}
              >
                Salvar
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    </div>
  );
}
