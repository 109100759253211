// AIzaSyBYgrI2_kdhvk_VnTVmFX4W8W0JcnrQTR0

import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@mui/material/Tooltip';

//dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//MAPA
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';

//icones  BsHouseCheckFill
import { BsHouseFill } from 'react-icons/bs';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { MdWrongLocation, MdAddCircle } from 'react-icons/md';

//import Rots from '../componentes/RotasMaps';

import '../style/style_mapa.css';

export default function Rotas() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [Novarota, setNovarota] = useState([]);
  const [Distancia, setDistancia] = useState();

  const [map, setMap] = useState();
  const [searchBoxA, setSearchBoxA] = useState(null);
  const [searchBoxB, setSearchBoxB] = useState(null);
  const [Paradas, setParadas] = useState([]);
  const [pointA, setPointA] = useState();
  const [pointB, setPointB] = useState();
  const [waypoints, setWaypoints] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [response, setResponse] = useState(null);
  const [waypointMarkers, setWaypointMarkers] = useState([]);
  const [tiporota, setTiporota] = useState(null);
  const [namerota, setNamerota] = useState(null);
  const [tipoParada, setTipoParada] = useState('A Definir');
  const [Novaparadas, setNovaparadas] = useState([]);

  const handleTipoParadaChange = (event, index) => {
    const updatedItems = [...Novaparadas];
    updatedItems[index].tipo_parada = event.target.value;
    setNovaparadas(updatedItems);
  };

  //busca atualizacao tipo rota
  const handletiporota = (event) => {
    setTiporota(event.target.value);
  };

  const handleNomeRotaChange = (event) => {
    setNamerota(event.target.value);
  };

  const handleAddWaypoint = () => {
    setWaypoints([...waypoints, '']);
  };

  const handleRemoveWaypoint = (index) => {
    setWaypoints(waypoints.filter((_, i) => i !== index));
  };

  const handleWaypointChange = (value, index) => {
    const newWaypoints = [...waypoints];
    newWaypoints[index] = value;
    setWaypoints(newWaypoints);
  };

  const onMapLoad = (map) => {
    setMap(map);
  };

  const onLoadA = (ref) => {
    setSearchBoxA(ref);
  };

  const onLoadB = (ref) => {
    setSearchBoxB(ref);
  };

  const onPlacesChangedA = () => {
    const places = searchBoxA.getPlaces();

    const place = places[0];

    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setPointA(location);
    setOrigin(null);
    setDestination(null);
    setResponse(null);
    map?.panTo(location);
  };

  const onPlacesChangedB = () => {
    const places = searchBoxB.getPlaces();

    const place = places[0];
    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };
    setPointB(location);
    setOrigin(null);
    setDestination(null);
    setResponse(null);
    map?.panTo(location);
  };

  const traceRoute = () => {
    if (pointA && pointB) {
      setOrigin(pointA);
      setDestination(pointB);
    }
  };

  useEffect(() => {
    const locations = [];
    waypoints.forEach((waypoint) => {
      const places = new window.google.maps.places.PlacesService(map);
      places.textSearch({ query: waypoint }, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK
        ) {
          const location = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          locations.push(location);
        }
      });
    });
    setParadas(locations);
  }, []);

  {
    /* 
  const getWaypointLocations = () => {
    const locations = [];
    waypoints.forEach((waypoint) => {
      const places = new window.google.maps.places.PlacesService(map);
      places.textSearch({ query: waypoint }, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK
        ) {
          const location = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          locations.push(location);
        }
      });
    });
    return locations;
  };

*/
  }

  const directionsServiceOptions = useMemo(() => {
    return {
      origin,
      destination,
      waypoints: waypoints
        .filter((waypoint) => waypoint !== '')
        .map((waypoint) => {
          return {
            location: waypoint,
            stopover: true,
          };
        }),
      travelMode: 'DRIVING',
    };
  }, [origin, destination, waypoints]);

  // outras funções existentes

  const directionsCallback = useCallback(
    (res) => {
      if (res !== null && res.status === 'OK') {
        setResponse(res);

        const markers = []; // array para armazenar os marcadores dos pontos de parada

        // percorre cada segmento da rota
        res.routes[0].legs.forEach((leg) => {
          // percorre cada ponto de parada no segmento
          leg.via_waypoints.forEach((waypoint) => {
            // cria um novo marcador para o ponto de parada
            const marker = new window.google.maps.Marker({
              position: waypoint,
              map: map,
              title: 'Parada',
            });

            markers.push(marker); // adiciona o marcador à matriz
          });
        });

        setWaypointMarkers(markers); // atualiza o estado dos marcadores dos pontos de parada
      } else {
        console.log(res);
      }
    },
    [map]
  );

  const defaultOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#ff0000' },
  };

  const directionsRendererOptions = useMemo(() => {
    return {
      directions: response,
      options: {
        ...defaultOptions,
        polylineOptions: {
          editable: false,
          strokeColor: '#ff0000',
          strokeWeight: 3,
          strokeOpacity: 0.8,
        },
        markerOptions: { draggable: true },
      },
    };
  }, [response]);

  // Funções de callback para atualizar os pontos A e B
  const onMarkerDragEndA = (e) => {
    setPointA({ lat: e.latLng.lat(), lng: e.latLng.lng() }); // atualizando a posição do ponto A
  };

  const onMarkerDragEndB = (e) => {
    setPointB({ lat: e.latLng.lat(), lng: e.latLng.lng() }); // atualizando a posição do ponto B
  };

  // Função para calcular a rota
  const calculateRoute = () => {
    if (pointA && pointB) {
      // Define as novas coordenadas de origem e destino
      setOrigin(pointA);
      setDestination(pointB);

      // Cria uma nova solicitação de rota
      const directionsService =
        new window.google.maps.DirectionsService();
      const request = {
        origin: pointA,
        destination: pointB,
        waypoints: waypoints
          .filter((waypoint) => waypoint !== '')
          .map((waypoint) => {
            return {
              location: waypoint,
              stopover: true,
            };
          }),
        travelMode: 'DRIVING',
      };

      // Envia a solicitação de rota para o DirectionsService
      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setResponse(result);
        }
      });
    }
  };

  const MontObjet = () => {
    const cordenadasParadas = Paradas;
    const NomeParadas = waypoints;
    const group = [];

    for (let i = 0; i < NomeParadas.length; i++) {
      let latitude = cordenadasParadas.map((x) => x.lat);
      let longitude = cordenadasParadas.map((x) => x.lng);
      group.push({
        localizacao: NomeParadas[i],
        lat: latitude[i],
        long: longitude[i],
        tipo_parada: tipoParada,
      });
    }

    return setNovaparadas(group);
  };

  const LongLatParadas = () => {
    const locations = [];
    waypoints.forEach((waypoint) => {
      const places = new window.google.maps.places.PlacesService(map);
      places.textSearch({ query: waypoint }, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK
        ) {
          const location = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          locations.push(location);
        }
      });
    });

    return setParadas(locations);
  };

  const handleTracarrota = () => {
    if (waypoints.length == 0) {
      traceRoute();
      setWaypoints([]);
    } else {
      traceRoute();
      calculateRoute();
      LongLatParadas();
      MontObjet();
    }
  };

  //let paradasArray = MontObjet();

  console.log('Paradass', Novaparadas);

  return (
    <>
      <Box sx={{ flexGrow: 1, flexDirection: 'column' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              style={{
                marginTop: 5,
                marginLeft: 5,
                height: '100%',
                overflow: 'auto',
                // padding: '20px',
                boxSizing: 'border-box',
                maxWidth: '100%',
              }}
            >
              <div style={{ height: '100vh' }}>
                <LoadScript
                  googleMapsApiKey={
                    'AIzaSyBYgrI2_kdhvk_VnTVmFX4W8W0JcnrQTR0'
                  }
                  libraries={['places']}
                >
                  <GoogleMap
                    onLoad={onMapLoad}
                    mapContainerStyle={{
                      width: '100%',
                      height: '100%',
                    }}
                    center={{
                      lat: -27.224935,
                      lng: -48.621472,
                    }}
                    zoom={15}
                  >
                    <div className='address'>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <StandaloneSearchBox
                          onLoad={onLoadA}
                          onPlacesChanged={onPlacesChangedA}
                        >
                          <input
                            className='addressField'
                            placeholder='Digite o endereço inicial'
                          />
                        </StandaloneSearchBox>
                        <Tooltip
                          title='Inicio'
                          placement='right-start'
                        >
                          <button className='btnremove'>
                            <FaMapMarkedAlt
                              style={{
                                fontSize: 18,
                                color: '#2d8659',
                              }}
                            />
                          </button>
                        </Tooltip>
                      </div>

                      {waypoints.map((waypoint, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                          }}
                        >
                          <input
                            style={{ width: 185 }}
                            className='addressField'
                            type='text'
                            placeholder='Digite a parada'
                            value={waypoint}
                            onChange={(e) =>
                              handleWaypointChange(
                                e.target.value,
                                index
                              )
                            }
                          />
                          <Tooltip
                            title='Remover Parada'
                            placement='right-start'
                          >
                            <button
                              className='btnremove'
                              onClick={() =>
                                handleRemoveWaypoint(index)
                              }
                            >
                              <MdWrongLocation
                                style={{
                                  fontSize: 18,
                                  color: '#bf4040',
                                }}
                              />
                            </button>
                          </Tooltip>
                        </div>
                      ))}

                      {/*IMPUTS FINAL*/}
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <StandaloneSearchBox
                          onLoad={onLoadB}
                          onPlacesChanged={onPlacesChangedB}
                        >
                          <input
                            className='addressField'
                            placeholder='Digite o endereço final'
                          />
                        </StandaloneSearchBox>
                        <Tooltip
                          title='Final'
                          placement='right-start'
                        >
                          <button className='btnremove'>
                            <BsHouseFill
                              style={{
                                fontSize: 18,
                                color: '#2d8659',
                              }}
                            />
                          </button>
                        </Tooltip>
                      </div>

                      {/*BOTOES COMANDOS  */}
                      <div className='divAdd'>
                        <Tooltip
                          title='Adcionar parada'
                          placement='right-start'
                        >
                          <button
                            onClick={handleAddWaypoint}
                            className='btnadd'
                          >
                            <MdAddCircle
                              style={{
                                fontSize: 24,
                                color: '#2d8659',
                              }}
                            />
                          </button>
                        </Tooltip>
                      </div>
                      <div>
                        <button
                          onClick={handleTracarrota}
                          className='tracarrotar'
                        >
                          Traçar rota
                        </button>
                        <button
                          onClick={handleClickOpen}
                          className='cadastrarrota'
                        >
                          Cadastrar Rota
                        </button>
                      </div>
                    </div>

                    {!response && pointA && (
                      <Marker position={pointA} />
                    )}
                    {!response && pointB && (
                      <Marker position={pointB} />
                    )}

                    {origin && destination && (
                      <DirectionsService
                        options={directionsServiceOptions}
                        callback={directionsCallback}
                      />
                    )}

                    {response && directionsRendererOptions && (
                      <DirectionsRenderer
                        options={directionsRendererOptions}
                      />
                    )}

                    {pointA && (
                      <Marker
                        position={pointA}
                        draggable={true}
                        onDragEnd={onMarkerDragEndA}
                      />
                    )}
                    {pointB && (
                      <Marker
                        position={pointB}
                        draggable={true}
                        onDragEnd={onMarkerDragEndB}
                      />
                    )}
                    {Paradas.map((parada, index) => (
                      <Marker
                        key={`parada-${index}`}
                        position={parada}
                      />
                    ))}
                  </GoogleMap>
                </LoadScript>
                <Dialog
                  maxWidth='lg'
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                >
                  <DialogTitle id='alert-dialog-title'>
                    {'Cadastrar Rotas'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                      <div
                        style={{
                          marginTop: 15,
                          alignItems: 'center',
                          justifyItems: 'center',
                        }}
                      >
                        <label
                          style={{
                            marginTop: 15,
                            alignItems: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            height: 25,
                            fontSize: 14,
                          }}
                        >
                          Tipo de Rotas :
                        </label>
                        <FormControl
                          id='standard-basic'
                          style={{
                            width: 180,
                            marginTop: 12,
                            height: 25,
                            marginLeft: -30,
                            alignItems: 'center',
                            justifyItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          <Select
                            native
                            value={tiporota}
                            onChange={handletiporota}
                            label='Tipo'
                            inputProps={{
                              name: 'tipo',
                              id: 'outlined-age-native-simple',
                            }}
                          >
                            <option aria-label='None' value='' />
                            <option value={'Padrao'}>Padrao</option>
                            <option value={'Alternativa'}>
                              Alternativa
                            </option>
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <label
                          style={{
                            marginTop: 5,
                            alignItems: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            height: 25,
                            fontSize: 14,
                          }}
                        >
                          Nome da rota :
                        </label>

                        <TextField
                          style={{ width: 310 }}
                          fullWidth
                          value={namerota}
                          onChange={handleNomeRotaChange}
                          // label='Nome da Rota'
                          id='fullWidth'
                        />
                      </div>
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            marginTop: 15,
                          }}
                        >
                          <label
                            style={{
                              marginTop: 5,
                              alignItems: 'center',
                              justifyItems: 'center',
                              justifyContent: 'center',
                              height: 15,
                              fontSize: 14,
                              width: 95,
                            }}
                          >
                            Distancia :
                          </label>

                          <TextField
                            style={{ width: 50 }}
                            fullWidth
                            InputProps={{ style: { fontSize: 12 } }}
                            value={Distancia}
                            // label='Nome da Rota'
                            id='fullWidth'
                          />
                        </div>
                        <div
                          style={{
                            marginTop: 15,
                            marginLeft: 50,
                          }}
                        >
                          <label
                            style={{
                              marginTop: 5,
                              alignItems: 'center',
                              justifyItems: 'center',
                              justifyContent: 'center',
                              height: 15,
                              fontSize: 14,
                            }}
                          >
                            Tempo :
                          </label>

                          <TextField
                            style={{ width: 50 }}
                            fullWidth
                            InputProps={{ style: { fontSize: 12 } }}
                            // label='Nome da Rota'
                            id='fullWidth'
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <label
                          style={{
                            marginTop: 5,
                            alignItems: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            height: 25,
                            fontSize: 14,
                          }}
                        >
                          Cidade origem :
                          {searchBoxA == null
                            ? console.log(
                                'sem parada',
                                searchBoxA?.getPlaces()
                              )
                            : searchBoxA?.getPlaces()?.[0]
                                .formatted_address}
                        </label>
                      </div>
                      <div
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <label
                          style={{
                            marginTop: 5,
                            alignItems: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            height: 25,
                            fontSize: 14,
                          }}
                        >
                          Cidade Destino :
                          {searchBoxB == null
                            ? console.log(
                                'sems parada',
                                searchBoxB?.getPlaces()
                              )
                            : searchBoxB?.getPlaces()?.[0]
                                .formatted_address}
                        </label>
                      </div>
                      <div
                        style={{
                          marginTop: 45,
                          //  margin: 10,
                          overflow: 'auto',
                          maxHeight: 350,
                        }}
                      >
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label='a dense table'
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align='left'
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Endereço
                                </TableCell>
                                <TableCell
                                  align='left'
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Latitude
                                </TableCell>
                                <TableCell
                                  align='left'
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Longitude
                                </TableCell>
                                <TableCell
                                  align='left'
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  Tipo Parada
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {Novaparadas?.map((item, index) => (
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontSize: 12,
                                    }}
                                    align='left'
                                    component='th'
                                    scope='row'
                                  >
                                    {item.localizacao}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: 12,
                                    }}
                                    align='left'
                                    component='th'
                                    scope='row'
                                  >
                                    {Number(item.lat)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: 12,
                                    }}
                                    align='left'
                                    component='th'
                                    scope='row'
                                  >
                                    {Number(item.long)}
                                  </TableCell>
                                  <TableCell align='left'>
                                    <FormControl
                                      id={`select-${item.index}`}
                                      style={{
                                        width: 180,
                                        marginTop: 12,
                                        height: 25,
                                        marginLeft: -35,
                                        alignItems: 'center',
                                        justifyItems: 'center',
                                        fontSize: 12,
                                      }}
                                    >
                                      <Select
                                        native
                                        value={item.tipo_parada}
                                        onChange={(event) =>
                                          handleTipoParadaChange(
                                            event,
                                            index
                                          )
                                        }
                                        label='Tipo'
                                        inputProps={{
                                          name: 'tipo',
                                          id: 'outlined-age-native-simple',
                                        }}
                                      >
                                        <option
                                          aria-label='None'
                                          value=''
                                        />
                                        <option value='Coleta'>
                                          Coleta
                                        </option>
                                        <option value='Entrega'>
                                          Entrega
                                        </option>

                                        <option value='A Definir'>
                                          A definir
                                        </option>
                                        <option value='Desvio'>
                                          Desvio
                                        </option>
                                        <option value='Abastecimento'>
                                          Abastecimento
                                        </option>
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className='cadastrarrota'>Salvar</Button>
                    <Button
                      className='cadastrarrota'
                      onClick={handleClose}
                    >
                      Fechar
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
