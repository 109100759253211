import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Form from '../componentes/form_operacoes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  barra: {
    padding: 10,
  },
  nomepag: {
    marginLeft: 'auto',
  },
  formulario: {
    padding: 20,
    alignContent: 'center',
    //  paddingLeft: 520,
    //paddingRight: 520,

    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    //  padding: 20,
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.barra}>
        <AppBar position='static' color='default'>
          <Toolbar>
            <Typography variant='h6'>Operacoes</Typography>
            <Typography color='inherit' className={classes.nomepag}>
              STR TRANSPORTES LOGISTICA
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.formulario}>
        <Form />
      </div>
    </div>
  );
}
