//grid listar
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//COMPONENTES INTERNOS
import Tabela from './Tabela_cargas_pendentes';
import Barra from '../componentes/barra_listaCargapendente';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    justify: 'center',
    marginRight: theme.spacing(2),
  },
  formpaper: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-11),

    marginLeft: theme.spacing(-40),
  },
}));

const stilotab = {
  padding: '10px',
  marginTop: '50px',
};

export default function GridListarCargas() {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={0} justify='left'>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Barra />
          </Paper>
        </Grid>
        <Grid item xs={12} style={stilotab}>
          <Tabela />
        </Grid>
      </Grid>
    </div>
  );
}
