import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  getNomeUsuario,
  getTipoUsuario,
} from '../service/authService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position='static' color='red'>
        <Toolbar>
          <Typography variant='h6'>Dashaboard</Typography>
          <Typography
            variant='h6'
            className={classes.title}
          ></Typography>
          <Typography color='inherit'>
            Usuario Logado:{getNomeUsuario()}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
