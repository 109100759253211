import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//COMPONENTES INTERNOS
import Barra from '../componentes/barra_destino';
import Form from '../componentes/Form_destino';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    //padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    justify: 'center',
    marginRight: theme.spacing(2),
  },
  formpaper: {
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-1),

    marginLeft: theme.spacing(-20),
    // justify: 'center',
    //marginRight:theme.spacing(-10)
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify='left'>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Barra />
          </Paper>
        </Grid>
      </Grid>

      <Grid container justify='center'>
        <Grid item xs={12}>
          <Form />
        </Grid>
      </Grid>
    </div>
  );
}
