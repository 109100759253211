import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import api from '../api';

export default function ListadeCargas() {
  const [cargas, setCargas] = useState([]); //recebe do banco de dados
  const [ent, setEnt] = useState({});
  const classes = useRowStyles();

  //busca dados api
  useEffect(() => {
    api
      .get('filterviagemmotorista')
      .then((res) => {
        let carg = res.data;
        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = new Date(a.datasolicitamdfe).getTime();
          var d = new Date(b.datasolicitamdfe).getTime();
          return c - d; //data mais antiga para mais nova
        });
        setCargas(itens);
      })
      .catch(() => alert('erro ao carregar'));
  }, []);

  //EXIBE DADOS DA TABELA
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>

          <TableCell component='th' scope='row'>
            {row.fkmotorista.nome}
          </TableCell>
          {/* {row.fkmotorista.tipo} */}
          <TableCell align='center'>{row.fkmotorista.tipo}</TableCell>
          <TableCell align='center'>
            {row.fkveiculotrator.placa}
          </TableCell>
          <TableCell align='center'>{row.fkveiculo.placa}</TableCell>

          {/*STATUS DA CARGA ESTIVER VISUALIZADO A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align='center'>
            {row.statusentregacarga}
          </TableCell>

          {/*STATUS DA NF, SE ESTIVER COM NF A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align='center'>{row.statusnf}</TableCell>

          {/*STATUS DO DOCUMENTO, SE O DOCUMENTO PENDENTE A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align='center'>{row.statusdoc}</TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA CARGA */}
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box margin={1}>
                <Typography variant='h6' gutterBottom component='div'>
                  Detalhes
                </Typography>
                <Table size='small' aria-label='purchases'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Lojas</TableCell>
                      <TableCell>Perfil</TableCell>
                      <TableCell align='center'>Qtd P</TableCell>
                      <TableCell align='center'>Cidade</TableCell>
                      <TableCell align='center'>
                        Status Entrega
                      </TableCell>
                      <TableCell align='center'>
                        Data Entrega
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}
                    {row.cviagens.map((valor) => (
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {valor.fkdestino.lojas}
                        </TableCell>
                        <TableCell> {valor.perfil}</TableCell>
                        <TableCell align='center'>
                          {valor.qtdpallet}
                        </TableCell>
                        <TableCell align='center'>
                          {valor.fkdestino.municipio}
                        </TableCell>
                        <TableCell align='center'>
                          {valor.statusentrega}
                        </TableCell>
                        <TableCell align='center'>
                          {(() => {
                            let data = valor.dataentrega;
                            let horas = new Date(data);
                            let [date, month, year] = new Date(data)
                              .toLocaleDateString('pt-BR')
                              .split('/');
                            let formatadata =
                              date +
                              '/' +
                              month +
                              '/' +
                              year +
                              ' ' +
                              horas.getHours() +
                              ':' +
                              horas.getMinutes() +
                              ':' +
                              horas.getSeconds();
                            let sem = '';
                            if (data === null) {
                              return sem;
                            } else {
                              return formatadata;
                            }
                          })()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell />
            <TableCell className={classes.txtheard}>
              Motoristas
            </TableCell>
            <TableCell align='center' className={classes.txtheard}>
              Perfil Mt
            </TableCell>
            <TableCell align='center' className={classes.txtheard}>
              Placa Cavalo
            </TableCell>
            <TableCell align='center' className={classes.txtheard}>
              Placa Carreta
            </TableCell>
            <TableCell align='center' className={classes.txtheard}>
              Status Carga
            </TableCell>
            <TableCell align='center' className={classes.txtheard}>
              Status NF
            </TableCell>
            <TableCell align='center' className={classes.txtheard}>
              Status Doc{' '}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cargas.map((row) => (
            <Row row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
//styles
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  head: {
    backgroundColor: '#FFFFFF',
  },
  txtheard: {
    fontWeight: 'bold',
  },
});
