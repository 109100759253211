import React, { useEffect, useState, version } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";

import Divider from "@material-ui/core/Divider";
import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import axios from "axios";
import api from "../api";
import { ApiTracerVeiculos } from "../service/authtracer";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: "red",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",

    alignItems: "center",
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: "#ffff ",
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: "#ffff",
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -5,
    //marginLeft: 42,
  },
  icon: {
    fontSize: 19,
  },
});

export default function CardVeiculosDisponivel({ datafiltra, operacoes }) {
  const classes = useStyles();

  const [veiculosTracer, setVeiculosTracer] = useState();
  const [veiculos, setVeiculos] = useState();
  const [Cargas, setCargas] = useState(1);

  const [open, setOpen] = React.useState(false);

  const [opencarga, setOpencarga] = useState(false);
  const [detalhesCarga, setDetalhesCarga] = useState(null);

  const formatarDataEntrada = (timestamp) => {
    const format1 = "DD/MM/YYYY HH:mm:ss"; // Formato desejado
    let hora = new Date(timestamp); // Converte o timestamp em objeto Date

    // Formata a data em UTC usando moment
    let dataFormatada = moment.utc(hora).format(format1);

    return dataFormatada; // Retorna a data formatada
  };

  const handleOpenModal = (detalhes) => {
    setDetalhesCarga(detalhes);
    setOpencarga(true);
  };

  const handleCloseModal = () => {
    setOpencarga(false);
    setDetalhesCarga(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  let latcd = -27.226422; //ponto fixo
  let loncd = -48.626584; //ponto fixo
  let cerca = 300.67600239642678; // area do cd

  //veiculos em transito
  useEffect(() => {
    api
      .get("/dashFrotaveiculotransito")
      .then((res) => {
        let dados = res.data;
        // console.log('dashFrotaveiculotransito', dados);
        setCargas(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashFrotaveiculotransito")
        .then((res) => {
          let dados = res.data;
          // console.log('dashFrotaveiculotransito', dados);
          setCargas(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
        //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
        let dados = carg.map((item) => [item.name, item.lat, item.lng]);

        let dados1 = dados
          .map(([placa, latitude, longitude]) => ({
            placa,
            latitude,
            longitude,
            distancia: geolocalizacao(latitude, longitude, latcd, loncd),
          })) // calcula a distancia de cada placa
          .filter(
            ({ placa, latitude, longitude, distancia }) => distancia <= cerca
          ) // filtra somente as placas que estão no cerco
          .map(
            ({ placa, latitude, longitude }) => placa
            // latitude,
            //longitude,
          ); // recupera somente o valor latitude e longitude

        setVeiculosTracer(dados1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
          //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
          let dados = carg.map((item) => [item.name, item.lat, item.lng]);

          let dados1 = dados
            .map(([placa, latitude, longitude]) => ({
              placa,
              latitude,
              longitude,
              distancia: geolocalizacao(latitude, longitude, latcd, loncd),
            })) // calcula a distancia de cada placa
            .filter(
              ({ placa, latitude, longitude, distancia }) => distancia <= cerca
            ) // filtra somente as placas que estão no cerco
            .map(
              ({ placa, latitude, longitude }) => placa
              // latitude,
              //longitude,
            ); // recupera somente o valor latitude e longitude

          setVeiculosTracer(dados1);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    api
      .get("/veiculos")
      .then((res) => {
        let carg = res.data;
        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = new Date(a.nfrota);
          var d = new Date(b.nfrota);
          return c - d; //data mais antiga para mais nova
        });
        setVeiculos(itens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
          //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
          let dados = carg.map((item) => [item.name, item.lat, item.lng]);

          let dados1 = dados
            .map(([placa, latitude, longitude]) => ({
              placa,
              latitude,
              longitude,
              distancia: geolocalizacao(latitude, longitude, latcd, loncd),
            })) // calcula a distancia de cada placa
            .filter(
              ({ placa, latitude, longitude, distancia }) => distancia <= cerca
            ) // filtra somente as placas que estão no cerco
            .map(
              ({ placa, latitude, longitude }) => placa
              // latitude,
              //longitude,
            ); // recupera somente o valor latitude e longitude

          setVeiculosTracer(dados1);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function VericarCargaCard() {
    let veiculo = veiculos?.filter((x) => veiculosTracer?.includes(x.placa));

    const veiculosFiltrados = veiculo?.map((veiculo) => {
      // Procurando o veículo no array de veiculoscomcarga pelo atributo "placa"
      const veiculoComCarga = Cargas?.find(
        (vc) => vc.fkplaca.placa === veiculo.placa
      );

      if (veiculoComCarga) {
        // Se o veículo foi encontrado em veiculoscomcarga, retornar os dados da carga
        return {
          ...veiculo,
          carga: {
            idcarga: veiculoComCarga.idcarga,
            descricaostatus: veiculoComCarga.descricaostatus,
            dtentrada: veiculoComCarga.dtentrada,
            dtsaida: veiculoComCarga.dtsaida,
            qtdpallet: veiculoComCarga.qtdpallet,
            viagens: veiculoComCarga,
          },
        };
      } else {
        // Se o veículo não foi encontrado, retornar o veículo sem carga
        return {
          ...veiculo,
          carga: "Sem carga",
        };
      }
    });

    // Conta quantos veículos têm carga
    const contagemVeiculosComCarga =
      veiculosFiltrados?.filter((veiculo) => veiculo.carga !== "Sem carga")
        .length || 0; // Se não houver veículos filtrados, retorna 0

    return contagemVeiculosComCarga;
  }

  function VericarSemCargaCard() {
    let veiculo = veiculos?.filter((x) => veiculosTracer?.includes(x.placa));

    const veiculosFiltrados = veiculo?.map((veiculo) => {
      // Procurando o veículo no array de veiculoscomcarga pelo atributo "placa"
      const veiculoComCarga = Cargas?.find(
        (vc) => vc.fkplaca.placa === veiculo.placa
      );

      if (veiculoComCarga) {
        // Se o veículo foi encontrado em veiculoscomcarga, retornar os dados da carga
        return {
          ...veiculo,
          carga: {
            idcarga: veiculoComCarga.idcarga,
            descricaostatus: veiculoComCarga.descricaostatus,
            dtentrada: veiculoComCarga.dtentrada,
            dtsaida: veiculoComCarga.dtsaida,
            qtdpallet: veiculoComCarga.qtdpallet,
            viagens: veiculoComCarga,
          },
        };
      } else {
        // Se o veículo não foi encontrado, retornar o veículo sem carga
        return {
          ...veiculo,
          carga: "Sem carga",
        };
      }
    });

    // Conta quantos veículos têm carga
    const contagemVeiculosComCarga =
      veiculosFiltrados?.filter((veiculo) => veiculo.carga == "Sem carga")
        .length || 0; // Se não houver veículos filtrados, retorna 0

    return contagemVeiculosComCarga;
  }

  //atribuir um novo metodo ao essa funcao para exibir dados em tela
  function MetodoPrincipal() {
    let veiculo = veiculos?.filter((x) => veiculosTracer?.includes(x.placa));

    const veiculosFiltrados = veiculo?.map((veiculo) => {
      // Procurando o veículo no array de veiculoscomcarga pelo atributo "placa"
      const veiculoComCarga = Cargas?.find(
        (vc) => vc.fkplaca.placa === veiculo.placa
      );

      if (veiculoComCarga) {
        // Se o veículo foi encontrado em veiculoscomcarga, retornar os dados da carga
        return {
          ...veiculo,
          carga: {
            idcarga: veiculoComCarga.idcarga,
            descricaostatus: veiculoComCarga.descricaostatus,
            dtentrada: veiculoComCarga.dtentrada,
            dtsaida: veiculoComCarga.dtsaida,
            qtdpallet: veiculoComCarga.qtdpallet,
            viagens: [veiculoComCarga],
          },
        };
      } else {
        // Se o veículo não foi encontrado, retornar o veículo sem carga
        return {
          ...veiculo,
          carga: "Sem carga",
        };
      }
    });

    return (
      <TableContainer sx={{ maxHeight: 632, minWidth: 950 }}>
        <Table
          //  sx={{ minWidth: 950 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Placa</TableCell>
              <TableCell align="center">N Frota</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Modelo</TableCell>
              <TableCell align="center">Qtd Pallet</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {veiculosFiltrados?.map((item) => (
              <TableRow>
                <TableCell align="center">{item.placa}</TableCell>
                <TableCell component="th" align="center">
                  {item.nfrota}
                </TableCell>
                <TableCell component="th" align="center">
                  {item.tipoveiculo}
                </TableCell>
                <TableCell component="th" align="center">
                  {item.tipocarroceria}
                </TableCell>
                <TableCell component="th" align="center">
                  {item.qtdpallet}
                </TableCell>
                {/* Verifica se o veículo está com carga */}
                <TableCell align="center">
                  {item.carga === "Sem carga" ? (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#e57373", width: 50 }}
                    >
                      <LocalShippingIcon />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      style={{ width: 50 }}
                      onClick={() => handleOpenModal(item)}
                    >
                      <LocalShippingIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const totalVeiculos = VericarCargaCard() + VericarSemCargaCard();

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button onClick={handleClickOpen}>
            <VisibilityIcon className={classes.icon} />
          </Button>
          <Typography className={classes.qtd}>
            {totalVeiculos == 0 ? 0 : totalVeiculos}
          </Typography>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
              }}
            >
              <LocalShippingIcon
                style={{
                  color: "#40bf80",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  opacity: VericarCargaCard() ? 1 : 0,
                  marginRight: 5,
                }}
              />
              <Typography
                style={{
                  color: "#fff",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  opacity: VericarCargaCard() ? 1 : 0,
                }}
              >
                {VericarCargaCard()}
              </Typography>
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
              }}
            >
              <LocalShippingIcon
                style={{
                  color: "#fff",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  opacity: VericarSemCargaCard() ? 1 : 0,
                  marginRight: 5,
                }}
              />
              <Typography
                style={{
                  color: "#fff",
                  marginLeft: "auto",
                  transition: "transform 0.5s ease-in-out",
                  opacity: VericarSemCargaCard() ? 1 : 0,
                }}
              >
                {VericarSemCargaCard()}
              </Typography>
            </div>
          </div>
        </Box>
      </CardContent>

      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Typography className={classes.title}>Patio STR</Typography>

        {/* Modal para mostrar os detalhes da carga */}
        <Modal open={opencarga} onClose={handleCloseModal}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "white",
              margin: "auto",
              width: "450px",
            }}
          >
            {detalhesCarga && (
              <>
                <h2>Detalhes da Carga</h2>
                <p>
                  <strong>Id Carga:</strong> {detalhesCarga.carga.idcarga}
                </p>
                <p>
                  <strong>Data Saída CD:</strong>{" "}
                  {formatarDataEntrada(detalhesCarga.carga.dtsaida)}
                </p>

                {/* Exibindo todas as origens de embarque */}
                {detalhesCarga.carga.viagens?.map((viagem, index) => (
                  <div key={index}>
                    <p>
                      <strong>Origem do embarque:</strong>{" "}
                      {viagem.fkviagens.cviagens[0].fkorigem.descricao}
                    </p>
                  </div>
                ))}
                {/* Exibindo todas as origens de embarque */}
                {detalhesCarga.carga.viagens?.map((viagem, index) => (
                  <div key={index}>
                    <p>
                      <strong>Destinos :</strong>{" "}
                      {viagem.fkviagens.cviagens[0].fkdestino.lojas}
                    </p>
                  </div>
                ))}
                {/* Exibindo todas as origens de embarque */}
                {detalhesCarga.carga.viagens?.map((viagem, index) => (
                  <div key={index}>
                    <p>
                      <strong>Status solicitação mdfe :</strong>{" "}
                      {viagem.fkviagens.statussolicitamdfe}
                    </p>
                  </div>
                ))}
                {/* Exibindo todas as origens de embarque */}
                {detalhesCarga.carga.viagens?.map((viagem, index) => (
                  <div key={index}>
                    <p>
                      <strong>Status processamento :</strong>{" "}
                      {viagem.fkviagens.statusprocessamento}
                    </p>
                  </div>
                ))}
                {/* Exibindo todas as origens de embarque */}
                {detalhesCarga.carga.viagens?.map((viagem, index) => (
                  <div key={index}>
                    <p>
                      <strong>Status NF :</strong> {viagem.fkviagens.statusnf}
                    </p>
                  </div>
                ))}

                {/* Exibindo todas as origens de embarque */}
                {detalhesCarga.carga.viagens?.map((viagem, index) => (
                  <div key={index}>
                    <p>
                      <strong>Status doc :</strong> {viagem.fkviagens.statusdoc}
                    </p>
                  </div>
                ))}

                {/* Botão para fechar o modal */}
                <Button onClick={handleCloseModal}>Fechar</Button>
              </>
            )}
          </div>
        </Modal>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Veiculos Patio STR 2"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <MetodoPrincipal />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}
