//nao esta mais sendo utilizado

import axios from 'axios';

import { getTokentracer } from '../src/service/authtracer';

const api = axios.create({
  //baseURL: 'http://207.244.244.73:50301',
  baseURL:
    //https://iter-api.getrak.com/v1/trackers?company_id=3706' novo
    //https://iter-api.itertelemetria.com/v1/trackers?company_id=3706' // antigo
    'https://iter-api.itertelemetria.com/v1/trackers?company_id=3706',
});

//novo
api.interceptors.request.use(async (config) => {
  const token = getTokentracer();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
