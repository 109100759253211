import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  nomepag: {
    color: '#9B9B9B',
  },
  fundo: {
    backgroundColor: '#FFFFFF',
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position='static' className={classes.fundo}>
        <Toolbar>
          <Typography variant='h6' className={classes.nomepag}>
            Lista de Carga Pendente Entrega
          </Typography>
          <Typography
            variant='h6'
            className={classes.title}
          ></Typography>
          <Typography className={classes.nomepag}>
            STR TRANSPORTES LOGISTICA
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
