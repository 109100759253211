import React from "react";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import MapIcon from "@material-ui/icons/Map";

import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import StorageIcon from "@material-ui/icons/Storage";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { logout, getTipoUsuario } from "../service/authService";

export default function MainListItems() {
  const [open, setOpen] = React.useState(false);
  const [exportacoes, setExportacoes] = React.useState(false);
  const [bi, setBi] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickexportacoes = () => {
    setExportacoes(!exportacoes);
  };

  const handleClickbi = () => {
    setBi(!bi);
  };

  return (
    <div>
      {/* PRIMEIRO NOME */}
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/Dashaboard" tyle={{ color: "#fff" }}>
          Dashboard Frota
        </Link>
      </ListItem>

      {/* PRIMEIRO NOME */}
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/DashaboardCarga" style={{ color: "#fff" }}>
          Dashboard Carga
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <StorageIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/listakoch" style={{ color: "#fff" }}>
          Entregas
        </Link>
      </ListItem>

      {/* SEGUNDO NOME */}
      <ListItem button>
        <ListItemIcon>
          <StorageIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/relatorios" style={{ color: "#fff" }}>
          Entregas Pendentes
        </Link>
      </ListItem>

      {/* TERCEIRO NOME */}
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/manifesto" style={{ color: "#fff" }}>
          Manifesto
        </Link>
      </ListItem>

      {/* QUARTO  NOME */}
      <ListItem button>
        <ListItemIcon>
          <NoteAddIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/PainelProcessamento" style={{ color: "#fff" }}>
          Painel Processamento
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <NoteAddIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/Listastatuscd" style={{ color: "#fff" }}>
          Status Veiculo CD
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <NoteAddIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/Listastatusloja" style={{ color: "#fff" }}>
          Status Veiculo Loja
        </Link>
      </ListItem>

      {/* QUIANTO NOME */}
      <ListItem button>
        <ListItemIcon>
          <NoteAddIcon style={{ color: "#fff" }} />
        </ListItemIcon>
        <Link to="/Mapa" style={{ color: "#fff" }}>
          Mapa
        </Link>
      </ListItem>

      <div>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={handleClick}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Cadastros" style={{ color: "#fff" }} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/ListarUsers" style={{ color: "#fff" }}>
                  {getTipoUsuario() == 2 ? "" : "Usuarios"}
                </Link>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <LocalShippingIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/listarveiculo" style={{ color: "#fff" }}>
                  Veiculos
                </Link>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/ListarMotorista" style={{ color: "#fff" }}>
                  Motorista
                </Link>
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <NoteAddIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/ListarOrigem" style={{ color: "#fff" }}>
                  Origem
                </Link>
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <NoteAddIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/ListarDestino" style={{ color: "#fff" }}>
                  Destino
                </Link>
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <NoteAddIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/ListarMensagem" style={{ color: "#fff" }}>
                  Mensagem Automatica
                </Link>
              </ListItem>
              <ListItem button>
                <MapIcon style={{ color: "#fff" }}>
                  <NoteAddIcon
                    style={{ color: "#fff", backgroundColor: "red" }}
                  />
                </MapIcon>
                <Link
                  to="/ListaRotas"
                  style={{ color: "#fff", marginLeft: 30 }}
                >
                  Rotas
                </Link>
              </ListItem>
              <ListItem button>
                <MapIcon style={{ color: "#fff" }}>
                  <NoteAddIcon
                    style={{ color: "#fff", backgroundColor: "red" }}
                  />
                </MapIcon>
                <Link
                  to="/ListOperacoes"
                  style={{ color: "#fff", marginLeft: 30 }}
                >
                  Operacoes
                </Link>
              </ListItem>
            </List>
          </Collapse>
        </List>

        {/* EXPORTACOES */}
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={handleClickexportacoes}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Exportacoes" style={{ color: "#fff" }} />
            {exportacoes ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={exportacoes} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem button>
                <ListItemIcon>
                  <LocalShippingIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/statusveiculo" style={{ color: "#fff" }}>
                  Status Veiculo
                </Link>
              </ListItem>
              <ListItem button>
                <ListItemIcon>
                  <LocalShippingIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/ExpEntregas" style={{ color: "#fff" }}>
                  Entregas Realizadas
                </Link>
              </ListItem>
            </List>
          </Collapse>
        </List>

        {/* BI */}
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button onClick={handleClickbi}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary="Relatorios BI" style={{ color: "#fff" }} />
            {bi ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={bi} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon style={{ color: "#fff" }} />
                </ListItemIcon>
                <Link to="/powerbicargas" style={{ color: "#fff" }}>
                  Cargas
                </Link>
              </ListItem>
            </List>
          </Collapse>
        </List>

        {/* TERCEIRO NOME */}
        <ListItem button>
          <ListItemIcon></ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider></Divider>
      </div>
    </div>
  );
}
