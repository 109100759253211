import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import api from "../api";

export default function CardVeiculoPatio({ datafiltra, operacoes }) {
  const classes = useStyles();

  const [bancoSTRveiculos, setBancoSTRveiculos] = useState();
  const [veiculosComCarga, setVeiculosComCarga] = useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //#########################DADOS DA API RASTREADOR###################################
  /*
 
*/
  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  //incluir veiculo
  useEffect(() => {
    api
      .get("/veiculos")
      .then((res) => {
        let carg = res.data;
        let status = "Ativo";
        let nfrota = 200;
        let dados = carg.map((item) => item);
        let filtro = dados.filter(
          (x) => x.status === status && x.nfrota !== nfrota
        );
        //let contar = filtro.length;

        setBancoSTRveiculos(filtro);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/veiculos")
        .then((res) => {
          let carg = res.data;
          let status = "Ativo";
          let nfrota = 200;
          let dados = carg.map((item) => item);
          let filtro = dados.filter(
            (x) => x.status === status && x.nfrota !== nfrota
          );
          //let contar = filtro.length;

          setBancoSTRveiculos(filtro);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //veiculos com carga
  useEffect(() => {
    api
      .get("/filterviagemmotorista")
      .then((res) => {
        let carg = res.data;

        setVeiculosComCarga(carg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/filterviagemmotorista")
        .then((res) => {
          let carg = res.data;

          setVeiculosComCarga(carg);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function FiltraPlacas() {
    let a = bancoSTRveiculos;
    let b = veiculosComCarga;
    let Op = Number(operacoes);

    //filtra a operação e veiculos da base str tudo que é carreta e o que esta ativo
    let filterVeiculo = a?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    //pega apenas a placa das viagems com carga
    let dados = b?.map((item) => item.fkveiculo.placa);

    let placas = filterVeiculo?.filter((x) => !dados?.includes(x.placa));
    let contar = placas?.length;

    return contar;
  }

  function ExibirPlacas() {
    let a = bancoSTRveiculos;
    let b = veiculosComCarga;
    let Op = Number(operacoes);

    //filtra a operação e veiculos da base str tudo que é carreta e o que esta ativo
    let filterVeiculo = a?.filter((x) =>
      x.Cad_operacoes.some((i) => i.id === Op)
    );

    //pega apenas a placa das viagems com carga
    let dados = b?.map((item) => item.fkveiculo.placa);

    let placas = filterVeiculo?.filter((x) => !dados?.includes(x.placa));

    return (
      <TableContainer sx={{ maxHeight: 632, minWidth: 950 }}>
        <Table
          //  sx={{ minWidth: 950 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Placa</TableCell>
              <TableCell align="center">N Frota</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Modelo</TableCell>
              <TableCell align="center">Qtd Pallet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {placas?.map((item) => (
              <TableRow>
                <TableCell align="center">{item.placa}</TableCell>
                <TableCell component="th" align="center">
                  {item.nfrota}
                </TableCell>
                <TableCell component="th" align="center">
                  {item.tipoveiculo}
                </TableCell>
                <TableCell component="th" align="center">
                  {item.tipocarroceria}
                </TableCell>
                <TableCell component="th" align="center">
                  {item.qtdpallet}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  let Veiculostb = FiltraPlacas();

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button className={classes.icon} onClick={handleClickOpen}>
            <VisibilityIcon className={classes.icon} />
          </Button>
          <Typography className={classes.qtd}>
            {Veiculostb == 0 ? 0 : Veiculostb}
          </Typography>
        </Box>
      </CardContent>

      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Tooltip title=" Carretas Disponiveis">
          <Typography className={classes.title}>Carretas Disp.</Typography>
        </Tooltip>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Veiculos disponivel"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ExibirPlacas />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: "red",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  icon: {
    fontSize: 20,
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //marginLeft: -14,
    color: "#ffff",
    //  marginBottom: -20,
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: "#ffff ",
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: "#ffff",
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -5,
    //marginLeft: 42,
  },
  toolip: {
    minWidth: 950,
  },
});
