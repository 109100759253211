import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Divider from '@material-ui/core/Divider';

import api from '../api';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: 'red',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: '#ffff',

    alignItems: 'center',
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: '#ffff ',
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: '#ffff',
  },
  qtd: {
    color: '#F5F5F5',
    fontSize: 40,
    fontWeight: 'bold',
    marginTop: -5,
    //marginLeft: 42,
  },
});

export default function CardVeiculosDisponivel() {
  const classes = useStyles();
  const [veiculo, setVeiculo] = useState(0);

  //incluir veiculo
  useEffect(() => {
    api
      .get('/veiculos')
      .then((res) => {
        let carg = res.data;
        let status = 'Ativo';

        let tipo = 'SRF'; //parametro para filtro
        let dados = carg.map((item) => item);
        let filtro = dados.filter(
          (x) => x.tipoveiculo === tipo && x.status === status
        );
        let contar = filtro.length;

        setVeiculo(contar);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Card
      style={{
        background: '#191E4D',
        justifyItems: 'flex-start',
        justifyContent: 'space-around',
      }}
    >
      <CardContent>
        <Typography className={classes.qtd}>{veiculo}</Typography>
      </CardContent>

      <Divider className={classes.dividir} />
      <CardActions style={{ background: '#191E4D' }}>
        <Typography className={classes.title}>
          Carretas SRF
        </Typography>
      </CardActions>
    </Card>
  );
}
