//FALTA FAZER//
//1 COLOCAR UPERCASE NO CAMPO PLACA(CAIXA ALTA)
//

import React, { Component } from 'react';
import api from '../api';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import Style_login from '../style/style_login.css';

class motorista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codigo: '',
      nome: '',
      cpf: '',
      turno: '',
      tipo: '',
      status: '',
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post('/motoristas', this.state)
      .then((response) => {
        console.log(response);
        alert('MOTORISTA SALVO COM SUCESSO!!! ');
        window.location = '/ListarMotorista';
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { codigo, nome, cpf, turno, tipo, status } = this.state;

    return (
      <body className={Style_login.body}>
        <div>
          <div>
            <br></br>
            <br></br>
            <Paper
              variant='outlined'
              elevation={4}
              style={{
                backgroundColor: '#ffff',
                width: 890,
                height: 400,
                marginTop: -20,
                marginLeft: 230,
              }}
            >
              <form
                style={{
                  backgroundColor: '#ffff',
                  width: 700,
                  marginLeft: 110,
                  marginTop: 40,
                }}
                onSubmit={this.submitHandler}
              >
                <InputLabel
                  style={{ marginLeft: 250, fontSize: 25 }}
                  id='demo-simple-select-helper-label'
                >
                  Adicionar Motorista
                </InputLabel>

                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 30,
                        marginTop: 40,
                      }}
                      required
                      id='standard-required'
                      label='Codigo'
                      placeholder='00'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='codigo'
                      value={codigo}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 70,
                        marginTop: 40,
                      }}
                      required
                      id='standard-required'
                      label='Nome'
                      placeholder='******'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='nome'
                      value={nome}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 110,
                        marginTop: 40,
                      }}
                      required
                      id='standard-required'
                      label='CPF'
                      placeholder='XXXXX'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='cpf'
                      value={cpf}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>

                <br></br>

                {/* CAMPOS DE SELEÇÃO  */}
                <Grid container spacing={3}>
                  {/* CAMPO TURNO */}
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant='outlined'
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 30,
                      }}
                    >
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Turno
                      </InputLabel>

                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        margin='dense'
                        label='Turno'
                        name='turno'
                        value={turno}
                        onChange={this.changeHandler}
                      >
                        <MenuItem value=''>
                          <em>Turno...</em>
                        </MenuItem>

                        <MenuItem value={'Comercial'}>
                          Comercial
                        </MenuItem>
                        <MenuItem value={'Turno 1'}>Turno 1</MenuItem>
                        <MenuItem value={'Turno 2'}>Turno 2</MenuItem>
                        <MenuItem value={'Turno 3'}>Turno 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* CAMPO VINCULO */}
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant='outlined'
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 70,
                      }}
                    >
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Tipo
                      </InputLabel>

                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        margin='dense'
                        label='Tipo'
                        name='tipo'
                        value={tipo}
                        onChange={this.changeHandler}
                      >
                        <MenuItem value=''>
                          <em>Tipo.....</em>
                        </MenuItem>

                        <MenuItem value={'Frota'}>Frota</MenuItem>
                        <MenuItem value={'Agregado'}>
                          Agregado
                        </MenuItem>
                        <MenuItem value={'Terceiro'}>
                          Terceiro
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* CAMPO VINCULO */}
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant='outlined'
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 110,
                      }}
                    >
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Status
                      </InputLabel>

                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        margin='dense'
                        label='status'
                        name='status'
                        value={status}
                        onChange={this.changeHandler}
                      >
                        <MenuItem value=''>
                          <em>Status.....</em>
                        </MenuItem>

                        <MenuItem value={'ativo'}>Ativo</MenuItem>
                        <MenuItem value={'inativo'}>Inativo</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Button
                  style={{
                    marginLeft: 300,
                    height: 30,
                    marginTop: 100,
                  }}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Salvar
                </Button>
              </form>
            </Paper>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </body>
    );
  }
}

export default motorista;
