import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";

import { styled } from "@mui/material/styles";

//icones
import { GiGrapes, GiCube, GiSlicedBread } from "react-icons/gi";
import DescriptionIcon from "@material-ui/icons/Description";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LocalPostOfficeIcon from "@material-ui/icons/LocalPostOffice";
import { FaTemperatureLow } from "react-icons/fa";
//utils
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";
import "../style/style_pag.css";

import apitracer from "../apitracer";
import api from "../api";
import axios from "axios";
import { ApiTracerVeiculos } from "../service/authtracer";

const useStyles = makeStyles({
  // root: {
  // display: "flex",
  // overflowX: "hide",
  //},
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",
    alignItems: "center",
  },
  teste: {
    backgroundColor: "red",
    color: "red",
  },
  cabecalhos: {
    color: "black",
    fontSize: 12,
    fontWeight: 750,
  },
});

export default function Dashtabelaveiculoscd({
  datafiltra,
  operacoes,
  gridorigem,
}) {
  const classes = useStyles();
  const [dados, setDados] = useState(1);
  const [veiculos, setVeiculos] = useState();
  const [origem, setOrigem] = useState();
  const [tracer, setTracer] = useState();

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  let latcd = -27.224884; //ponto fixo
  let loncd = -48.621497; //ponto fixo
  let cerca = 420.67600239642678; // area do cd

  //incluir origem
  useEffect(() => {
    api
      .get("/origem")
      .then((res) => {
        let carg = res.data;

        let filterCerca = carg.filter((x) => x.cerca !== null);

        let pegaid = filterCerca.map(function (x) {
          return {
            id: x.id,
            descricao: x.descricao,
            lat: x.latitude,
            long: x.longitude,
            cerca: x.cerca,
          };
        });

        setOrigem(pegaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //incluir veiculo
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
        //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
        let dados = carg.map((item) => [
          item.name,
          item.lat,
          item.lng,
          item.temperature,
        ]);

        setTracer(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
          //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
          let dados = carg.map((item) => [
            item.name,
            item.lat,
            item.lng,
            item.temperature,
          ]);

          setTracer(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashfrotaveiculocdcarregado")
        .then((res) => {
          let carg = res.data;

          //funcao para ordenar a lista por hora
          const itens = carg.sort(function (a, b) {
            var c = new Date(a.createdAt).getTime();
            var d = new Date(b.createdAt).getTime();
            return c - d; //data mais antiga para mais nova
          });

          setDados(itens);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    api
      .get("/dashfrotaveiculocdcarregado")
      .then((res) => {
        let carg = res.data;

        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = new Date(a.createdAt).getTime();
          var d = new Date(b.createdAt).getTime();
          return c - d; //data mais antiga para mais nova
        });

        setDados(itens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //NOVO METODO DE PERCORRER ONDE O VEICULO ESTA
  function MetodoPrincipal() {
    if (dados !== 1) {
      let Op = Number(operacoes);
      let Origens = origem;
      let VeiculosTracer = tracer;
      let placasloja = [];
      //let cargas = dados;
      let novoArray = [];

      let cargas = dados?.filter((item) => {
        // Filtra os dados do array principal que atendem as condições de origem nos itens dentro de cviagens
        return item.cviagens.some((cviagem) => {
          // Se origem for undefined ou 999, retorna todos os itens
          if (gridorigem === undefined || Number(gridorigem) === 999) {
            return true;
          }
          // Caso contrário, filtra com base na origem
          return cviagem.origem === Number(gridorigem);
        });
      });

      // dentro do for tem que testar
      for (var i = 0; i < Origens?.length; i++) {
        for (var j = 0; j < VeiculosTracer?.length; j++) {
          if (
            geolocalizacao(
              VeiculosTracer[j][1],
              VeiculosTracer[j][2],
              Origens[i].lat,
              Origens[i].long
            ) <= Origens[i].cerca
          ) {
            placasloja.push({
              Origensid: Origens[i].id,
              placa: VeiculosTracer[j][0],
              temperatura: VeiculosTracer[j][3],
            });
          }
        }
      }

      cargas?.map((item) =>
        placasloja?.map((x) => {
          if (x.placa == item.fkveiculo.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      // Filtrando as viagens com base nos IDs de operações do usuário
      const filteredViagens = novoArray?.filter((v) => {
        // Obtendo todos os IDs de operações associadas à origem da viagem
        const origemOperacoesIds = v.cviagens?.flatMap((c) =>
          c.fkorigem.Cad_operacoes?.map((o) => o.id)
        );

        // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
        return origemOperacoesIds?.some((id) => id === Op);
      });

      return filteredViagens;
    } else {
      console.log("teste");
    }
  }

  let filtro = MetodoPrincipal();

  function FiltraveiculosAdefinir() {
    let dados = filtro;

    let filtrarindefinido = dados?.filter((item) => item.idveiculotrator == 28);

    return filtrarindefinido?.length;
  }

  function Filtraveiculosdefinidos() {
    let dados = filtro;

    let filtrardefinidos = dados?.filter((item) => item.idveiculotrator !== 28);

    return filtrardefinidos?.length;
  }

  // Determine o estilo `sx` com base no valor de `operacaoSelecionada`
  const sxStyle = operacoes === 3 ? { maxHeight: 532 } : { maxHeight: 775 }; // Altere conforme necessário

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.5)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.1)",
        opacity: 0,
      },
    },
  }));

  return (
    <Card
      style={{
        background: "#33415C",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
      }}
    >
      <div
        style={{
          background: "#33415C",
          flexFlow: "nowrap",
          justifyContent: "flex-end",
          color: "white",
          font: "monospace",
          display: "flex",
        }}
      >
        <Typography
          style={{
            height: 35,
            marginRight: 190,
            marginLeft: 5,
            fontSize: 20,
            marginTop: 5,
          }}
        >
          Veiculos no local de coletas
        </Typography>
        <Typography
          style={{
            fontSize: 20,
            marginRight: 10,
            marginTop: 5,
          }}
        >
          A definir:
        </Typography>
        <Typography
          style={{
            marginRight: 25,
            fontSize: 22,
            marginTop: 5,
            color: "#FFF",
          }}
        >
          {FiltraveiculosAdefinir() == 0 ? 0 : FiltraveiculosAdefinir()}
        </Typography>
        <Typography
          style={{
            fontSize: 20,
            marginRight: 10,
            marginTop: 5,
          }}
        >
          Definidos:
        </Typography>
        <Typography
          style={{
            marginRight: 10,
            fontSize: 22,
            marginTop: 5,
          }}
        >
          {Filtraveiculosdefinidos() == 0 ? 0 : Filtraveiculosdefinidos()}
        </Typography>
      </div>
      <TableContainer className={classes.root} sx={sxStyle}>
        <Table
          sx={{
            //  width: '100%',
            //  maxWidth: 60,
            bgcolor: "background.paper",
            //  position: 'relative',
            overflow: "auto",
            maxHeight: 50,
            // minHeight: 550,
            minWidth: 140,
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.cabecalhos}>Cavalo</Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title="Unidade de Carga">
                  <Typography
                    className={classes.cabecalhos}
                    style={{
                      marginLeft: -30,
                    }}
                  >
                    U.C
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Typography className={classes.cabecalhos}>Origem</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Lojas</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Pallet</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  className={classes.cabecalhos}
                  style={{
                    marginLeft: -30,
                  }}
                >
                  T
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Perfil</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Status</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Tempo CD</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtro?.map((item) => (
              <TableRow>
                <TableCell align="center">
                  {(() => {
                    if (item.fkveiculotrator.placa) {
                      return (
                        <Typography
                          className="textoareaplaca"
                          style={{ fontSize: 15 }}
                        >
                          {item.fkveiculotrator.placa}
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          className="textoareaplacadefinir"
                          style={{ fontSize: 15 }}
                        >
                          A definir
                        </Typography>
                      );
                    }
                  })()}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography
                    className="textoareanfrota"
                    style={{
                      fontSize: 20,
                      marginLeft: -30,
                    }}
                  >
                    {item.fkveiculo.nfrota}
                  </Typography>
                </TableCell>
                {(() => {
                  // let origem = row.cviagens.fkorigem.descricao;
                  // let origem = row.cviagens.fkorigem.descricao;
                  let origem = [
                    ...new Set(item.cviagens.map((x) => x.fkorigem.descricao)),
                  ];

                  return (
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.txtOperacaotabela}
                    >
                      <Typography
                        style={{
                          //  backgroundColor: "#415A77",
                          fontFamily: "serif",
                          color: "#415A77",
                          borderColor: "#778DA9",
                          borderRadius: "4px",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          //  justifyContent: "space-between", // Distribui espaço entre itens
                          textAlign: "center",
                          minWidth: 30,
                        }}
                      >
                        {origem}
                      </Typography>
                    </TableCell>
                  );
                })()}
                <div>
                  {item.cviagens?.map((x) => (
                    <TableCell align="center">
                      <div
                        style={{
                          paddingTop: 9,
                          marginLeft: -25,
                        }}
                      >
                        {/* 
                        <StyledBadge
                          overlap='circular'
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          variant='dot'
                        >
                           </StyledBadge>

                            */}
                        <Typography
                          className="textoarealoja"
                          style={{
                            fontSize: 14,
                          }}
                        >
                          {x.fkdestino.lojas}
                        </Typography>
                      </div>
                    </TableCell>
                  ))}
                </div>
                <TableCell align="center">
                  {(() => {
                    let a = item.cviagens?.map((x) => x.qtdpallet);

                    let sum = 0;

                    for (let i = 0; i < a.length; i++) {
                      sum += a[i];
                    }

                    return (
                      <Typography
                        className="textoarepallet"
                        style={{
                          fontSize: 20,
                        }}
                      >
                        {sum}
                      </Typography>
                    );
                  })()}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      fontSize: 14,
                      marginLeft: -30,
                    }}
                  >
                    <FaTemperatureLow
                      style={{
                        fontSize: 10,
                        color: "red",
                      }}
                    />
                    {item.temperatura}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {(() => {
                    let perfil = item.cviagens?.map((x) => x.perfil);
                    var filtra = perfil.filter(
                      (x, i) => perfil.indexOf(x) === i
                    );

                    if (
                      perfil.includes("Normal") &&
                      perfil.includes("Horti") &&
                      !perfil.includes("Frios")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <GiCube
                              style={{
                                fontSize: 18,
                                color: "#fcb900",
                                marginLeft: -10,
                              }}
                            />

                            <GiGrapes
                              style={{
                                fontSize: 18,
                                color: "#194d33",
                                //   marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                    //PERFIL FRIOS E HORTI E NORMAL
                    else if (
                      perfil.includes("Frios") &&
                      perfil.includes("Horti") &&
                      perfil.includes("Normal")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <GiCube
                              style={{
                                fontSize: 18,
                                color: "#fcb900",
                                //  marginLeft: -10,
                              }}
                            />
                            <AcUnitIcon
                              style={{
                                fontSize: 18,
                                color: "#0d47a1",
                                // marginLeft: -10,
                              }}
                            />

                            <GiGrapes
                              style={{
                                fontSize: 18,
                                color: "#194d33",
                                //   marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                    //PERFIL FRIOS E HORTI E NORMAL
                    else if (
                      perfil.includes("Frios") &&
                      !perfil.includes("Horti") &&
                      perfil.includes("Normal")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <GiCube
                              style={{
                                fontSize: 18,
                                color: "#fcb900",
                                //  marginLeft: -10,
                              }}
                            />

                            <AcUnitIcon
                              style={{
                                fontSize: 18,
                                color: "#0d47a1",
                                // marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                    //PERFIL FRIOS E HORTI
                    else if (
                      perfil.includes("Frios") &&
                      perfil.includes("Horti") &&
                      !perfil.includes("Normal")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <AcUnitIcon
                              style={{
                                fontSize: 18,
                                color: "#0d47a1",
                                // marginLeft: -10,
                              }}
                            />

                            <GiGrapes
                              style={{
                                fontSize: 18,
                                color: "#194d33",
                                //   marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    } else if (perfil.includes("Horti")) {
                      return (
                        <Tooltip title="Carga Horti">
                          <GiGrapes
                            style={{
                              fontSize: 22,
                              color: "#194d33",
                              //marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Normal")) {
                      return (
                        <Tooltip title="Carga Ceca">
                          <GiCube
                            style={{
                              fontSize: 22,
                              color: "#fcb900",
                              //marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Frios")) {
                      return (
                        <Tooltip title="Carga Frios">
                          <AcUnitIcon
                            style={{
                              fontSize: 22,
                              color: "#0d47a1",
                              // marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Panificacao Seca")) {
                      return (
                        <Tooltip title="Panificacao Seca">
                          <GiSlicedBread
                            style={{
                              fontSize: 22,
                              color: "#fcdc00",
                              // marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Panificacao Frios")) {
                      return (
                        <Tooltip title="Panificacao Frios">
                          <GiSlicedBread
                            style={{
                              fontSize: 22,
                              color: "#B4C4BC",
                              // marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else {
                      return console.log("tes");
                    }
                  })()}
                </TableCell>
                <TableCell align="center">
                  {(() => {
                    let Doc = item.statussolicitamdfe;
                    let visualizado = item.statusprocessamento;
                    let statusnf = item.statusnf;
                    let statusdoc = item.statusdoc;
                    let predoc = item.statuspredoc;

                    if (Doc == "Pendente" && statusnf == "Com NF") {
                      if (predoc == "gerado") {
                        return (
                          <Tooltip title="Solicitacao de Documento pendente com nf">
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              variant="dot"
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "blue", // Define a cor aqui
                                  color: "#194d33",
                                  width: "6px", // Define a largura
                                  height: "6px", // Define a altura
                                  minWidth: "6px", // Garante que a largura mínima seja 5px
                                  minHeight: "6px", // Garante que a altura mínima seja 5px
                                  borderRadius: "50%", // Garante que o elemento seja redondo
                                  padding: 0,
                                },
                              }}
                            >
                              <Typography>
                                <DescriptionIcon
                                  style={{
                                    fontSize: 19,
                                    color: "red",
                                  }}
                                />
                              </Typography>
                            </StyledBadge>
                          </Tooltip>
                        );
                      }
                      return (
                        <Tooltip title="Solicitacao de Documento pendente com nf">
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            variant="dot"
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "#40bf80", // Define a cor aqui
                                color: "#194d33",
                                width: "6px", // Define a largura
                                height: "6px", // Define a altura
                                minWidth: "6px", // Garante que a largura mínima seja 5px
                                minHeight: "6px", // Garante que a altura mínima seja 5px
                                borderRadius: "50%", // Garante que o elemento seja redondo
                                padding: 0,
                              },
                            }}
                          >
                            <Typography>
                              <DescriptionIcon
                                style={{
                                  fontSize: 19,
                                  color: "red",
                                }}
                              />
                            </Typography>
                          </StyledBadge>
                        </Tooltip>
                      );
                    } else if (Doc == "Pendente" && statusnf == "Sem NF") {
                      return (
                        <Tooltip title="Solicitacao de Documento pendente sem nf">
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            variant="dot"
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "#b71c1c", // Define a cor aqui
                                color: "#b71c1c",
                                width: "6px", // Define a largura
                                height: "6px", // Define a altura
                                minWidth: "6px", // Garante que a largura mínima seja 5px
                                minHeight: "6px", // Garante que a altura mínima seja 5px
                                borderRadius: "50%", // Garante que o elemento seja redondo
                                padding: 0,
                              },
                            }}
                          >
                            <Typography>
                              <DescriptionIcon
                                style={{
                                  fontSize: 19,
                                  color: "red",
                                }}
                              />
                            </Typography>
                          </StyledBadge>
                        </Tooltip>
                      );
                    } else if (
                      Doc == "Solicitado" &&
                      visualizado == "Pendente"
                    ) {
                      return (
                        <Tooltip title="Documento solicitado ao processamento STR">
                          <Typography>
                            <DescriptionIcon
                              style={{
                                fontSize: 19,
                                color: "green",
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    } else if (
                      visualizado == "Visualizado" &&
                      statusnf == "Sem NF"
                    ) {
                      return (
                        <Tooltip title="Documento  Visualizado pelo processamento STR">
                          <Typography>
                            <VisibilityIcon
                              style={{
                                fontSize: 19,
                                color: "green",
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    } else if (
                      statusnf == "Com NF" &&
                      statusdoc == "Pendente"
                    ) {
                      return (
                        <Tooltip title="Documento em processamento STR Com NF">
                          <Typography>
                            <ContactMailIcon
                              style={{
                                fontSize: 19,
                                color: "green",
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    } else if (
                      visualizado == "Visualizado" &&
                      statusdoc == "Enviado"
                    ) {
                      return (
                        <Tooltip title="Documento enviado para cd Koch">
                          <Typography>
                            <LocalPostOfficeIcon
                              style={{
                                fontSize: 19,
                                color: "green",
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                  })()}
                </TableCell>
                <TableCell align="center">
                  {(() => {
                    //hora atual
                    let horsea = new Date();
                    let [diaa, mesa, anoa] = new Date()
                      .toLocaleDateString("pt-BR")
                      .split("/");
                    let formatarhoraatual =
                      diaa +
                      "-" +
                      mesa +
                      "-" +
                      anoa +
                      " " +
                      horsea.getHours() +
                      ":" +
                      horsea.getMinutes() +
                      ":" +
                      horsea.getSeconds();
                    /*  
                    //hora atual
                    let horaatual = Date();
                    //data atual
                    var timehoraatual = new Date(horaatual);
                    var outrahoraatual = new Date(horaatual);
                    outrahoraatual.setMonth(
                      timehoraatual.getMonth() + 1
                    );

                    var datahoraatual = (
                      '0' + outrahoraatual.getDate()
                    ).slice(-2);
                    var monthhoraatual = (
                      '0' + outrahoraatual.getMonth()
                    ).slice(-2);
                    var yearhoraatual = outrahoraatual.getFullYear();
                    var hourhoraatual = (
                      '0' + outrahoraatual.getHours()
                    ).slice(-2); //estava com +2 mais proximo as 00:00 esta ficando como 25:00
                    var minhoraatual = (
                      '0' + outrahoraatual.getMinutes()
                    ).slice(-2); //To get the Current Minutes
                    var sechoraatual = (
                      '0' + outrahoraatual.getSeconds()
                    ).slice(-2); //To get the Current Seconds

                    let formatarhoraatual =
                      outrahoraatual.getDate() +
                      '-' +
                      outrahoraatual.getMonth() +
                      '-' +
                      outrahoraatual.getFullYear() +
                      '  ' +
                      outrahoraatual.getHours() +
                      ':' +
                      outrahoraatual.getMinutes() +
                      ':' +
                      outrahoraatual.getSeconds();

                      */
                    //data criada

                    const format1 = "DD-MM-YYYY HH:mm:ss";
                    let horseteste = new Date(item.createdAt);

                    let formatardataapi = moment
                      .utc(horseteste)
                      .format(format1);

                    //teste de calculo tempo de cd
                    var ms = moment(
                      formatarhoraatual,
                      "DD/MM/YYYY HH:mm:ss"
                    ).diff(moment(formatardataapi, "DD/MM/YYYY HH:mm:ss"));
                    var d = moment.duration(ms);
                    var tempo =
                      Math.floor(d.asHours()) + moment.utc(ms).format(":mm");

                    //tempo baixo 7 hrs
                    var parametro1 = moment(
                      "20220525 20:00:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20220525 12:00:00", "YYYYMMDD HH:mm:ss"));
                    var dados1 = moment.duration(parametro1);
                    var tempoparametro1 =
                      Math.floor(dados1.asHours()) +
                      moment.utc(parametro1).format(":mm");

                    //tempo baixo 7 hrs
                    var parametro0 = moment(
                      "20220525 20:00:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20220525 10:00:00", "YYYYMMDD HH:mm:ss"));
                    var dados0 = moment.duration(parametro0);
                    var tempoparametro0 =
                      Math.floor(dados0.asHours()) +
                      moment.utc(parametro0).format(":mm");

                    //tempo baixo  hrs
                    var parametro2 = moment(
                      "20170101 20:00:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20170101 13:00:00", "YYYYMMDD HH:mm:ss"));
                    var dados2 = moment.duration(parametro2);
                    var tempoparametro2 =
                      Math.floor(dados2.asHours()) +
                      moment.utc(parametro2).format(":mm");

                    //tempo baixo  hrs
                    var parametro3 = moment(
                      "20170101 20:00:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20170101 15:00:00", "YYYYMMDD HH:mm:ss"));
                    var dados3 = moment.duration(parametro3);
                    var tempoparametro3 =
                      Math.floor(dados3.asHours()) +
                      moment.utc(parametro3).format(":mm");

                    if (parseInt(tempo) > parseInt(tempoparametro2)) {
                      return (
                        <Typography
                          className="tempocritico"
                          style={{ fontSize: 18 }}
                        >
                          {tempo}
                        </Typography>
                      );
                    } else if (
                      parseInt(tempo) >= parseInt(tempoparametro3) &&
                      parseInt(tempo) <= parseInt(tempoparametro2)
                    ) {
                      return (
                        <Typography
                          className="tempomedio"
                          style={{ fontSize: 18 }}
                        >
                          {tempo}
                        </Typography>
                      );
                    } else if (parseInt(tempo) < parseInt(tempoparametro3)) {
                      return (
                        <Typography
                          className="tempobaixo"
                          style={{ fontSize: 18 }}
                        >
                          {tempo}
                        </Typography>
                      );
                    }
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
