{
  /*   import api from "../api";

export const checkToken = async (token) => {
  const response = await api.post("/sessions", { token });
  return response.data;
};

export const postSignin = async ({ cpf, password }) => {
  const response = await api.post("/sessions", { cpf, password });
  return response.data; setCadoperacao
};
*/
}
export const TOKEN_KEY = "@STR-Token";
export const ID = "@id";
export const NAME = "@name";
export const TIPO = "@tipo";
export const OPERACAO = "@operacao";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const setTipoUsuario = (tipo) => localStorage.setItem(TIPO, tipo);
export const getTipoUsuario = () => localStorage.getItem(TIPO);

export const setNomeUsuario = (name) => localStorage.setItem(NAME, name);
export const getNomeUsuario = () => localStorage.getItem(NAME);

export const setIdUsuario = (id) => localStorage.setItem(ID, id);
export const getIdUsuario = () => localStorage.getItem(ID);

export const setOperacao = (name) =>
  localStorage.setItem(OPERACAO, JSON.stringify(name));
//export const getOperacao = () => localStorage.getItem(OPERACAO);

export const getOperacao = () => {
  const operacaoString = localStorage.getItem(OPERACAO);
  return operacaoString ? JSON.parse(operacaoString) : null;
};
