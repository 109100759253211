//

import React, { Component, useState, useEffect } from "react";
import api from "../api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import {
  getveiculoid,
  putVel,
  deleteGrupooperacoesveiculos,
} from "../service/CRUD_Service";
import Barra from "../componentes/barra_veiculoEdit";
import Paper from "@material-ui/core/Paper";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function VeiculoEdit({ history }) {
  //class updateveiculo extends Component{
  const { id } = history.location.state;
  const [vel, setVel] = useState({});
  const [velErro, setVelErro] = useState("");
  const [nfrota, setNfrota] = useState("");
  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [cor, setCor] = useState("");
  const [status, setStatus] = useState("");
  const [vinculo, setVinculo] = useState("");
  const [qtdpallet, setQtdpallet] = useState("");
  const [comprimento, setComprimento] = useState("");
  const [tipoveiculo, setTipoveiculo] = useState("");
  const [tipocarroceria, setTipocarroceria] = useState("");

  const [btntoperacao, setBtnoperacao] = useState();
  const [tipoperacao, setTipoperacao] = useState();
  const [tpoperacoes, setTpoperacoes] = useState();

  //tipo de operacao
  useEffect(() => {
    api
      .get("/operacoes")
      .then((res) => {
        setTipoperacao(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getveiculoid(id)
      .then((res) => {
        setVel(res);
        setNfrota(res.nfrota);
        setPlaca(res.placa);
        setMarca(res.marca);
        setCor(res.cor);
        setStatus(res.status);
        setVinculo(res.vinculo);
        setQtdpallet(res.qtdpallet);
        setComprimento(res.comprimento);
        setTipoveiculo(res.tipoveiculo);
        setTipocarroceria(res.tipocarroceria);
        setTpoperacoes(res.Cad_operacoes);
      })
      .catch((err) => setVelErro(err));
  }, [id]);

  //numero da frota 1
  const onChangenfrota = (event) => {
    setNfrota(event.target.value);
  };
  // placa 2
  const onChangeplaca = (event) => {
    setPlaca(event.target.value);
  };
  // marca 3
  const onChangemarca = (event) => {
    setMarca(event.target.value);
  };
  //cor 4
  const onChangecor = (event) => {
    setCor(event.target.value);
  };
  //status 5
  const onChangestatus = (event) => {
    setStatus(event.target.value);
  };
  //vinculo 6
  const onChangevinculo = (event) => {
    setVinculo(event.target.value);
  };
  //Quantidade de pallet 7
  const onChangeqtdpallet = (event) => {
    setQtdpallet(event.target.value);
  };
  //comprimento 8
  const onChangecomprimento = (event) => {
    setComprimento(event.target.value);
  };
  //tipo de veiculo 9
  const onChangetveiculo = (event) => {
    setTipoveiculo(event.target.value);
  };
  //tipo Carroceria 10
  const onChangetcarroceria = (event) => {
    setTipocarroceria(event.target.value);
  };

  // //tipo operacao
  const onChangeoperacao = (event) => {
    setBtnoperacao(event.target.value);
  };

  const onDeleteAgrupoperacao = (id) => {
    deleteGrupooperacoesveiculos(id)
      .then(() => {
        refreshPage();
      })
      .catch((err) => console.log(err));
  };

  ///atualiza a pagina
  const refreshPage = () => {
    window.location.reload();
  };

  //salvar operacao
  const Salvaroperacao = () => {
    let filtraroperacao = tpoperacoes.filter((x) =>
      btntoperacao.includes(x.id)
    );

    if (filtraroperacao.length > 0) {
      return alert(
        "Atencao !!!, nao pode vincular operacao ja existente no usuario"
      );
    } else {
      api
        .post("agrupaoperacoesveiculos", {
          fkveiculos: id,
          fkoperacoes: btntoperacao,
        })
        .then((error) => {
          refreshPage();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toEdit = (event) => {
    event.preventDefault();
    vel.nfrota = nfrota;
    vel.placa = placa;
    vel.marca = marca;
    vel.cor = cor;
    vel.status = status;
    vel.vinculo = vinculo;
    vel.qtdpallet = qtdpallet;
    vel.comprimento = comprimento;
    vel.tipoveiculo = tipoveiculo;
    vel.tipocarroceria = tipocarroceria;

    putVel(id, vel)
      .then(() => history.push("/listarveiculo"))
      .catch((err) => setVelErro(err));
  };

  return (
    <div>
      <Paper
        square
        variant="outlined"
        elevation={4}
        style={{
          backgroundColor: "#FFFF",
          marginLeft: 100,
          marginRight: 100,
          marginTop: 50,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#abb8c3",
          borderRadius: 10,
          minWidth: 410,
        }}
      >
        <form>
          <div
            style={{
              display: "flex",
              backgroundColor: "#191E4D",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              height: 50,
            }}
          >
            {" "}
            <Typography
              style={{
                fontSize: 20,
                color: "#cccccc",
              }}
            >
              Editar Veiculos
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 100,
                maxWidth: 100,
                flex: 1,
                marginRight: 50,
              }}
              id="outlined-textarea"
              size="small"
              label="Nº Frota"
              placeholder="00"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="nfrota"
              value={nfrota}
              onChange={onChangenfrota}
            />
            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                minWidth: 350,
                maxWidth: 250,
                marginRight: 50,
              }}
              id="outlined-textarea"
              label="Placa"
              placeholder="ZZZ-0000"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="placa"
              value={placa}
              onChange={onChangeplaca}
            />
            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                flex: 1,
                minWidth: 350,
                maxWidth: 450,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Marca
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Marca"
                name="marca"
                value={marca}
                onChange={onChangemarca}
              >
                <MenuItem value="">
                  <em>Marca ...</em>
                </MenuItem>

                <MenuItem value={"Scania"}>Scania</MenuItem>
                <MenuItem value={"Iveco"}>Iveco</MenuItem>
                <MenuItem value={"Volvo"}>Volvo</MenuItem>
                <MenuItem value={"DAF"}>DAF</MenuItem>
                <MenuItem value={"Mercedes-Benz"}>Mercedes-Benz</MenuItem>
                <MenuItem value={"Linshalm"}>Linshalm</MenuItem>
                <MenuItem value={"Ibipora"}>Ibipora</MenuItem>
                <MenuItem value={"Facchini"}>Facchini</MenuItem>
                <MenuItem value={"Termossara"}>Termossara</MenuItem>
                <MenuItem value={"Thermosul "}>Thermosul</MenuItem>
              </Select>
            </FormControl>

            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                maxWidth: 350,
                flex: 1,
              }}
              id="outlined-textarea"
              label="Cor"
              placeholder="XXXXX"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="cor"
              value={cor}
              onChange={onChangecor}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              id="outlined-textarea"
              label="Qtd Pallet"
              placeholder="00"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="qtdpallet"
              value={qtdpallet}
              onChange={onChangeqtdpallet}
            />

            <TextField
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
              id="outlined-textarea"
              label="Comprimento"
              placeholder="00.00"
              multiline
              variant="filled"
              margin="dense"
              type="search"
              name="comprimento"
              value={comprimento}
              onChange={onChangecomprimento}
            />

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo Veiculo
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Tipo Veiculo"
                name="tipoveiculo"
                value={tipoveiculo}
                onChange={onChangetveiculo}
              >
                <MenuItem value="">
                  <em>Tipo Veiculo</em>
                </MenuItem>

                <MenuItem className="Truck" value={"CT"}>
                  CT
                </MenuItem>
                <MenuItem className="semi-reboque-termico" value={"SRT"}>
                  SRT
                </MenuItem>
                <MenuItem className="semi-reboque" value={"SR"}>
                  SR
                </MenuItem>
                <MenuItem className="semi-reboque-frigorifica" value={"SRF"}>
                  SRF
                </MenuItem>
                <MenuItem className="Caminhao-trator-cavalo" value={"CTC"}>
                  CTC
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo Carroceria
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Tipo Carroceria"
                name="tipocarroceria"
                value={tipocarroceria}
                onChange={onChangetcarroceria}
              >
                <MenuItem value="">
                  <em>Tipo Carroceria</em>
                </MenuItem>

                <MenuItem value={"Indefinido"}>Indefinido</MenuItem>
                <MenuItem value={"Bau"}>Bau</MenuItem>
                <MenuItem value={"Sider"}>Sider</MenuItem>
                <MenuItem value={"Frigorifico"}>Frigorifico</MenuItem>
                <MenuItem value={"Termico"}>Termico</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Vinculo
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Status"
                name="vinculo"
                value={vinculo}
                onChange={onChangevinculo}
              >
                <MenuItem value="">
                  <em>Vinculo</em>
                </MenuItem>

                <MenuItem value={"Frota"}>Frota</MenuItem>
                <MenuItem value={"Agregado"}>Agregado</MenuItem>
                <MenuItem value={"Carreteiro"}>Carreteiro</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Status"
                name="status"
                value={status}
                onChange={onChangestatus}
              >
                <MenuItem value="">
                  <em>Status</em>
                </MenuItem>

                <MenuItem value={"Ativo"}>Ativo</MenuItem>
                <MenuItem value={"Manutencao"}>Manutencao</MenuItem>
                <MenuItem value={"Off"}>Off</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              //  alignItems: 'center',
              //  justifyContent: 'center',
              justifyContent: "space-evenly",
              paddingLeft: 50,
              paddingRight: 50,
              marginTop: 60,
            }}
          >
            {/*CARD PARA DEFINIR A OPERACAO QUE ELE PERTENCE    */}
            <Card
              style={{
                // display: "flex",
                //maxWidth: 390,
                flex: 1,
                minWidth: 350,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cccccc",
                  marginBottom: 15,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                  }}
                >
                  Adicionar operacao
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 500,
                    marginTop: 10,
                    marginBottom: 30,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 10,
                      marginLeft: 5,
                      fontSize: 14,
                    }}
                  >
                    Mensagem :
                  </Typography>
                  <FormControl
                    id="standard-basic"
                    style={{ maxWidth: 225, minWidth: 200, flex: 1 }}
                  >
                    <Select
                      native
                      value={btntoperacao}
                      onChange={onChangeoperacao}
                      label="operacao"
                      inputProps={{
                        name: "operacao",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {tipoperacao?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tpoperacao}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    style={{
                      marginTop: 5,
                      color: "#2d8659",
                      height: 40,
                      width: 40,
                      borderRadius: 40 / 2,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                    onClick={Salvaroperacao}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: 30,
                        color: "#2d8659",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //backgroundColor: 'red',
                  marginBottom: 15,
                }}
              >
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#abb8c3",
                    borderRadius: 5,
                    //backgroundColor: 'red',
                  }}
                >
                  {tpoperacoes?.map((value) => (
                    <ListItem
                      key={value}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() =>
                            onDeleteAgrupoperacao(
                              value.agrupaoperacoesveiculos.id
                            )
                          }
                        >
                          <DeleteIcon
                            style={{
                              color: "#862d2d",
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <Typography>{value.id}</Typography>
                      </ListItemText>

                      <ListItemText>
                        <Typography>{value.tpoperacao}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginTop: 5,
                marginBottom: 30,
                marginTop: 50,
              }}
              variant="contained"
              color="primary"
              onClick={toEdit}
            >
              Salvar
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
