//FALTA FAZER//
//MELHORAR DIALOGO COM EXCLUSÃO.
//ORDERNAR TABELA PELO CODIGO DO VEICULO MENOR PARA O MAIOR

//COMPONENSTE REACT
import React, { useState, useEffect } from "react";

//COMPONENTES MATERIAL
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

//COMPONENTES INTERNOS
import { deleteDestino, getDestino } from "../../service/CRUD_Service";
import api from "../../api";
import { getTipoUsuario, logout } from "../../service/authService";

export default function Origem() {
  const history = useHistory("");
  const [names, setNames] = useState([]); //set principal
  const [search, setSearch] = useState(""); //set de busca
  const [loading, setLoading] = useState(false); //set para antes de carregar a pagina aparecer loading
  const [page, setPage] = React.useState(0); //set para paginas
  const [rowsPerPage, setRowsPerPage] = React.useState(50); //set para numeros de paginas.
  const [nameError, setNameError] = useState(""); //set para refresc de pagina
  const [openExcluir, setOpenExcluir] = React.useState(false); //para botao  excluir apenas para visualzar o que esta escrito
  const [open, setOpen] = React.useState(false);

  //handle de paginas
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //handle de open
  const handleExcluirClose = () => {
    setOpenExcluir(false);
  };

  const handleExcluirOpen = () => {
    setOpenExcluir(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //incluir origem
  useEffect(() => {
    setLoading(true);
    api
      .get("/destino")
      .then((res) => {
        let dest = res.data;
        //funcao para ordenar a lista por hora
        const itens = dest.sort(function (a, b) {
          var c = a.lojas;
          var d = b.lojas;
          return c - d; //data mais antiga para mais nova
        });
        setNames(itens);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    reloadDestino();
  }, []);

  const reloadDestino = () => {
    setLoading(true);
    getDestino()
      .then((res) => {
        let dest = res.data;
        //funcao para ordenar a lista por hora
        const itens = dest.sort(function (a, b) {
          var c = a.lojas;
          var d = b.lojas;
          return c - d; //data mais antiga para mais nova
        });
        setNames(itens);
        setLoading(false);
      })
      .catch((err) => setNameError(err));
  };

  //PASSA PARA BOTA EXCLUSAO
  const onDelete = (id) => {
    deleteDestino(id)
      .then(() => {
        reloadDestino();
      })
      .catch((err) => setNameError(err));
  };

  // FAZ O LOADING ANTES DE CARREGAR OS DADOS
  if (loading) {
    return <p>Loading Destino.......</p>;
  }

  // FILTAR ORIGEM NA TABELA
  {
    /* 
  const filteredCountries = names.filter((name) => {
    return name.lojas;
  });
   const filteredCountries = names.filter((name) => {
    return name.lojas.toLowerCase().includes(search.toLowerCase());
  });
  */
  }
  function retornaEletronico(value) {
    return value.lojas;
  }
  var filteredCountries = names.filter(retornaEletronico);

  //EDITE VEICULO
  const onEdit = (id) => {
    history.push("/DestinoEdit", { id });
  };

  return (
    <div className="Destino">
      <div>
        <AppBar position="sticky" color="default">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Lista de Destinos
            </Typography>

            <ListItem
              button
              style={{
                width: 25,
                height: 25,
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                onClick={logout}
                style={{
                  justifyItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ExitToAppIcon style={{ color: "#192d4d" }} />
              </ListItemIcon>
            </ListItem>
          </Toolbar>
        </AppBar>
        <br></br>
      </div>
      <br></br>
      <br></br>

      <Paper
        style={{
          height: 50,
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 10,
        }}
      >
        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 4,
            color: "default",
            height: 35,
            width: 200,
            //  marginTop: 5,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          //onChange={(ev) => setSearch(ev.target.value)}
          placeholder="Pesquisar Destino"
          onChange={(e) => setSearch(e.target.value)}
        />

        <Button
          style={{
            height: 35,
            marginBlockEnd: 5,
            marginTop: 5,
            height: 35,
            marginLeft: 10,
            color: "#fff",
            paddingRight: 20,
          }}
          href="/CadDestino"
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
        >
          Novo
        </Button>
      </Paper>

      <Paper style={{ marginLeft: 10, marginRight: 10 }}>
        <TableContainer>
          <br></br>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Nº Loja
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Razao
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Bairro
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Municipio
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  UF
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Rec.Inicial
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Rec.Final
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  T.Veiculo
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Compr
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333",
                    fontWeight: "bold",
                  }}
                >
                  Operacoes
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#ffff",
                    color: "#333333 ",
                    fontWeight: "bold",
                  }}
                >
                  Opcoes
                </TableCell>
              </TableRow>
            </TableHead>

            {filteredCountries
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((name, id) => (
                <TableBody key={name.id}>
                  <TableRow role="checkbox" key={name.id}>
                    <TableCell align="center">{name.lojas}</TableCell>
                    <TableCell align="center">{name.razao}</TableCell>

                    <TableCell align="center">{name.bairro}</TableCell>
                    <TableCell align="center">{name.municipio}</TableCell>
                    <TableCell align="center">{name.uf}</TableCell>
                    <TableCell align="center">
                      {name.recebimentohrinicial}
                    </TableCell>
                    <TableCell align="center">
                      {name.recebimentohrfinal}
                    </TableCell>
                    <TableCell align="center">{name.tipoveiculo}</TableCell>
                    <TableCell align="center">
                      {name.comprimentoveiculo}
                    </TableCell>
                    <TableCell align="left">
                      {(() => {
                        let operacoes = name.Cad_operacoes || [];
                        if (operacoes.length === 0) {
                          return (
                            <ul>
                              <li
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                <Typography
                                  style={{
                                    marginRight: 10,
                                    backgroundColor: "#E0E1DD",
                                    fontFamily: "serif",
                                    height: "30px",
                                    width: "105px",
                                    color: "#0D1B2A",
                                    textAlign: "center",
                                    borderColor: "#1f2880",
                                    borderRadius: "4px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  Sem operação
                                </Typography>
                              </li>
                            </ul>
                          );
                        } else {
                          return (
                            <ul>
                              {operacoes.map((operacao) => (
                                <li
                                  key={operacao.id}
                                  style={{
                                    display: "inline-block",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      marginRight: 10,
                                      backgroundColor: "#415A77",
                                      fontFamily: "serif",
                                      height: "30px",
                                      width: "105px",
                                      color: "#fff",
                                      textAlign: "center",
                                      borderColor: "#778DA9",
                                      borderRadius: "4px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    {operacao.tpoperacao}
                                  </Typography>
                                </li>
                              ))}
                            </ul>
                          );
                        }
                      })()}
                    </TableCell>

                    <TableCell align="center">
                      <ButtonGroup>
                        {/*BOTAO EDITAR */}
                        <Tooltip
                          placement="right"
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          TransitionComponent={Zoom}
                          title="Editar"
                        >
                          <IconButton
                            aria-label="Editar"
                            style={{ width: 25 }}
                            color="primary"
                          >
                            <EditIcon
                              fontSize="small"
                              onClick={() => onEdit(name.id)}
                            />
                          </IconButton>
                        </Tooltip>

                        {/*BOTAO EXCLUIR 
                        <Tooltip
                          placement='left'
                          open={openExcluir}
                          onClose={handleExcluirClose}
                          onOpen={handleExcluirOpen}
                          TransitionComponent={Zoom}
                          title='Excluir'
                        >
                          <IconButton
                            aria-label='delete'
                            style={{ width: 25 }}
                            color='secondary'
                          >
                            {getTipoUsuario() == 2 ? (
                              ''
                            ) : (
                              <DeleteIcon
                                fontSize='small'
                                className='btexlcuir'
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Deseja confirmar exclusão'
                                    )
                                  )
                                    onDelete(name.id);
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        */}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>

          {/*PAGINAÇÃO DE PAGINAS**/}
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por Paginas"
              component="div"
              count={names.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableContainer>
      </Paper>
    </div>
  );
}
