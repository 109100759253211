//FALTA FAZER//

//COMPONENSTE REACT
import React, { useState, useEffect } from "react";
//COMPONENTES MATERIAL
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Form from "react-bootstrap/Form";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import api from "../../api";

export default function Origem() {
  const history = useHistory("");
  const [names, setNames] = useState([]); //set principal

  const [loading, setLoading] = useState(false); //set para antes de carregar a pagina aparecer loading

  //incluir origem
  useEffect(() => {
    api
      .get("/exportcsventregasControllers")
      .then((res) => {
        let dest = res.data;

        setNames(dest);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // FAZ O LOADING ANTES DE CARREGAR OS DADOS
  if (loading) {
    return <p>Loading Entregas.......</p>;
  }

  const columns = [
    { field: "idcarga", headerName: "ID Viagem", width: 90 },
    { field: "operacao", headerName: "Operacao", width: 160 },
    { field: "descricao", headerName: "Descricao da origem ", width: 160 },
    { field: "loja", headerName: "Loja", width: 90 },
    { field: "perfil", headerName: "Perfil", width: 90 },
    { field: "qtdpl", headerName: "Qtd Pallet", width: 90 },
    { field: "placa", headerName: "Placa Carreta", width: 90 },
    { field: "motorista", headerName: "Motorista", width: 200 },
    { field: "ncarga", headerName: "Numero carga", width: 130 },
    {
      field: "datacargacriada",
      headerName: "Data Carga Criada",
      width: 200,
    },
    {
      field: "datasolicitamdfe",
      headerName: "Data Solicitacao MDF",
      width: 200,
    },
    {
      field: "datastatusprocessamento",
      headerName: "Data Visualizado",
      width: 200,
    },
    {
      field: "datastatusnf",
      headerName: "Data Chegada NF",
      width: 200,
    },
    {
      field: "datastatusdoc",
      headerName: "Data Envio CTE",
      width: 200,
    },
    {
      field: "tempoenviocte",
      headerName: "Tempo Envio CTE",
      width: 200,
    },
  ];

  {
    /*

*/
  }

  const teste = () => {
    var results = [];
    var len = names.length;
    for (var i = 0; i < len; i++) {
      //  console.log(names[i].idstatuscd);
      const format1 = "DD/MM/YYYY HH:mm:ss";

      //formatar da criada
      //formatar data
      let timeDatacriada = new Date(names[i].created_at);
      let formatarcriada = moment.utc(timeDatacriada).format(format1);

      //formatar data
      let timesolicitamdfe = new Date(names[i].datasolicitamdfe);
      let formatardatamdf = moment.utc(timesolicitamdfe).format(format1);

      //formatar data

      let timedatastatusprocessamento = new Date(
        names[i].datastatusprocessamento
      );
      let formataroutraDatadatastatusprocessamento = moment
        .utc(timedatastatusprocessamento)
        .format(format1);

      //formatar data
      let timestatusnf = new Date(names[i].datastatusnf);
      let formatardatastatusnf = moment.utc(timestatusnf).format(format1);

      //formatar data
      let timedatastatusdoc = new Date(names[i].datastatusdoc);
      let formatardatastatusdoc = moment.utc(timedatastatusdoc).format(format1);

      //teste de calculo tempo de cd
      let inicio = new Date(timesolicitamdfe);
      let final = new Date(timedatastatusdoc);
      let diferenca = new Date(final - inicio);
      var resultado =
        ("0" + diferenca.getUTCHours()).slice(-2) +
        ":" +
        ("0" + diferenca.getUTCMinutes()).slice(-2);

      results.push({
        id: names[i].id,
        idcarga: names[i].idcarga,
        operacao: names[i].operacao,
        descricao: names[i].descricao,
        loja: names[i].lojas,
        perfil: names[i].perfil,
        qtdpl: names[i].qtdpallet,
        placa: names[i].placa,
        motorista: names[i].nome,
        ncarga: names[i].ncarga,
        datacargacriada: formatarcriada,
        datasolicitamdfe: formatardatamdf,
        datastatusprocessamento: formataroutraDatadatastatusprocessamento,
        datastatusnf: formatardatastatusnf,
        datastatusdoc: formatardatastatusdoc,
        tempoenviocte: resultado,
      });
    }
    return results;
  };
  const rows = teste();
  console.log(names);

  return (
    <div className="Destino">
      <div>
        <AppBar position="relative" color="default">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Entregas realizadas
            </Typography>
            <ListItem
              button
              style={{
                width: 25,
                height: 25,
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></ListItem>
          </Toolbar>
        </AppBar>
        <br></br>
      </div>
      {/* 
      <Paper
        style={{
          marginLeft: 10,
          marginRight: 10,
          height: 40,
          marginBottom: 15,
          flexdirection: 'row',
        }}
      ></Paper>
       */}
      <Paper style={{ marginLeft: 10, marginRight: 10 }}>
        <Box sx={{ height: 500, width: "100%" }}>
          {names.length <= 0 ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={40}
              rowsPerPageOptions={[40]}
              checkboxSelection
              disableSelectionOnClick
              localeText={{
                toolbarColumns: "Colunas",
                toolbarFilters: "Filtros",
                toolbarDensity: "Densidade",
                toolbarExport: "Exportar",
              }}
              components={{ Toolbar: GridToolbar }}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
}
