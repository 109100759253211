import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

import api from "../api";
import { getGrafico1 } from "../service/CRUD_Service";

export default function Grafico1({ datafiltra, operacoes }) {
  const [totalCarga, setTotalCarga] = useState(1);

  function formtardata() {
    let [month, date, year] = new Date(datafiltra)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatade = year + "-" + date + "-" + month;

    return formatade;
  }

  useEffect(() => {
    const dates = new Date();
    let [month, date, year] = new Date(dates)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatade = year + "-" + date + "-" + month;

    setDtatual(formatade);
  });

  useEffect(() => {
    const dates = new Date(datafiltra);
    let [month, date, year] = new Date(dates)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatade = year + "-" + date + "-" + month;
  });
  const [dtatual, setDtatual] = useState();

  //incluir veiculo
  useEffect(() => {
    api
      .get("/graficoentregasfinalizadas")
      .then((res) => {
        let carg = res.data[0];
        let n = null;
        //let comparar = carg.filter((x) => x.datachegadapadrao !== n);
        // let filtronormal = comparar.filter((x) => x.normal >= 1);

        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = a.lojas;
          var d = b.lojas;
          return c - d; //data mais antiga para mais nova
        });
        // Remover ou definir valores 0 como vazio
        itens.forEach((item) => {
          item.frios = item.frios === "0" ? "" : item.frios;
          item.horti = item.horti === "0" ? "" : item.horti;
          item.normal = item.normal === "0" ? "" : item.normal;
          item.panificacaofrios =
            item.panificacaofrios === "0" ? "" : item.panificacaofrios;
          item.panificacaoseca =
            item.panificacaoseca === "0" ? "" : item.panificacaoseca;
        });

        setTotalCarga(itens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function filtramanual() {
    if (totalCarga !== 1) {
      let busca = totalCarga.filter((item) => {
        let e = formtardata();
        return item.dataentregapadrao.toString().includes(e.toString());
      });
      return busca;
    } else {
      console.log("teste");
    }
  }

  function filtraautomatico() {
    if (totalCarga !== 1) {
      let busca = totalCarga.filter((item) => {
        let e = dtatual;
        return item.dataentregapadrao.toString().includes(e.toString());
      });
      return busca;
    } else {
      console.log("teste");
    }
  }

  const manual = filtramanual();
  const automatico = filtraautomatico();

  const dataatual = dtatual;
  const Datafiltra = dtatual;
  const dados = Datafiltra == dataatual ? automatico : manual;

  useEffect(() => {
    let id = setInterval(() => {
      Buscaautomatico().then();
    }, 60000);
    return () => clearInterval(id);
  });

  async function Buscaautomatico() {
    let busca = await getGrafico1();
    let dados1 = busca[0];
    let n = null;
    // let comparar = dados1.filter((x) => x.datachegadapadrao !== n);
    //funcao para ordenar a lista por hora
    const itens = dados1.sort(function (a, b) {
      var c = a.lojas;
      var d = b.lojas;
      return c - d; //data mais antiga para mais nova
    });
    // Remover ou definir valores 0 como vazio
    itens.forEach((item) => {
      item.frios = item.frios === "0" ? "" : item.frios;
      item.horti = item.horti === "0" ? "" : item.horti;
      item.normal = item.normal === "0" ? "" : item.normal;
      item.panificacaofrios =
        item.panificacaofrios === "0" ? "" : item.panificacaofrios;
      item.panificacaoseca =
        item.panificacaoseca === "0" ? "" : item.panificacaoseca;
    });
    return setTotalCarga(itens);
  }

  function filtraManualOperacaoes() {
    if (totalCarga !== 1) {
      let d = dados; // recebe os dados apos filtro de data
      let Op = Number(operacoes); // garante que o tipo de dado vai ser sempre numero
      let filtro = d?.filter((x) => x.id === Op); // manipula a operacao de acordo com filtro

      return filtro;
    } else {
      console.log("teste");
    }
  }

  let DadosFiltroOperacao = filtraManualOperacaoes();
  setInterval(DadosFiltroOperacao, 60000);

  var ConverteOperacaotipo = Number(operacoes);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%" backgroundColor="#F5F5F5">
        <BarChart
          width={500}
          height={300}
          data={DadosFiltroOperacao}
          margin={{
            top: 3,
            right: 30,
            left: 20,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ stroke: "#7986cb", strokeWidth: 1 }} dataKey="lojas" />

          <Tooltip />
          <Legend />
          {/*
          Tipo      prod        local
          Koch cd   3             5
          */}
          {ConverteOperacaotipo === 3 ? (
            <React.Fragment>
              <Bar dataKey="normal" stackId="a" fill="#ffc658" barSize={70}>
                <LabelList
                  dataKey="normal"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="frios" stackId="a" fill="#8884d8" barSize={70}>
                <LabelList
                  dataKey="frios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="horti" stackId="a" fill="#82ca9d" barSize={70}>
                <LabelList
                  dataKey="horti"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
            </React.Fragment>
          ) : ConverteOperacaotipo === 1 ? (
            <React.Fragment>
              {/*
          Tipo          prod        local
          Panificacao   1             2
          */}
              <Bar
                dataKey="panificacaofrios"
                stackId="a"
                fill="#b3d2e5"
                barSize={70}
              >
                <LabelList
                  dataKey="panificacaofrios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar
                dataKey="panificacaoseca"
                stackId="a"
                fill="#dbb429"
                barSize={70}
              >
                <LabelList
                  dataKey="panificacaoseca"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
            </React.Fragment>
          ) : ConverteOperacaotipo === 2 ? (
            <React.Fragment>
              {/*
          Tipo      prod        local
          Passarela   2            3
          */}
              <Bar dataKey="normal" stackId="a" fill="#ffc658" barSize={70}>
                <LabelList
                  dataKey="normal"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="frios" stackId="a" fill="#8884d8" barSize={70}>
                <LabelList
                  dataKey="frios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="horti" stackId="a" fill="#82ca9d" barSize={70}>
                <LabelList
                  dataKey="horti"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar
                dataKey="panificacaofrios"
                stackId="a"
                fill="#b3d2e5"
                barSize={70}
              >
                <LabelList
                  dataKey="panificacaofrios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar
                dataKey="panificacaoseca"
                stackId="a"
                fill="#dbb429"
                barSize={70}
              >
                <LabelList
                  dataKey="panificacaoseca"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
            </React.Fragment>
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
