import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { icon } from "leaflet";
import "../App.css";

import apistr from "../api"; //api str
import { logout, getOperacao } from "../service/authService";

import { ApiTracerVeiculos } from "../service/authtracer";

import axios from "axios";

export default function Mapa({ transito, operacoesMapa }) {
  const [veiculos, SetVeiculos] = useState();
  const [entregas, SetEntregas] = useState();
  const [destinos, SetDestinos] = useState();
  const [tracer, setTracer] = useState();

  const classes = useRowStyles();
  console.log("transito", transito);
  //busca destinos api
  useEffect(() => {
    apistr
      .get("/filterviagemconferente")
      .then((res) => {
        let dados = res.data;
        SetEntregas(dados);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  console.log("operacoes", operacoesMapa);
  //busca destinos api
  useEffect(() => {
    apistr
      .get("/destino")
      .then((res) => {
        let dados = res.data;
        let nulo = null;
        let zero = 0;
        let um = 1;

        let Op = Number(operacoesMapa);

        let filt = dados.filter(
          (item) =>
            (item.longitude !== nulo) & (item.longitude !== zero) &&
            item.longitude !== um
        );

        const dest = filt?.filter((v) => {
          // Obtendo todos os IDs de operações associadas à origem da viagem
          const destinoOperacoesIds = v.Cad_operacoes?.map((o) => o.id);

          // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
          return destinoOperacoesIds?.some((id) => id === Op);
        });

        SetDestinos(dest);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  //busca veiculos API
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        let dados = carg.map((item) => [
          item.name,
          item.lat,
          item.lng,
          item.temperature,
          item.speed,
        ]);

        SetVeiculos(dados);
        setTracer(dados);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  const myIconveiculo = L.icon({
    iconUrl: require("../imagem/cargo-truck.png"),
    iconSize: [20, 30],
    iconAnchor: [22, 30],
    popupAnchor: [-3, -26],
    shadowSize: [40, 20],
  });
  const myIconlojas = L.icon({
    iconUrl: require("../imagem/pin.png"),
    iconSize: [25, 25],
    iconAnchor: [23, 30],
    popupAnchor: [-10, -42],
    shadowSize: [70, 60],
  });

  function MultipleMarkersVeiculos() {
    return (
      <Marker key={transito} position={transito} icon={myIconveiculo}></Marker>
    );
  }

  function MultipleMarkersDestinos() {
    return destinos?.map((coordinata, index) => {
      return (
        <Marker
          key={index}
          position={[coordinata.latitude, coordinata.longitude]}
          icon={myIconlojas}
        >
          <Popup>
            Razao : {coordinata.razao}
            <br /> Loja : {coordinata.lojas}
          </Popup>
        </Marker>
      );
    });
  }

  return (
    <div>
      <MapContainer center={transito} zoom={18}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {(() => {
          if (transito) {
            return <MultipleMarkersVeiculos />;
          }
        })()}
        {(() => {
          if (destinos) {
            return <MultipleMarkersDestinos />;
          }
        })()}
      </MapContainer>
    </div>
  );
}
//styles
const useRowStyles = makeStyles({
  root: {
    //backgroundColor: 'red',
    height: 320,
    width: 650,
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
  },
});
