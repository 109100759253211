import React, { Component } from 'react';
import api from '../api';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TextField from '@material-ui/core/TextField';

class operacoes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tpoperacao: ''  
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post('/operacoes', this.state)
      .then((response) => {
        // console.log(response);
        alert('OPERACAO SALVO COM SUCESSO!!! ');
        window.location = '/ListOperacoes';
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { tpoperacao } = this.state;

    return (
      <div>
        <Paper
          square
          elevation={1}
          variant='outlined'
          style={{
            backgroundColor: '#FFFF',
            // height: 500,
            //width: 450,
            // marginLeft: 230,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <form
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#abb8c3',
                borderRadius: 10,
                minWidth: 660,
                marginTop: 10,
                marginBottom: 20,
              }}
              onSubmit={this.submitHandler}
            >
             
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TextField
                  style={{
                    width: 490,
                    marginTop: 25,
                  }}
                  id='standard-basic'
                  size='small'
                  variant='filled'
                  label='Nome'
                  type='text'
                  name='tpoperacao'
                  value={tpoperacao}
                  onChange={this.changeHandler}
                />
              </div>

            
         
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 55,
                  //backgroundColor: 'red',
                }}
              >
                <Button
                  style={{
                    marginTop: 50,
                    width: 260,
                    marginBottom: 10,
                  }}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disableElevation
                >
                  Salvar
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    );
  }
}

export default operacoes;
