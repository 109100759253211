//grid listar
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//COMPONENTES INTERNOS
import Tabela from '../MensagemAutomatica/Tabela_Mensagem';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    justify: 'center',
    marginRight: theme.spacing(2),
  },
  formpaper: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(-1),

    marginLeft: theme.spacing(-20),
  },
}));

export default function GridListar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify='left'>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Tabela />
        </Grid>
      </Grid>
    </div>
  );
}
