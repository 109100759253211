import React from 'react';

//APIS MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  box: {
    position: 'absolute',
    marginTop: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 50,
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        Rota nao definido para tipo de usuario
      </div>
    </div>
  );
}
