import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logout, getTipoUsuario } from '../service/authService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  nomepag: {
    color: 'inherit',
  },
  logoutitem: {
    width: 25,
    height: 25,
    justifyItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logouticon: {
    justifyItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  razao: {
    paddingRight: 20,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position='static' color='default'>
        <Toolbar>
          <Typography variant='h6' className={classes.nomepag}>
            Nova Origem
          </Typography>
          <Typography
            variant='h6'
            className={classes.title}
          ></Typography>

          <Typography color='inherit' className={classes.razao}>
            STR TRANSPORTES LOGISTICA
          </Typography>
          <ListItem button className={classes.logoutitem}>
            <ListItemIcon
              onClick={logout}
              className={classes.logouticon}
            >
              <ExitToAppIcon style={{ color: '#192d4d' }} />
            </ListItemIcon>
          </ListItem>
        </Toolbar>
      </AppBar>
    </div>
  );
}
