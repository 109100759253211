import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Divider from "@material-ui/core/Divider";

import Box from "@mui/material/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import api from "../api";
import axios from "axios";
import apitracer from "../apitracer";
import { ApiTracerVeiculos } from "../service/authtracer";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: "red",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",

    alignItems: "center",
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: "#ffff ",
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: "#ffff",
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -5,
    //marginLeft: 42,
  },
});

export default function CardVeiculosDisponivel({ datafiltra, operacoes }) {
  const classes = useStyles();
  const [veiculo, setVeiculo] = useState(1);
  const [veiculosComCarga, setVeiculosComCarga] = useState();
  const [veiculostracer, setVeiculostracer] = useState();
  const [origem, setOrigem] = useState();

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //origem cadastradas para buscar a long e lat
  useEffect(() => {
    api
      .get("/origem")
      .then((res) => {
        let carg = res.data;
        setOrigem(carg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //veiculos com carga
  useEffect(() => {
    api
      .get("/filterviagemmotorista")
      .then((res) => {
        let carg = res.data;
        let dados = carg.map((item) => item.fkveiculotrator.placa);
        let filter = dados.filter((x) => x !== "");

        setVeiculosComCarga(filter);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/filterviagemmotorista")
        .then((res) => {
          let carg = res.data;
          let dados = carg.map((item) => item.fkveiculotrator.placa);

          let filter = dados.filter((x) => x !== "");

          setVeiculosComCarga(filter);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/veiculos")
        .then((res) => {
          let carg = res.data;
          let status = "Ativo";
          let nrfrota = 200;
          let tipo = "CT"; //parametro para filtro
          let dados = carg.map((item) => item);
          let filtro = dados.filter(
            (x) =>
              x.tipoveiculo === tipo &&
              x.status === status &&
              x.nfrota === nrfrota
          );

          setVeiculo(filtro);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  //incluir veiculo
  useEffect(() => {
    api
      .get("/veiculos")
      .then((res) => {
        let carg = res.data;
        let status = "Ativo";
        let nrfrota = 200;
        let tipo = "CT"; //parametro para filtro
        let dados = carg.map((item) => item);
        let filtro = dados.filter(
          (x) =>
            x.tipoveiculo === tipo &&
            x.status === status &&
            x.nfrota === nrfrota
        );

        setVeiculo(filtro);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //tracer
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        let dados = carg.map((item) => [item.name, item.lat, item.lng]);

        let dadostracer = carg
          .map((i) => ({
            placatracer: i.name,
            lat: i.lat,
            long: i.lng,
          }))
          .flat();

        setVeiculostracer(dadostracer);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          let dados = carg.map((item) => [item.name, item.lat, item.lng]);

          let dadostracer = carg
            .map((i) => ({
              placatracer: i.name,
              lat: i.lat,
              long: i.lng,
            }))
            .flat();

          setVeiculostracer(dadostracer);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  function MetodoContar() {
    if (veiculo !== 1) {
      let orig = origem;
      let placastracer = veiculostracer;
      let a = veiculo;
      let b = veiculosComCarga;
      let Op = Number(operacoes); // garante que o tipo de dado vai ser sempre numero

      let placas = a?.filter((x) => !b?.includes(x.placa));

      //filtra a operação
      let filterorigem = orig?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      //filtra a operação
      let filterVeiculo = placas?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      var veiculosAgregados = placas?.filter(
        (item) => item.vinculo == "Agregado"
      );

      let filteOperacaoAgregados = veiculosAgregados?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      let novoArray = [];
      let placasdistancia = [];

      filterVeiculo?.map((item) =>
        placastracer?.map((x) => {
          if (x.placatracer == item.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      // Loop para calcular a distância entre cada par de pontos
      for (var i = 0; i < filterorigem?.length; i++) {
        for (var j = i + 1; j < novoArray?.length; j++) {
          placasdistancia.push({
            id: novoArray[j].id,
            placa: novoArray[j].placa,
            marca: novoArray[j].marca,
            tipoveiculo: novoArray[j].tipoveiculo,
            vinculo: novoArray[j].vinculo,
            descricaoOrigem: filterorigem[i].descricao,

            distancia: geolocalizacao(
              filterorigem[i].latitude,
              filterorigem[i].longitude,
              novoArray[j].lat,
              novoArray[j].long
            ),
          });
        }
      }

      /*
    
      

   for (var i = 0; i < novoArray?.length; i++) {
      placasdistancia.push({
        placa: novoArray[i].placa,
        marca: novoArray[i].marca,
        tipoveiculo: novoArray[i].tipoveiculo,
        vinculo: novoArray[i].vinculo,

        distancia: geolocalizacao(
          novoArray[i].lat,
          novoArray[i].long,
          latcd,
          loncd
        ),
      });
    }


    //funcao para ordenar a lista por hora
    const ordena = placasdistancia.sort(function (a, b) {
      var c = a.distancia;
      var d = b.distancia;
      return c - d; //data mais antiga para mais nova
    });

    // var AgruparArray = [...ordena, ...veiculosAgregados];
    return ordena?.length + veiculosAgregados?.length;

    */
      //funcao para ordenar a lista por hora
      const ordena = placasdistancia.sort(function (a, b) {
        var c = a.distancia;
        var d = b.distancia;
        return c - d; //data mais antiga para mais nova
      });

      // Usando Set para contar valores exclusivos com base na propriedade 'placa'
      const placasUnicas = new Set(ordena.map((item) => item.placa));
      const totalPlacasUnicas = placasUnicas.size;

      // var AgruparArray = [...ordena, ...veiculosAgregados];
      return totalPlacasUnicas + filteOperacaoAgregados?.length;
    } else {
      console.log("teste");
    }
  }

  function ExibirPlacas() {
    if (veiculo !== 1) {
      let orig = origem;
      let placastracer = veiculostracer;
      let a = veiculo;
      let b = veiculosComCarga;
      let Op = Number(operacoes); // garante que o tipo de dado vai ser sempre numero

      let placas = a?.filter((x) => !b?.includes(x.placa));

      // retirar a origem padrao
      let isorigem = orig?.filter((x) => x.id !== 3);

      //filtra a operação
      let filterorigem = isorigem?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      //filtra a operação
      let filterVeiculo = placas?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      var veiculosAgregados = placas?.filter(
        (item) => item.vinculo == "Agregado"
      );

      let filteOperacaoAgregados = veiculosAgregados?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      let novoArray = [];
      let placasdistancia = [];

      filterVeiculo?.map((item) =>
        placastracer?.map((x) => {
          if (x.placatracer == item.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      // Loop para calcular a distância entre cada par de pontos
      for (var i = 0; i < filterorigem?.length; i++) {
        for (var j = i + 1; j < novoArray?.length; j++) {
          placasdistancia.push({
            id: novoArray[j].id,
            placa: novoArray[j].placa,
            marca: novoArray[j].marca,
            tipoveiculo: novoArray[j].tipoveiculo,
            vinculo: novoArray[j].vinculo,
            descricaoOrigem: filterorigem[i].descricao,

            distancia: geolocalizacao(
              filterorigem[i].latitude,
              filterorigem[i].longitude,
              novoArray[j].lat,
              novoArray[j].long
            ),
          });
        }
      }

      /*
    
      

   for (var i = 0; i < novoArray?.length; i++) {
      placasdistancia.push({
        placa: novoArray[i].placa,
        marca: novoArray[i].marca,
        tipoveiculo: novoArray[i].tipoveiculo,
        vinculo: novoArray[i].vinculo,

        distancia: geolocalizacao(
          novoArray[i].lat,
          novoArray[i].long,
          latcd,
          loncd
        ),
      });
    }


    //funcao para ordenar a lista por hora
    const ordena = placasdistancia.sort(function (a, b) {
      var c = a.distancia;
      var d = b.distancia;
      return c - d; //data mais antiga para mais nova
    });

    // var AgruparArray = [...ordena, ...veiculosAgregados];
    return ordena?.length + veiculosAgregados?.length;

    */
      //funcao para ordenar a lista por hora
      const ordena = placasdistancia.sort(function (a, b) {
        var c = a.distancia;
        var d = b.distancia;
        return c - d; //data mais antiga para mais nova
      });

      const newArray = ordena.reduce((acc, item) => {
        let existing = acc.find((e) => e.placa === item.placa);
        if (!existing) {
          existing = {
            id: item.id,
            placa: item.placa,
            marca: item.marca,
            tipo: item.tipoveiculo,
            vinculo: item.vinculo,
            descricaodasorigens: [],
            placacavalo: item.placa,
          };
          acc.push(existing);
        }

        existing.descricaodasorigens.push({
          distancia: item.distancia,
          descricaoOrigem: item.descricaoOrigem,
        });

        return acc;
      }, []);

      const newArrayterceiro = filteOperacaoAgregados.reduce((acc, item) => {
        let existing = acc.find((e) => e.placa === item.placa);
        if (!existing) {
          existing = {
            id: item.id,
            placa: item.placa,
            marca: item.marca,
            tipo: item.tipoveiculo,
            vinculo: item.vinculo,
            descricaodasorigens: [],
            placacavalo: item.placa,
          };
          acc.push(existing);
        }

        existing.descricaodasorigens.push({
          distancia: 2,
          descricaoOrigem: "Sem rastreador",
        });

        return acc;
      }, []);

      var AgruparArray = [...newArray, ...newArrayterceiro];

      return (
        <TableContainer sx={{ maxHeight: 632, minWidth: 950 }}>
          <Table
            //  sx={{ minWidth: 950 }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Placa</TableCell>
                <TableCell align="center">Marca</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">Vinculo</TableCell>
                <TableCell align="center">Distancia CD</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AgruparArray?.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.placa}</TableCell>
                  <TableCell component="th" align="center">
                    {item.marca}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {item.tipoveiculo}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {item.vinculo}
                  </TableCell>
                  {item.descricaodasorigens?.map((x) => (
                    <TableCell component="th" align="center">
                      {(() => {
                        let distancia = parseInt(x.distancia);
                        let calculo = (distancia * 1) / 1000;
                        let descricao = x.descricaoOrigem;

                        return (
                          <>
                            <Typography
                              style={{
                                backgroundColor: "#415A77",
                                fontFamily: "serif",
                                color: "#fff",
                                borderColor: "#778DA9",
                                borderRadius: "4px",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                //  justifyContent: "space-between", // Distribui espaço entre itens
                                textAlign: "center",
                                minWidth: 90,
                              }}
                            >
                              {distancia === 2 ? "CD" : descricao}
                            </Typography>
                            <Typography
                              style={{
                                // backgroundColor: "#EDF1F6",
                                fontFamily: "serif",
                                //  color: "#fff",
                                borderColor: "#778DA9",
                                borderRadius: "4px",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                //  justifyContent: "space-between", // Distribui espaço entre itens
                                textAlign: "center",
                                minWidth: 90,
                              }}
                            >
                              {calculo <= 0.3
                                ? descricao
                                : calculo == 2
                                ? calculo
                                : calculo}
                            </Typography>
                          </>
                        );
                      })()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      console.log("teste");
    }
  }

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button onClick={handleClickOpen}>
            <VisibilityIcon className={classes.icon} />
          </Button>
          <Typography className={classes.qtd}>
            {MetodoContar() == 0 ? 0 : MetodoContar()}
          </Typography>
        </Box>
      </CardContent>

      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Typography className={classes.title}>Cavalo Disp.</Typography>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Veiculo Trator Disponiveis"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <ExibirPlacas />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}
