import React, { useState, useEffect } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import {
  getMotoristaid,
  putMot,
  deleteGrupooperacoesmotorista,
} from "../service/CRUD_Service";
import Barra from "../componentes/barra_motoristaEdit";
import Paper from "@material-ui/core/Paper";

import Card from "@material-ui/core/Card";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import api from "../api";

export default function MotoristaEdit({ history }) {
  //class updateveiculo extends Component{
  const { id } = history.location.state;
  const [mot, setMot] = useState({});
  const [motErro, setMotErro] = useState("");
  const [codigo, setCodigo] = useState("");
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [turno, setTurno] = useState("");
  const [tipo, setTipo] = useState("");
  const [status, setStatus] = useState("");

  const [btntoperacao, setBtnoperacao] = useState();
  const [tipoperacao, setTipoperacao] = useState();
  const [tpoperacoes, setTpoperacoes] = useState();

  //tipo de operacao
  useEffect(() => {
    api
      .get("/operacoes")
      .then((res) => {
        setTipoperacao(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getMotoristaid(id)
      .then((res) => {
        setMot(res);
        setCodigo(res.codigo);
        setNome(res.nome);
        setCpf(res.cpf);
        setTurno(res.turno);
        setTipo(res.tipo);
        setStatus(res.status);
        setTpoperacoes(res.Cad_operacoes);
      })
      .catch((err) => setMotErro(err));
  }, [id]);

  //codigo
  const onChangencodigo = (event) => {
    setCodigo(event.target.value);
  };
  // nome
  const onChangenome = (event) => {
    setNome(event.target.value);
  };
  // cpf
  const onChangecpf = (event) => {
    setCpf(event.target.value);
  };
  //turno
  const onChangeturno = (event) => {
    setTurno(event.target.value);
  };
  //tipo
  const onChangetipo = (event) => {
    setTipo(event.target.value);
  };
  //tipo
  const onChangestatus = (event) => {
    setStatus(event.target.value);
  };

  // //tipo operacao
  const onChangeoperacao = (event) => {
    setBtnoperacao(event.target.value);
  };

  const onDeleteAgrupoperacao = (id) => {
    deleteGrupooperacoesmotorista(id)
      .then(() => {
        refreshPage();
      })
      .catch((err) => console.log(err));
  };

  ///atualiza a pagina
  const refreshPage = () => {
    window.location.reload();
  };

  //salvar operacao
  const Salvaroperacao = () => {
    let filtraroperacao = tpoperacoes.filter((x) =>
      btntoperacao.includes(x.id)
    );

    if (filtraroperacao.length > 0) {
      return alert(
        "Atencao !!!, nao pode vincular operacao ja existente no usuario"
      );
    } else {
      api
        .post("agrupaoperacoesmotorista", {
          fkmotorista: id,
          fkoperacoes: btntoperacao,
        })
        .then((error) => {
          refreshPage();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const toEdit = (event) => {
    event.preventDefault();
    mot.codigo = codigo;
    mot.nome = nome;
    mot.cpf = cpf;
    mot.turno = turno;
    mot.tipo = tipo;
    mot.status = status;

    putMot(id, mot)
      .then(() => history.push("/Listarmotorista"))
      .catch((err) => setMotErro(err));
  };

  return (
    <div className="Users">
      <Paper
        square
        elevation={4}
        style={{
          backgroundColor: "#FFFF",
          marginLeft: 100,
          marginRight: 100,
          marginTop: 50,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#abb8c3",
          borderRadius: 10,
          minWidth: 410,
        }}
      >
        <form>
          <div
            style={{
              display: "flex",
              backgroundColor: "#191E4D",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              height: 50,
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                color: "#cccccc",
              }}
            >
              Editar Motoristas
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                minWidth: 100,
                maxWidth: 100,
                flex: 1,
                marginRight: 50,
              }}
              id="outlined-textarea"
              label="Codigo"
              placeholder="00"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="codigo"
              value={codigo}
              onChange={onChangencodigo}
            />

            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                minWidth: 350,
                marginRight: 50,
              }}
              id="outlined-textarea"
              label="Nome"
              placeholder="******"
              multiline
              variant="filled"
              margin="dense"
              type="text"
              name="nome"
              value={nome}
              onChange={onChangenome}
            />

            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                maxWidth: 390,
                minWidth: 350,
              }}
              id="outlined-textarea"
              label="CPF"
              placeholder="XXXXX"
              multiline
              variant="filled"
              margin="dense"
              type="number"
              name="cpf"
              value={cpf}
              onChange={onChangecpf}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Turno
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Turno"
                name="turno"
                value={turno}
                onChange={onChangeturno}
              >
                <MenuItem value="">
                  <em>Turno...</em>
                </MenuItem>

                <MenuItem value={"Comercial"}>Comercial</MenuItem>
                <MenuItem value={"Turno 1"}>Turno 1</MenuItem>
                <MenuItem value={"Turno 2"}>Turno 2</MenuItem>
                <MenuItem value={"Turno 3"}>Turno 3</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Tipo"
                name="tipo"
                value={tipo}
                onChange={onChangetipo}
              >
                <MenuItem value="">
                  <em>Tipo.....</em>
                </MenuItem>

                <MenuItem value={"Frota"}>Frota</MenuItem>
                <MenuItem value={"Agregado"}>Agregado</MenuItem>
                <MenuItem value={"Terceiro"}>Terceiro</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="status"
                name="status"
                value={status}
                onChange={onChangestatus}
              >
                <MenuItem value="">
                  <em>Status.....</em>
                </MenuItem>

                <MenuItem value={"ativo"}>Ativo</MenuItem>
                <MenuItem value={"inativo"}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              //  alignItems: 'center',
              //  justifyContent: 'center',
              justifyContent: "space-evenly",
              paddingLeft: 50,
              paddingRight: 50,
              marginTop: 25,
            }}
          >
            {/*CARD PARA DEFINIR A OPERACAO QUE ELE PERTENCE    */}
            <Card
              style={{
                // display: "flex",
                //maxWidth: 390,
                flex: 1,
                minWidth: 350,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cccccc",
                  marginBottom: 15,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Typography
                  style={{
                    fontSize: 17,
                  }}
                >
                  Adicionar operacao
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 500,
                    marginTop: 10,
                    marginBottom: 30,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 10,
                      marginLeft: 5,
                      fontSize: 14,
                    }}
                  >
                    Mensagem :
                  </Typography>
                  <FormControl
                    id="standard-basic"
                    style={{ maxWidth: 225, minWidth: 200, flex: 1 }}
                  >
                    <Select
                      native
                      value={btntoperacao}
                      onChange={onChangeoperacao}
                      label="operacao"
                      inputProps={{
                        name: "operacao",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {tipoperacao?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tpoperacao}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    style={{
                      marginTop: 5,
                      color: "#2d8659",
                      height: 40,
                      width: 40,
                      borderRadius: 40 / 2,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                    onClick={Salvaroperacao}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: 30,
                        color: "#2d8659",
                      }}
                    />
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //backgroundColor: 'red',
                  marginBottom: 15,
                }}
              >
                <List
                  dense
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                  }}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#abb8c3",
                    borderRadius: 5,
                    //backgroundColor: 'red',
                  }}
                >
                  {tpoperacoes?.map((value) => (
                    <ListItem
                      key={value}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() =>
                            onDeleteAgrupoperacao(
                              value.agrupaoperacoesmotoristas.id
                            )
                          }
                        >
                          <DeleteIcon
                            style={{
                              color: "#862d2d",
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        <Typography>{value.id}</Typography>
                      </ListItemText>

                      <ListItemText>
                        <Typography>{value.tpoperacao}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Card>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginTop: 5,
                marginBottom: 30,
                marginTop: 50,
              }}
              variant="contained"
              color="primary"
              onClick={toEdit}
            >
              Salvar
            </Button>
          </div>
        </form>
      </Paper>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}
