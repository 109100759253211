import React, { Component } from "react";
import api from "../api";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TextField from "@material-ui/core/TextField";

class usuario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      cpf: "",
      password: "",
      tipo: "",
      contato: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post("/users", this.state)
      .then((response) => {
        // console.log(response);
        alert("USUARIO SALVO COM SUCESSO!!! ");
        window.location = "/ListarUsers";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { name, cpf, password, tipo, contato } = this.state;

    return (
      <div>
        <Paper
          square
          elevation={1}
          variant="outlined"
          style={{
            backgroundColor: "#FFFF",
            // height: 500,
            //width: 450,
            // marginLeft: 230,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#abb8c3",
                borderRadius: 10,
                minWidth: 660,
                marginTop: 10,
                marginBottom: 20,
              }}
              onSubmit={this.submitHandler}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 55,
                  //backgroundColor: 'red',
                }}
              >
                <AccountCircleIcon
                  color="primary"
                  style={{
                    marginTop: -22,
                    fontSize: 70,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  style={{
                    width: 490,
                    marginTop: 25,
                  }}
                  id="standard-basic"
                  size="small"
                  variant="filled"
                  label="Nome"
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.changeHandler}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: 660,
                }}
              >
                <TextField
                  style={{
                    marginRight: 40,
                    marginTop: 30,
                    width: 225,
                  }}
                  id="standard-basic"
                  size="small"
                  variant="filled"
                  label="CPF"
                  type="text"
                  name="cpf"
                  value={cpf}
                  onChange={this.changeHandler}
                />
                <TextField
                  style={{ marginTop: 30, width: 225 }}
                  id="filled-basic"
                  size="small"
                  variant="filled"
                  label="Telefone"
                  type="text"
                  name="contato"
                  value={contato}
                  onChange={this.changeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormControl
                  id="standard-basic"
                  style={{
                    marginRight: 40,
                    marginTop: 30,
                    width: 225,
                  }}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Tipo Usuario
                  </InputLabel>
                  <Select
                    native
                    value={tipo}
                    onChange={this.changeHandler}
                    label="Tipo"
                    inputProps={{
                      name: "tipo",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option aria-label="None" value="" />
                    <option value={1}>Administrador</option>
                    <option value={2}>Motorista</option>
                    <option value={3}>Cliente Apenas visao web</option>
                    <option value={4}>Conferente STR </option>
                    <option value={5}>ADM STR(CTE)</option>
                    <option value={6}>Cliente Acesso web e APP</option>
                  </Select>
                </FormControl>

                <TextField
                  style={{ marginTop: 30, width: 225 }}
                  id="standard-basic"
                  size="small"
                  type="password"
                  variant="filled"
                  label="Password"
                  name="password"
                  value={password}
                  onChange={this.changeHandler}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 55,
                  //backgroundColor: 'red',
                }}
              >
                <Button
                  style={{
                    marginTop: 50,
                    width: 260,
                    marginBottom: 10,
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                >
                  Salvar
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    );
  }
}

export default usuario;
