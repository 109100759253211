import React, { useState, useEffect } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { putstatusloja, getStatuslojaid } from "../service/CRUD_Service";
import Barra from "./barra_motoristaEdit";
import Paper from "@material-ui/core/Paper";

import Typography from "@material-ui/core/Typography";

import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

export default function StatusLojaEdit({ history }) {
  //class updateveiculo extends Component{
  const { id } = history.location.state;

  const [motErro, setMotErro] = useState("");

  const [dados, setDados] = useState({});
  const [Placa, setPlaca] = useState();
  const [idplaca, setIdplaca] = useState();
  const [identrega, setidentrega] = useState();
  const [nfrota, setNfrota] = useState();
  const [status, setStatus] = useState("");
  const [descricao, setDescricao] = useState("");

  const [dataentrada, setDataentrada] = useState("");
  const [datasaida, setDatasaida] = useState();

  // Função para formatar a data corretamente para o datetime-local
  const formatDateTimeLocal = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Retornando no formato "YYYY-MM-DDTHH:MM"
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Função que ajusta a data e hora para o fuso horário correto
  const formatData = (datasaida) => {
    // Ajusta o fuso horário de UTC para UTC -3 (Horário de Brasília)

    return moment(datasaida).utc().format("YYYY-MM-DDTHH:mm");
  };

  // Função para formatar a data corretamente para o datetime-local
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Retornando no formato "YYYY-MM-DDTHH:MM"
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    getStatuslojaid(id)
      .then((res) => {
        setDados(res);
        setPlaca(res.fkplacastatus.placa);
        setidentrega(res.identrega);
        setNfrota(res.fkplacastatus.nfrota);
        setStatus(res.isstatus);
        setDescricao(res.descricaostatus);
        setDataentrada(formatData(res.dtchegadaloja));
        setDatasaida(formatData(res.dtsaidaloja));
      })
      .catch((err) => setMotErro(err));
  }, [id]);

  const onChangeentrega = (event) => {
    setidentrega(event.target.value);
  };

  //codigo
  const onChangenIdplaca = (event) => {
    setIdplaca(event.target.value);
  };
  const onChangenplaca = (event) => {
    setPlaca(event.target.value);
  };
  // nome
  const onChangenfrota = (event) => {
    setNfrota(event.target.value);
  };
  // cpf
  const onChangestatus = (event) => {
    setStatus(event.target.value);
  };
  //turno
  const onChangedescricao = (event) => {
    setDescricao(event.target.value);
  };

  //tipo
  const onChangedataentrada = (event) => {
    setDataentrada(event.target.value);
  };

  const onChangedatasaida = (event) => {
    setDatasaida(event.target.value); // Armazena no estado o valor ajustado para UTC
  };

  const toEdit = (event) => {
    event.preventDefault();
    let datasaidas = new Date(datasaida); // Exemplo de data
    let dataentradas = new Date(dataentrada); // Exemplo de data

    dados.idplaca = idplaca;
    dados.isstatus = status;
    dados.identrega = identrega;
    dados.descricaostatus = descricao;
    dados.descricaostatus = descricao;
    dados.dtchegadaloja = dataentradas;
    dados.dtsaidaloja = datasaidas;

    putstatusloja(id, dados)
      .then(() => history.push("/Listastatusloja"))
      .catch((err) => setMotErro(err));
  };

  return (
    <div className="Users">
      <Paper
        square
        elevation={4}
        style={{
          backgroundColor: "#FFFF",
          marginLeft: 100,
          marginRight: 100,
          marginTop: 50,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#abb8c3",
          borderRadius: 10,
          minWidth: 410,
        }}
      >
        <form>
          <div
            style={{
              display: "flex",
              backgroundColor: "#191E4D",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              height: 50,
            }}
          >
            <Typography
              style={{
                fontSize: 20,
                color: "#cccccc",
              }}
            >
              Editar Status veiculos em Lojas
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: 50,
              paddingRight: 50,
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
                backgroundColor: "#e7e7e7",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingLeft: 5,
                marginTop: 25,
                maxWidth: 110,
                minWidth: 150,
              }}
            >
              <label
                for="example-input"
                style={{ marginbottom: 5, color: "#808080" }}
              >
                Placa:
              </label>
              <input
                type="text"
                style={{
                  color: "#808080",
                  border: "none",
                }}
                value={Placa}
                disabled
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
                backgroundColor: "#e7e7e7",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                paddingLeft: 5,
                marginTop: 25,
                maxWidth: 110,
                minWidth: 150,
                height: 50,
              }}
            >
              <label
                for="example-input"
                style={{ marginbottom: 5, color: "#808080" }}
              >
                Numero frota:
              </label>
              <input
                type="text"
                style={{
                  color: "#808080",
                  border: "none",
                }}
                value={nfrota}
                disabled
              />
            </div>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Status"
                name="status"
                value={status}
                onChange={onChangestatus}
              >
                <MenuItem value="">
                  <em>Status...</em>
                </MenuItem>

                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              variant="filled"
              style={{
                marginTop: 25,
                minWidth: 350,
                flex: 1,
                marginRight: 50,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Descricao
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                margin="dense"
                label="Descricao"
                name="descricao"
                value={descricao}
                onChange={onChangedescricao}
              >
                <MenuItem value="">
                  <em>Descricao.....</em>
                </MenuItem>

                <MenuItem value={"Entrada Loja"}>Entrada Loja</MenuItem>
                <MenuItem value={"Saida Loja"}>Saida Loja</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: 2,
              justifyContent: "space-between",
              marginTop: 20,
              marginRight: 100,
              marginLeft: 70,
            }}
          >
            <TextField
              style={{
                marginTop: 25,
                flex: 1,
                maxWidth: 110,
                minWidth: 350,

                backgroundColor: "#e7e7e7",
              }}
              id="filled-helperText"
              label="Id Entrega"
              multiline
              margin="dense"
              type="text"
              name="identrega"
              value={identrega} // Usar 'value' em vez de 'defaultValue'
              onChange={onChangeentrega}
              InputLabelProps={{
                shrink: true, // Faz o label ficar sempre em cima
              }}
            />

            <TextField
              id="datetime-local"
              label="Data e Hora de Entrada"
              variant="filled"
              margin="dense"
              type="datetime-local"
              value={dataentrada}
              onChange={onChangedataentrada}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginTop: 25,
                minWidth: 350,
              }}
            />

            <TextField
              id="datetime-local"
              label="Data e Hora de Saida"
              variant="filled"
              margin="dense"
              type="datetime-local"
              value={datasaida}
              onChange={onChangedatasaida}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginTop: 25,
                minWidth: 350,
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                marginTop: 5,
                marginBottom: 30,
                marginTop: 50,
              }}
              variant="contained"
              color="primary"
              onClick={toEdit}
            >
              Salvar
            </Button>
          </div>
        </form>
      </Paper>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}
