import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Popover from "@mui/material/Popover";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import { GiGrapes, GiCube, GiSlicedBread } from "react-icons/gi";

import FilterListIcon from "@material-ui/icons/FilterList";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

//filtro
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

//COMPONENTES INTERNOS

import CardTotalCargas from "../componentes/CardTotalCargas";
import CadCargasEntregues from "../componentes/CadCargasEntregues";
import CadCargasPendente from "../componentes/CadCargasPendente";
import CadCargasHortiPendente from "../componentes/CadCargasHortiPendente";
import CardCargasFriosPendente from "../componentes/CardCargasFriosPendente";

import CadCargaNormalPendente from "../componentes/CadCargaNormalPendente";

import CadCargapanificacaoSeca from "../componentes/CardCargaspanificacaoSeca";
import CadCargapanificacaoFrios from "../componentes/CardCargaspanificacaoFrios";

//grafico
import Grafico1 from "../componentes/Grafico1";
import Grafico2 from "../componentes/Grafico2";
import Grafico3 from "../componentes/Grafico3";

import { logout, getOperacao } from "../service/authService";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#ffff",
  ...theme.typography.body2,
  // padding: 2,
  textAlign: "center",
  boxShadow: "15px",
  color: theme.palette.text.secondary,
  height: 98,
}));

const useStyles = makeStyles((theme) => ({
  //Em uso

  box1: {
    backgroundColor: "#E6E6E6",
    flexGrow: 1,
    maxWidth: "1900px",
    // margin: 'auto',
    minWidth: "1200px",
    marginLeft: 10,
  },
  //Em uso
  dividir: {
    border: 10,
    backgroundColor: "#ffff",
  },
  //Em uso
  papergrafico: {
    padding: 2,
    //paddingRight: 20,
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    border: " 2px solid  #BFBFBF",
    borderRadius: 11,
    boxShadow: "1px 10px 10px #595959",
    backgroundColor: "#eeeef0",
    height: 280,

    justify: "center",
  },
  //Em uso
  papergrafico2: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: 2,
    marginBottom: -8,
    marginLeft: 2,
    marginRight: 5,
    justify: "center",
    height: 220,
    border: " 2px solid  #BFBFBF",
    borderRadius: 11,
    boxShadow: "1px 10px 10px #595959",
    backgroundColor: "#eeeef0",
  },

  paperbarrafiltro: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: 2,
    marginBottom: -8,
    marginLeft: 2,
    marginRight: 5,
    justify: "center",
    height: 50,
    border: " 2px solid  #BFBFBF",
    borderRadius: 11,
    boxShadow: "1px 10px 10px #595959",
    backgroundColor: "#eeeef0",
  },
  //Em uso
  papergraficoperfil: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: 2,
    marginBottom: -12,
    marginLeft: 2,
    justify: "center",
    height: 220,
    // width: 400,
    borderRadius: 11,
    border: " 2px solid  #BFBFBF",
    boxShadow: "1px 10px 10px  #595959",
    backgroundColor: "#eeeef0",
  },
  //Em uso
  grid: {
    padding: 5,
    marginRight: 2,
  },
  //Em uso
  img: {
    height: 150,
    width: 200,
    marginLeft: 4,
    marginTop: -27,
    //marginBottom: -25,
    // alignContent: 'center',
    //justifyContent: 'center',
  },
  gridcard: {
    padding: 6,

    borderRadius: 11,
  },
}));

export default function GridDash() {
  const [cadOperacoes, setCadOperacoes] = useState([]);
  const [operacaoSelecionada, setOperacaoSelecionada] = useState("");

  useEffect(() => {
    const operacoes = getOperacao();
    if (operacoes) {
      setCadOperacoes(operacoes);

      // Encontra a operação com o nome "Koch - CD"
      const kochCdOperacao = operacoes.find((op) => op.id === 3);
      if (kochCdOperacao) {
        // Define o ID da operação selecionada como o ID da operação "Koch - CD"
        setOperacaoSelecionada(kochCdOperacao.id);
      } else {
        const operacaoPadrao = operacoes[0];
        setOperacaoSelecionada(operacaoPadrao.id);
      }
    }
  }, []);

  const handleOperacaoChange = (event) => {
    setOperacaoSelecionada(event.target.value);
  };
  const classes = useStyles();
  //FORMATAR DATA
  const formatDate = () => {
    const dates = new Date();
    let [month, date, year] = new Date(dates)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatade = month + "/" + date + "/" + year;

    return formatade;
  };

  const [date, setDate] = useState(formatDate());
  const datafiltra = () => {
    setDate(formatDate());
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Recupera os dados do localStorage
  //const cadOperacoes = getOperacao();

  var ConverteOperacaotipo = Number(operacaoSelecionada);

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.box1}>
      <Grid
        justifyContent="space-between"
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 7, md: 7 }}
        style={{
          backgroundColor: "#E6E6E6",
        }}
      >
        <Grid item xs className={classes.gridcard}>
          <Item
            style={{
              height: 98,
              background: "#191E4D",
              justifyItems: "center",
              justifyContent: "center",
              alignItems: "center",

              border: " 2px solid  #F4F6F9",
              borderRadius: 11,
              boxShadow: "1px 10px 10px  #595959",
            }}
          >
            <img src={require("../imagem/Logo.png")} className={classes.img} />
            {/*
            <Form.Group
              controlId='duedate'
              style={{
                width: 120,
                height: 30,
                marginTop: -12,
                fontSize: 9,
                backgroundColor: '#2D3586',
                color: '#fff',
              }}
            >
              <Form.Control
                style={{
                  fontSize: 9,
                  backgroundColor: '#191E4D',
                  color: '#fff',
                  borderColor: '#2D3586',
                }}
                type='date'
                name='duedate'
                placeholder='Due date'
                // value={date}
                //   onChange={(e) => setDate(e.target.value)}
              />
            </Form.Group>
             */}
          </Item>
        </Grid>
        {/*
           OPERACAO 3 É REFERENTE A OPERACAO KOCH -CD EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 3 && (
          <>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardTotalCargas
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasEntregues
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasPendente
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardCargasFriosPendente operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasHortiPendente operacoes={operacaoSelecionada} />
              </Item>
            </Grid>

            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargaNormalPendente operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
          </>
        )}
        {ConverteOperacaotipo === 1 && (
          <>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardTotalCargas
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasEntregues
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasPendente
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargapanificacaoSeca operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargapanificacaoFrios operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
          </>
        )}

        {ConverteOperacaotipo === 2 && (
          <>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardTotalCargas
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasEntregues
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasPendente
                  datafiltra={date}
                  operacoes={operacaoSelecionada}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardCargasFriosPendente operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargasHortiPendente operacoes={operacaoSelecionada} />
              </Item>
            </Grid>

            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargaNormalPendente operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargapanificacaoSeca operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CadCargapanificacaoFrios operacoes={operacaoSelecionada} />
              </Item>
            </Grid>
          </>
        )}
      </Grid>

      <Box sx={{ flexGrow: 1, marginTop: 1, backgroundColor: "#E6E6E6" }}>
        <Stack direction="row" spacing={2}>
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            onClick={handleClick}
            startIcon={<FilterListIcon />}
          >
            Filtro
          </Button>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: 300, // largura do Popover
              maxHeight: 700, // altura máxima do Popover
              paddingLeft: 3,
            },
          }}
        >
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Operacoes</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={operacaoSelecionada}
              onChange={handleOperacaoChange}
              name="radio-buttons-group"
            >
              {cadOperacoes.map((op, index) => (
                <FormControlLabel
                  key={index}
                  value={op.id}
                  control={<Radio />}
                  label={op.tpoperacao}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Popover>
      </Box>
      <Box sx={{ flexGrow: 1, marginTop: 3, backgroundColor: "#E7E3FD" }}>
        <Grid
          sx={{ height: "100%" }}
          container
          spacing={{ xs: 1, sm: 2, lg: 2 }}
          columns={{ xs: 8, sm: 8, md: 8, lg: 8 }}
          style={{
            backgroundColor: "#E6E6E6",
          }}
        >
          <Grid item xs={8}>
            <Paper elevation={16} className={classes.papergrafico2}>
              <Typography style={{ color: "#697689", fontSize: 18 }}>
                Totais de entregas Pendentes
              </Typography>
              <Grafico2 datafiltra={date} operacoes={operacaoSelecionada} />
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.papergraficoperfil}>
              <Typography style={{ color: "#697689", fontSize: 18 }}>
                Totais de entregas dia Perfil
              </Typography>
              <Grafico3 datafiltra={date} operacoes={operacaoSelecionada} />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.papergrafico}>
              <Typography style={{ color: "#697689", fontSize: 18 }}>
                Totais de lojas entregues dia
              </Typography>
              <Grafico1 datafiltra={date} operacoes={operacaoSelecionada} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
