import React from 'react';
import clsx from 'clsx';

//APIS MATERIAL UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
//COMPONENTES INTERNOS
import MainListItems from '../componentes/listItem';

import Grid from '../componentes/form_rotas';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //backgroundColor: 'ghostwhite ',
    //flex-wrap: wrap-reverse;
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  drawer: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      display: 'none',
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  //paletta canto esquerdo
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 20px',
    ...theme.mixins.toolbar,
    // backgroundColor: '#002447',
    background: '#082655',
  },
  small: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    marginTop: 40,
    marginBottom: 40,
    right: -150,
    borderBlockColor: 'red',
  },
  fechar: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginTop: 90,
    marginBottom: 10,
    right: 15,
  },
  abrir: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginTop: 90,
    marginBottom: 10,
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />

        {/*  abre menu lateral  */}
        <Drawer
          onMouseMove={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbarIcon}>
            <img
              src={require('../imagem/Logo.png')}
              className={classes.small}
            />
          </div>
          <Divider />
          <List
            style={{
              background: '#082655',
              height: '100vh',
              justifycontent: 'space-between',
            }}
          >
            <MainListItems />
          </List>
          <Divider />
          {/*} <List style={{ background: '#002447' }}>{secondaryListItems}</List>*/}
        </Drawer>
        <Grid />
      </div>
    </>
  );
}
