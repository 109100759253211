import React from 'react';
//import Routes from "./router";
import Routes from './rotaprincipal';

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}
export default App;
