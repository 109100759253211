//FALTA FAZER//
//1 COLOCAR UPERCASE NO CAMPO PLACA(CAIXA ALTA)
//

import React, { Component } from 'react';
import api from '../api';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import Style_login from '../style/style_login.css';

class Destino extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codigo: '',
      razao: '',
      cnpj: '',
      lojas: '',
      rua: '',
      numero: '',
      cep: '',
      municipio: '',
      bairro: '',
      uf: '',
      longitude: '',
      latitude: '',
      recebimentohrinicial: '',
      recebimentohrfinal: '',
      tipoveiculo: '',
      comprimentoveiculo: '',
      cerca: '',
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post('/destino', this.state)
      .then((response) => {
        console.log(response);
        alert('DESTINO SALVO COM SUCESSO!!! ');
        window.location = '/ListarDestino';
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      codigo,
      razao,
      cnpj,
      lojas,
      rua,
      numero,
      cep,
      municipio,
      bairro,
      uf,
      longitude,
      latitude,
      recebimentohrinicial,
      recebimentohrfinal,
      tipoveiculo,
      comprimentoveiculo,
      cerca,
    } = this.state;

    return (
      <body className={Style_login.body}>
        <div>
          <div>
            <br></br>
            <br></br>
            <Paper
              variant='outlined'
              elevation={4}
              style={{
                backgroundColor: '#ffff',
                width: 890,
                height: 550,
                marginTop: -30,
                marginLeft: 230,
              }}
            >
              <form
                style={{
                  backgroundColor: '#ffff',
                  width: 840,
                  marginLeft: 10,
                  marginTop: 30,
                }}
                onSubmit={this.submitHandler}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={1}>
                    <TextField
                      style={{
                        width: 80,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 80,
                        marginTop: 20,
                      }}
                      required
                      id='standard-required'
                      label='Codigo'
                      placeholder='00'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='codigo'
                      value={codigo}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <TextField
                      style={{
                        width: 340,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 130,
                        marginTop: 20,
                      }}
                      required
                      id='standard-required'
                      label='Razao Social'
                      placeholder='******'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='razao'
                      value={razao}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 370,
                        marginTop: 20,
                      }}
                      required
                      id='standard-required'
                      label='CNPJ'
                      placeholder='00.000.000/0001-00'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='cnpj'
                      value={cnpj}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>

                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 350,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 80,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='Rua'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='rua'
                      value={rua}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 70,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 255,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='Nº'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='numero'
                      value={numero}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 155,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='Bairro'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='bairro'
                      value={bairro}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 80,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='CEP'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='cep'
                      value={cep}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 260,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 65,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='Municipio'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='municipio'
                      value={municipio}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 155,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='UF'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='uf'
                      value={uf}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 80,
                        marginTop: 5,
                      }}
                      required
                      id='standard-required'
                      label='Loja'
                      placeholder='Nome-00'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='lojas'
                      value={lojas}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 260,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 65,
                        marginTop: 5,
                      }}
                      id='standard-required'
                      label='Longitude'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='longitude'
                      value={longitude}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: '#f5f5dc',
                        marginLeft: 155,
                        marginTop: 5,
                      }}
                      id='standard-required'
                      label='Latitude'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='latitude'
                      value={latitude}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>
                <br></br>
                <Grid item xs={6} sm={3}>
                  <TextField
                    style={{
                      width: 200,
                      backgroundColor: '#f5f5dc',
                      marginLeft: 78,
                      marginTop: 5,
                    }}
                    id='standard-required'
                    label='Cerca'
                    placeholder='xxx'
                    multiline
                    variant='outlined'
                    margin='dense'
                    type='number'
                    name='cerca'
                    value={cerca}
                    onChange={this.changeHandler}
                  />
                </Grid>
                <br></br>
                <br></br>
                <InputLabel
                  style={{ marginLeft: 75, fontSize: 18 }}
                  id='demo-simple-select-helper-label'
                >
                  Restrições
                </InputLabel>
                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <InputLabel
                      style={{
                        marginLeft: 75,
                        fontSize: 15,
                        marginTop: -5,
                      }}
                      id='demo-simple-select-helper-label'
                    >
                      Recebimento :
                    </InputLabel>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 120,
                        backgroundColor: '#f5f5dc',
                        marginLeft: -10,
                        marginTop: -10,
                      }}
                      required
                      id='standard-required'
                      label='Hora Inicial'
                      placeholder='00:00'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='text'
                      name='recebimentohrinicial'
                      value={recebimentohrinicial}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{
                        width: 120,
                        backgroundColor: '#f5f5dc',
                        marginLeft: -70,
                        marginTop: -10,
                      }}
                      required
                      id='standard-required'
                      label='Hora Final'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='recebimentohrfinal'
                      value={recebimentohrfinal}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>
                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <InputLabel
                      style={{
                        marginLeft: 75,
                        fontSize: 15,
                        marginTop: -5,
                      }}
                      id='demo-simple-select-helper-label'
                    >
                      Tipo Veiculo :
                    </InputLabel>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <FormControl
                      variant='outlined'
                      style={{
                        width: 120,
                        backgroundColor: '#f5f5dc',
                        marginLeft: -10,
                        marginTop: -10,
                      }}
                    >
                      <InputLabel id='demo-simple-select-outlined-label'>
                        Select...
                      </InputLabel>

                      <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        margin='dense'
                        label='Tipo Veiculo'
                        name='tipoveiculo'
                        value={tipoveiculo}
                        onChange={this.changeHandler}
                      >
                        <MenuItem value=''>
                          <em>Tipo Veiculo</em>
                        </MenuItem>

                        <MenuItem className='Truck' value={'CT'}>
                          CT
                        </MenuItem>
                        <MenuItem
                          className='semi-reboque-termico'
                          value={'SRT'}
                        >
                          SRT
                        </MenuItem>
                        <MenuItem
                          className='semi-reboque'
                          value={'SR'}
                        >
                          SR
                        </MenuItem>
                        <MenuItem
                          className='semi-reboque-frigorifica'
                          value={'SRF'}
                        >
                          SRF
                        </MenuItem>
                        <MenuItem
                          className='Caminhao-trator-cavalo'
                          value={'CTC'}
                        >
                          CTC
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      style={{
                        width: 120,
                        backgroundColor: '#f5f5dc',
                        marginLeft: -70,
                        marginTop: -10,
                      }}
                      required
                      id='standard-required'
                      // id="outlined-textarea"
                      label='Comprimento'
                      placeholder='xxx'
                      multiline
                      variant='outlined'
                      margin='dense'
                      type='number'
                      name='comprimentoveiculo'
                      value={comprimentoveiculo}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>
                <br></br>
                <br></br>

                <Button
                  style={{
                    marginLeft: 370,
                    height: 30,
                    marginTop: -10,
                  }}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Salvar
                </Button>
              </form>
            </Paper>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </body>
    );
  }
}

export default Destino;
