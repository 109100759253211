import React, { useEffect, useState } from "react";

import {
  BarChart,
  Bar,
  LabelList,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import api from "../api";
import { getGrafico3 } from "../service/CRUD_Service";

export default function Grafico2({ datafiltra, operacoes }) {
  const [totalCarga, setTotalCarga] = useState(1);

  useEffect(() => {
    const dates = new Date();
    let [month, date, year] = new Date(dates)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatade = year + "-" + date + "-" + month;

    setDtatual(formatade);
  });
  const [dtatual, setDtatual] = useState();

  function formatarFiltroManual() {
    const dates = datafiltra;
    let [month, date, year] = new Date(dates)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatade = year + "-" + date + "-" + month;

    return formatade;
  }

  //incluir veiculo
  useEffect(() => {
    api
      .get("/Graficotodasentregasperfil")
      .then((res) => {
        let carg = res.data[0];

        // Remover ou definir valores 0 como vazio
        carg.forEach((item) => {
          item.frios = item.frios === "0" ? "" : item.frios;
          item.horti = item.horti === "0" ? "" : item.horti;
          item.normal = item.normal === "0" ? "" : item.normal;
          item.panificacaofrios =
            item.panificacaofrios === "0" ? "" : item.panificacaofrios;
          item.panificacaoseca =
            item.panificacaoseca === "0" ? "" : item.panificacaoseca;
        });

        setTotalCarga(carg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  /* 
  Filtra
*/
  function filtramanual() {
    if (totalCarga !== 1) {
      let busca = totalCarga.filter((item) => {
        let e = formatarFiltroManual();
        return item.dataentregapadrao.toString().includes(e.toString());
      });
      return busca;
    } else {
      console.log("teste");
    }
  }
  function filtraautomatico() {
    if (totalCarga !== 1) {
      let busca = totalCarga.filter((item) => {
        let e = dtatual;
        return item.dataentregapadrao.toString().includes(e.toString());
      });
      return busca;
    } else {
      console.log("teste");
    }
  }
  const manual = filtramanual();
  const automatico = filtraautomatico();

  const dataatual = dtatual;
  const Datafiltra = dtatual;
  const dados = Datafiltra == dataatual ? automatico : manual;

  useEffect(() => {
    let id = setInterval(() => {
      Buscaautomatico().then();
    }, 60000);
    return () => clearInterval(id);
  });

  async function Buscaautomatico() {
    let busca = await getGrafico3();
    let dados1 = busca[0];
    let n = null;
    let comparar = dados1?.filter((x) => x.dataentregapadrao !== n);
    return setTotalCarga(comparar);
  }

  function filtraManualOperacaoes() {
    if (totalCarga !== 1) {
      let d = dados; // recebe os dados apos filtro de data
      let Op = Number(operacoes); // garante que o tipo de dado vai ser sempre numero
      let filtro = d?.filter((x) => x.id === Op); // manipula a operacao de acordo com filtro

      return filtro;
    } else {
      console.log("teste");
    }
  }

  let DadosFiltroOperacao = filtraManualOperacaoes();
  setInterval(DadosFiltroOperacao, 60000);

  var ConverteOperacaotipo = Number(operacoes);

  return (
    <>
      <ResponsiveContainer width="100%" height={180}>
        <BarChart width={90} height={30} data={DadosFiltroOperacao}>
          <Tooltip />
          <Legend />
          {/*
          Tipo      prod        local
          Koch cd   3             5
          */}
          {ConverteOperacaotipo === 3 ? (
            <React.Fragment>
              <Bar dataKey="normal" fill="#ffc658" barSize={70}>
                <LabelList
                  dataKey="normal"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="frios" fill="#8884d8" barSize={70}>
                <LabelList
                  dataKey="frios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="horti" fill="#82ca9d" barSize={70}>
                <LabelList
                  dataKey="horti"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
            </React.Fragment>
          ) : ConverteOperacaotipo === 1 ? (
            <React.Fragment>
              {/*
          Tipo          prod        local
          Panificacao   1             2
          */}
              <Bar dataKey="panificacaofrios" fill="#b3d2e5" barSize={70}>
                <LabelList
                  dataKey="panificacaofrios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="panificacaoseca" fill="#dbb429" barSize={70}>
                <LabelList
                  dataKey="panificacaoseca"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
            </React.Fragment>
          ) : ConverteOperacaotipo === 2 ? (
            <React.Fragment>
              {/*
          Tipo      prod        local
          Passarela   2            3
          */}
              <Bar dataKey="normal" fill="#ffc658" barSize={70}>
                <LabelList
                  dataKey="normal"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="frios" fill="#8884d8" barSize={70}>
                <LabelList
                  dataKey="frios"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="horti" fill="#82ca9d" barSize={70}>
                <LabelList
                  dataKey="horti"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
              <Bar dataKey="panificacaofrios" fill="#b3d2e5" barSize={70}>
                <LabelList
                  dataKey="panificacaofrios"
                  position="inside"
                  fontSize={20}
                  fill="#b3d2e5"
                />
              </Bar>
              <Bar dataKey="panificacaoseca" fill="#dbb429" barSize={70}>
                <LabelList
                  dataKey="panificacaoseca"
                  position="inside"
                  fontSize={20}
                  fill="#F5F5F5"
                />
              </Bar>
            </React.Fragment>
          ) : null}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
