import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Style_login from "../style/style_login.css";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  login,
  setNomeUsuario,
  setIdUsuario,
  setTipoUsuario,
  setOperacao,
} from "../service/authService";
import { withRouter } from "react-router-dom";

import api from "../api";

//se nao usar mais da um unistall abaixo
//import * as yup from "yup";

class SignIn extends Component {
  //export default function Login() {
  state = {
    cpf: "",
    password: "",
    error: "",
    tipo: "",
  };

  handleSignIn = async (e) => {
    e.preventDefault();
    const { cpf, password } = this.state;
    if (!cpf || !password) {
      this.setState({
        error: "Preencha CPF e senha para continuar!",
      });
    } else {
      try {
        const response = await api.post("/sessions", {
          cpf,
          password,
        });
        login(response.data.token);
        setIdUsuario(response.data.user.id);
        setNomeUsuario(response.data.user.name);
        setTipoUsuario(response.data.user.tipo);
        setOperacao(response.data.user.cad_operacoes);

        this.props.history.push("/Home");
      } catch (err) {
        this.setState({
          error: "ERRO: Verifique seu Login e senha",
        });
      }
    }
  };
  render() {
    return (
      <Container className={Style_login.body}>
        <div id="login">
          <Paper
            variant="outlined"
            elevation={5}
            style={{
              width: 400,
              height: 450,
              //  marginLeft: 350,
              marginTop: 100,
              borderRadius: "20px",
            }}
          >
            <form
              onSubmit={this.handleSignIn}
              style={{
                width: 300,
                height: 350,
                marginLeft: 50,
              }}
            >
              <img src={require("../imagem/Logoazul.jpg")} />
              <Typography
                style={{
                  marginTop: 40,
                  marginLeft: 120,
                  fontSize: 25,
                  color: "darkgrey",
                  textshadow: "1px 1px 1px rgba(0,0,0,0.004)",
                }}
              >
                Acesse
              </Typography>

              <div className="form-group">
                <Typography
                  style={{ fontSize: 12 }}
                  color="secondary"
                  id="discrete-slider-restrict"
                  gutterBottom
                ></Typography>
                {this.state.error && <p>{this.state.error}</p>}
                <TextField
                  style={{
                    marginTop: 10,
                    width: 350,
                    marginLeft: -25,
                  }}
                  id="cpf"
                  margin="dense"
                  label="CPF"
                  variant="outlined"
                  placeholder="00.000.000-00"
                  type="cpf"
                  onChange={(e) => this.setState({ cpf: e.target.value })}
                />
              </div>

              <div className="form-group">
                <Typography
                  style={{ fontSize: 12 }}
                  color="secondary"
                  id="discrete-slider-restrict"
                  gutterBottom
                ></Typography>
                <TextField
                  style={{
                    marginTop: -2,
                    width: 350,
                    marginLeft: -25,
                    paddingTop: 10,
                    paddingBottom: 20,
                  }}
                  id="outlined-password-input"
                  margin="dense"
                  label="Senha"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <Button
                style={{
                  width: 350,
                  height: 40,
                  marginLeft: -25,
                }}
                //href="/Dashaboard"
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
              >
                Login
              </Button>
            </form>
          </Paper>
        </div>
      </Container>
    );
  }
}
export default withRouter(SignIn);
