//

import React, { Component, useState, useEffect } from "react";
import api from "../api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { getoperacoesid, putoperacoes } from "../service/CRUD_Service";
import Barra from "../componentes/barra_veiculoEdit";
import Paper from "@material-ui/core/Paper";

export default function OperacoesEdit({ history }) {
  //class updateveiculo extends Component{
  const { id } = history.location.state;
  const [vel, setVel] = useState({});
  const [velErro, setVelErro] = useState("");
  const [tpoperacao, setTpoperacao] = useState("");
  

  useEffect(() => {
    getoperacoesid(id)
      .then((res) => {
        setVel(res);
        setTpoperacao(res.tpoperacao);
       
      })
      .catch((err) => setVelErro(err));
  }, [id]);

  //numero da frota 1
  const onChangetpoperacao = (event) => {
    setTpoperacao(event.target.value);
  };
  

  const toEdit = (event) => {
    event.preventDefault();
    vel.tpoperacao = tpoperacao;
     

    putoperacoes(id, vel)
      .then(() => history.push("/ListOperacoes"))
      .catch((err) => setVelErro(err));
  };

  return (
    <div>
      <Paper
        style={{
          backgroundColor: "#ffff",
          marginTop: 10,
          marginBlockEnd: 17,
          marginLeft: 25,
          marginRight: 10,
        }}>
        <Barra />
      </Paper>
      <div>
        <Paper
          variant="outlined"
          elevation={4}
          style={{
            backgroundColor: "#ffff",
            width: 890,
            marginTop: 120,
            marginBlockEnd: 179,
            marginLeft: 240,
            height: 450,
          }}>
          <form
            style={{
              backgroundColor: "#ffff",
              width: 720,
              marginLeft: 110,
              height: 350,
            }}>
            <br></br>
            <InputLabel
              style={{
                marginTop: 3,
                marginBlockEnd: 15,
                marginLeft: 260,
                fontSize: 25,
              }}
              id="demo-simple-select-helper-label">
              Editar Operacoes
            </InputLabel>
            <br></br>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <TextField
                  style={{
                    width: 170,
                    backgroundColor: "#f5f5dc",
                    marginLeft: 30,
                  }}
                  id="outlined-textarea"
                  label="Operacoes"
                  placeholder="00"
                  multiline
                  variant="outlined"
                  margin="dense"
                  type="text"
                  name="tpoperacao"
                  value={tpoperacao}
                  onChange={onChangetpoperacao}
                />
              </Grid>

          
             
            </Grid>

         

            <br></br>

        

            <br></br>
            <br></br>

            <Button
              style={{
                width: 170,
                marginLeft: 240,
                marginTop: 20,
              }}
              variant="contained"
              color="primary"
              onClick={toEdit}>
              Salvar
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}
