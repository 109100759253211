import React from "react";

export default function PainelProcessamento() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh", // Faz a div ocupar toda a altura da tela
        display: "flex",
        // justifyContent: "center",
        //alignItems: "center",
        marginTop: 10,
      }}
    >
      <iframe
        title="Power BI Report"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiMzQ2OGUxZWItOGZlMC00ZTUzLTg0ZmUtNjg3ODVlZWM1MjU4IiwidCI6IjEwZjI2MTM2LWJjNGMtNGNiZC05NWVjLWQ0MWQ4OTdjMWViOSJ9"
        frameBorder="0"
        allowFullScreen="true"
      />
    </div>
  );
}
