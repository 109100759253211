import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { icon } from "leaflet";
import "../App.css";

import { FaMapMarkerAlt } from "react-icons/fa";

import apistr from "../api"; //api str

import { ApiTracerVeiculos } from "../service/authtracer";

import axios from "axios";

export default function Mapa() {
  const [veiculos, SetVeiculos] = useState();
  const [entregas, SetEntregas] = useState();
  const [destinos, SetDestinos] = useState();
  const [tracer, setTracer] = useState();

  const classes = useRowStyles();

  //busca destinos api
  useEffect(() => {
    apistr
      .get("/filterviagemconferente")
      .then((res) => {
        let dados = res.data;
        SetEntregas(dados);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  //busca destinos api
  useEffect(() => {
    apistr
      .get("/destino")
      .then((res) => {
        let dados = res.data;
        let nulo = null;
        let zero = 0;
        let um = 1;

        let filt = dados.filter(
          (item) =>
            (item.longitude !== nulo) & (item.longitude !== zero) &&
            item.longitude !== um
        );
        SetDestinos(filt);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  //busca veiculos API
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        let dados = carg.map((item) => [
          item.name,
          item.lat,
          item.lng,
          item.temperature,
          item.speed,
        ]);

        SetVeiculos(dados);
        setTracer(dados);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  const myIconveiculo = L.icon({
    iconUrl: require("../imagem/cargo-truck.png"),
    iconSize: [20, 30],
    iconAnchor: [22, 30],
    popupAnchor: [-3, -26],
    shadowSize: [40, 20],
  });
  const myIconlojas = L.icon({
    iconUrl: require("../imagem/pin.png"),
    iconSize: [25, 25],
    iconAnchor: [23, 30],
    popupAnchor: [-10, -42],
    shadowSize: [70, 60],
  });

  function MultipleMarkersVeiculos() {
    return veiculos?.map((coordinata, index) => {
      let t = coordinata[0];
      let tt = entregas.filter((arr) => t?.includes(arr.fkveiculo.placa));
      return (
        <Marker
          key={index}
          position={[coordinata[1], coordinata[2]]}
          icon={myIconveiculo}
        >
          <Popup>
            <TableContainer className={classes.root}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Motorista</TableCell>
                    <TableCell align="right">Placa T</TableCell>
                    <TableCell align="right">Placa SR</TableCell>
                    <TableCell align="right">Lojas</TableCell>
                    <TableCell align="right">Perfil</TableCell>
                    <TableCell align="right">Status Entrega</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tt.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {(() => {
                          let data = row.fkmotorista.nome;
                          let sem = "Sem";
                          if (data == null) {
                            return sem;
                          } else {
                            return data;
                          }
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        {(() => {
                          let data = row.fkveiculotrator.placa;
                          let sem = "Sem";
                          if (data == null) {
                            return sem;
                          } else {
                            return data;
                          }
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        {(() => {
                          let data = row.fkveiculo.placa;
                          let sem = "Sem";
                          if (data == null) {
                            return sem;
                          } else {
                            return data;
                          }
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        {(() => {
                          let m = row.cviagens;
                          let a = m.map((x) => x.fkdestino.lojas);
                          let data = a;
                          let sem = "Sem";
                          if (data == null) {
                            return sem;
                          } else {
                            return data;
                          }
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        {(() => {
                          let m = row.cviagens;
                          let a = m.map((x) => x.perfil);
                          let data = a;
                          let sem = "Sem";
                          if (data == null) {
                            return sem;
                          } else {
                            return data;
                          }
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        {(() => {
                          let m = row.cviagens;
                          let a = m.map((x) => x.statusentrega);
                          let data = a;
                          let sem = "Sem";
                          if (data == null) {
                            return sem;
                          } else {
                            return data;
                          }
                        })()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Popup>
        </Marker>
      );
    });
  }

  function MultipleMarkersDestinos() {
    return destinos?.map((coordinata, index) => {
      return (
        <Marker
          key={index}
          position={[coordinata.latitude, coordinata.longitude]}
          icon={myIconlojas}
        >
          <Popup>
            Razao : {coordinata.razao}
            <br /> Loja : {coordinata.lojas}
          </Popup>
        </Marker>
      );
    });
  }

  const position = [-27.223789, -48.620961];

  //console.log(entregas);

  return (
    <div>
      <MapContainer center={position} zoom={10}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {(() => {
          if (veiculos) {
            return <MultipleMarkersVeiculos />;
          }
        })()}
        {(() => {
          if (destinos) {
            return <MultipleMarkersDestinos />;
          }
        })()}
      </MapContainer>
    </div>
  );
}
//styles
const useRowStyles = makeStyles({
  root: {
    //backgroundColor: 'red',
    height: 320,
    width: 650,
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
  },
});
