import React from "react";
import { GiTruck } from "react-icons/gi";

const Progress_bar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    marginTop: 5,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <GiTruck
          style={{
            fontSize: 30,
            color: "#191E4D",
          }}
        />
      </div>
    </div>
  );
};

export default Progress_bar;
