import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionIcon from "@material-ui/icons/Description";
import EmailIcon from "@material-ui/icons/Email";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { GiGrapes, GiCube, GiSlicedBread } from "react-icons/gi";
import AddCircleIconTrue from "@material-ui/icons/CheckCircle";
import AddCircleIconfalse from "@material-ui/icons/CheckCircleOutline";

import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import api from "../api";
import { getPainelProcessamentodemandafutura } from "../service/CRUD_Service";

export default function PainelProcessamento() {
  const [cargas, setCargas] = useState([]); //recebe do banco de dados
  const classes = useStyles();
  //busca dados api
  useEffect(() => {
    api
      .get("demandafuturaprocessamentoautomatico")
      .then((res) => {
        let carg = res.data;
        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = new Date(a.datasolicitamdfe).getTime();
          var d = new Date(b.datasolicitamdfe).getTime();
          return c - d; //data mais antiga para mais nova
        });
        setCargas(itens);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  useEffect(() => {
    let id = setInterval(() => {
      Buscaautomatico();
    }, 2000);
    return () => clearInterval(id);
  });

  async function Buscaautomatico() {
    let busca = await getPainelProcessamentodemandafutura();
    let dados1 = busca;
    //funcao para ordenar a lista por hora
    const itens = dados1.sort(function (a, b) {
      var c = new Date(a.datasolicitamdfe).getTime();
      var d = new Date(b.datasolicitamdfe).getTime();
      return c - d; //data mais antiga para mais nova
    });
    return setCargas(itens);
  }
  /* 
  //funcao para atualizar pagina automaticamente
  setTimeout(function () {
    window.location.reload(1);
  }, 20000); //5000

  //FUNCAO PARA EXECUTAR UM ALERTA SONORO
  const teste = () => {
    let audio = new Audio("../sound/atualiza.mp3");

    audio.play();
  };
*/
  //EXIBE DADOS DA TABELA
  function Row(props) {
    const { row } = props;
    const classes = useStyles();

    ///##################################################
    //retirar perfil repetido para exibição
    let perfil = row.cviagens.map((valor) => valor.perfil);

    //###########################################################
    //verifica se tem Horti, isso serve para mudar a cor de um item na lista
    let isEntrega = perfil.find((element) => element == "Horti"); //verifica se tem Horti
    let isEntregaFrios = perfil.find((element) => element == "Frios"); //verifica se tem Horti
    let isPadariaFrios = perfil.find(
      (element) => element == "Panificacao Frios"
    ); //verifica se tem Horti
    let isPadariaSeca = perfil.find((element) => element == "Panificacao Seca"); //verifica se tem Horti

    ///##################################################
    //retirar perfil repetido para exibição
    let nloja = row.cviagens.map((valor) => valor.fkdestino.lojas);
    let filtraloja = nloja.filter((este, i) => nloja.indexOf(este) === i);
    let exibinloja = "";
    for (let x of filtraloja) {
      exibinloja += x + " / ";
    }

    var quantidadeElementos = row.cviagens.filter((x) => x.id).length;

    //###########################################################

    return (
      <React.Fragment>
        <TableRow>
          <TableCell
            component="th"
            scope="row"
            className={classes.txtOperacaotabela}
          >
            {row.id}
          </TableCell>
          <TableCell component="th" scope="row" className={classes.txttabela}>
            {row.fkmotorista.nome}
          </TableCell>
          <TableCell align="center" className={classes.txttabela}>
            {row.fkmotorista.tipo}
          </TableCell>
          <TableCell align="center" className={classes.txttabela}>
            {row.fkveiculotrator.placa}
          </TableCell>
          <TableCell align="center" className={classes.txttabela}>
            {row.fkveiculo.placa}
          </TableCell>

          {(() => {
            // let origem = row.cviagens.fkorigem.descricao;
            let origem = [
              ...new Set(row.cviagens.map((x) => x.fkorigem.descricao)),
            ];

            return (
              <TableCell
                component="th"
                scope="row"
                className={classes.txtOperacaotabela}
              >
                {origem}
              </TableCell>
            );
          })()}
          <TableCell align="center" className={classes.txttabela}>
            <Typography
              style={{
                fontFamily: "inherit",
                fontWeight: "bold",
                color: "#40bf80",
                fontSize: 20,
              }}
            >
              {row.ncarga}
            </Typography>
          </TableCell>
          <TableCell align="center" className={classes.txttabela}>
            {quantidadeElementos}
          </TableCell>

          <TableCell align="center" className={classes.txttabela}>
            {isEntrega == "Horti" ? (
              <GiGrapes className={classes.iconHorti} />
            ) : isEntregaFrios == "Frios" ? (
              <AcUnitIcon className={classes.iconfrios} />
            ) : isPadariaFrios == "Panificacao Frios" ? (
              <GiSlicedBread className={classes.iconPadaria} />
            ) : isPadariaSeca == "Panificacao Seca" ? (
              <GiSlicedBread className={classes.iconPadaria} />
            ) : (
              <GiCube className={classes.iconNormal} />
            )}
          </TableCell>
          <TableCell align="center">
            {row.statuspredoc == null || row.statuspredoc == "Pendente" ? (
              <AddCircleIconfalse
                style={{ fontSize: 32 }}
                color="secondary"
              ></AddCircleIconfalse>
            ) : (
              <AddCircleIconTrue
                style={{ color: "#40BF80", fontSize: 32 }}
              ></AddCircleIconTrue>
            )}
          </TableCell>
          <TableCell align="center">
            {row.statusprocessamento == "Visualizado" ? (
              <VisibilityIcon className={classes.iconvisual}></VisibilityIcon>
            ) : (
              <VisibilityOffIcon
                className={classes.iconIsvisual}
              ></VisibilityOffIcon>
            )}
          </TableCell>
          <TableCell align="center">
            {row.statusnf == "Com NF" ? (
              <DescriptionIcon className={classes.iconvisual}></DescriptionIcon>
            ) : (
              <DescriptionIcon
                className={classes.iconIsvisual}
              ></DescriptionIcon>
            )}
          </TableCell>
          <TableCell align="center">
            {row.statusdoc == "Enviado" ? (
              <EmailIcon className={classes.iconvisual}></EmailIcon>
            ) : (
              <EmailIcon className={classes.iconIsvisual}></EmailIcon>
            )}
          </TableCell>
          <TableCell align="center" className={classes.txttabela}>
            {(() => {
              let horsea = new Date();
              let [diaa, mesa, anoa] = new Date()
                .toLocaleDateString("pt-BR")
                .split("/");
              let formatarhoraatual =
                diaa +
                "-" +
                mesa +
                "-" +
                anoa +
                " " +
                horsea.getHours() +
                ":" +
                horsea.getMinutes() +
                ":" +
                horsea.getSeconds();

              const format1 = "DD/MM/YYYY HH:mm:ss";

              //formatar data
              let timesolicitamdfe = new Date(row.datasolicitamdfe);
              let formatardatamdf = moment
                .utc(timesolicitamdfe)
                .format(format1);

              //teste de calculo tempo de cd
              var ms = moment(formatarhoraatual, "DD/MM/YYYY HH:mm:ss").diff(
                moment(formatardatamdf, "DD/MM/YYYY HH:mm:ss")
              );
              var d = moment.duration(ms);
              var tempo =
                Math.floor(d.asHours()) + moment.utc(ms).format(":mm");

              return (
                <Typography
                  style={{
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    color: "#4d1919",
                    fontSize: 20,
                  }}
                >
                  00:00
                </Typography>
              );
            })()}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography
          style={{
            fontSize: 30,
            color: "#e9eef3",
            backgroundColor: "#042353",
          }}
        >
          Demandas futuras
        </Typography>
        <TableContainer
          component={Paper}
          style={{ maxHeight: 400, minHeight: 300, overflowY: "auto" }}
        >
          <Table
            className={classes.table}
            aria-label="caption table"
            stickyHeader
          >
            <TableHead className={classes.heard}>
              <TableRow>
                <TableCell className={classes.txtheard}>ID Carga</TableCell>
                <TableCell className={classes.txtheard}>Motoristas</TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  Perfil Mt
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  Placa Cavalo
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  Placa Carreta
                </TableCell>
                <TableCell className={classes.txtheard}>Operacao</TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  Numero Carga
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  Qtd Entrega
                </TableCell>

                <TableCell align="center" className={classes.txtheard}>
                  Perfil
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  S.KMM
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  S.Carga
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  S.NF
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  S.Doc
                </TableCell>
                <TableCell align="center" className={classes.txtheard}>
                  Tempo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cargas.map((row) => (
                <Row row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // backgroundColor: 'red',
    borderRadius: 11,
    // border: ' 2px solid  #BFBFBF',
    boxShadow: "1px 10px 10px  #595959",
    minHeight: 200,
  },
  table: {
    minWidth: 650,
  },
  txttabela: {
    fontSize: 20,
  },
  txtOperacaotabela: {
    fontSize: 20,
    color: "#bf4040",
    fontFamily: "inherit",
    fontWeight: "bold",
  },
  heard: {
    backgroundColor: "#042353",
  },
  txtheard: {
    color: "#e9eef3",
    fontSize: 20,
    backgroundColor: "#042353",
  },
  iconIsvisual: {
    color: "red",
    fontSize: 32,
  },
  iconvisual: {
    color: "green",
    fontSize: 32,
  },
  iconfrios: {
    color: "#B4C4BC",
    fontSize: 33,
    fontWeight: "bold",
  },
  iconHorti: {
    color: "#194d33",
    fontSize: 33,
    fontWeight: "bold",
  },
  iconNormal: {
    color: "#fcb900",
    fontSize: 33,
    fontWeight: "bold",
  },
  iconPadaria: {
    color: "#fcdc00",
    fontSize: 33,
    fontWeight: "bold",
  },
}));
