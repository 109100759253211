//FALTA FAZER//
//1 COLOCAR UPERCASE NO CAMPO PLACA(CAIXA ALTA)
//

import React, { Component } from "react";
import api from "../api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import Style_login from "../style/style_login.css";

class veiculos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nfrota: "",
      placa: "",
      marca: "",
      cor: "",
      status: "",
      vinculo: "",
      qtdpallet: "",
      comprimento: "",
      tipoveiculo: "",
      tipocarroceria: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post("/veiculos", this.state)
      .then((response) => {
        console.log(response);
        alert("PLACA SALVA COM SUCESSO!!! ");
        window.location = "/listarveiculo";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      nfrota,
      placa,
      marca,
      cor,
      status,
      vinculo,
      qtdpallet,
      comprimento,
      tipoveiculo,
      tipocarroceria,
    } = this.state;

    return (
      <body className={Style_login.body}>
        <div>
          <div>
            <br></br>
            <br></br>
            <Paper
              variant="outlined"
              elevation={4}
              style={{
                backgroundColor: "#ffff",
                width: 840,
                marginTop: -20,
                marginLeft: 230,
                height: 480,
              }}>
              <form
                style={{
                  backgroundColor: "#ffff",
                  width: 800,
                  marginLeft: 10,
                  marginTop: 20,
                  height: 450,
                }}
                onSubmit={this.submitHandler}>
                <br></br>
                <InputLabel
                  style={{ marginLeft: 300, fontSize: 25 }}
                  id="demo-simple-select-helper-label">
                  Adicionar Veiculo
                </InputLabel>

                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 110,
                      }}
                      required
                      id="standard-required"
                      label="Nº Frota"
                      placeholder="00"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="nfrota"
                      value={nfrota}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 120,
                      }}
                      required
                      id="standard-required"
                      label="Placa"
                      placeholder="ZZZ-0000"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="placa"
                      value={placa}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        marginTop: 7,
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 130,
                      }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Marca
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        margin="dense"
                        label="Marca"
                        name="marca"
                        value={marca}
                        onChange={this.changeHandler}>
                        <MenuItem value="">
                          <em>Marca ...</em>
                        </MenuItem>

                        <MenuItem value={"Scania"}>Scania</MenuItem>
                        <MenuItem value={"Iveco"}>Iveco</MenuItem>
                        <MenuItem value={"Volvo"}>Volvo</MenuItem>
                        <MenuItem value={"DAF"}>DAF</MenuItem>
                        <MenuItem value={"Mercedez-Benz"}>
                          Mercedez-Benz
                        </MenuItem>
                        <MenuItem value={"Linshalm"}>Linshalm</MenuItem>
                        <MenuItem value={"Ibipora"}>Ibipora</MenuItem>
                        <MenuItem value={"Facchini"}>Facchini</MenuItem>
                        <MenuItem value={"Termossara"}>Thermosara</MenuItem>
                        <MenuItem value={"Thermosul "}>Thermosul</MenuItem>
                        <MenuItem value={"Librelato"}>Librelato</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 110,
                      }}
                      required
                      id="standard-required"
                      label="Cor"
                      placeholder="XXXXX"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="cor"
                      value={cor}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 120,
                      }}
                      required
                      id="standard-required"
                      id="outlined-textarea"
                      label="Qtd Pallet"
                      placeholder="00"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="qtdpallet"
                      value={qtdpallet}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 130,
                      }}
                      required
                      id="standard-required"
                      id="outlined-textarea"
                      label="Comprimento"
                      placeholder="00.00"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="search"
                      name="comprimento"
                      value={comprimento}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>

                <br></br>

                {/* CAMPOS DE SELEÇÃO  */}
                <Grid container spacing={3}>
                  {/* CAMPO TIPO DO VEICULO */}
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 110,
                      }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Tipo Veiculo
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        margin="dense"
                        label="Tipo Veiculo"
                        name="tipoveiculo"
                        value={tipoveiculo}
                        onChange={this.changeHandler}>
                        <MenuItem value="">
                          <em>Tipo Veiculo</em>
                        </MenuItem>

                        <MenuItem className="Truck" value={"CT"}>
                          CT
                        </MenuItem>
                        <MenuItem
                          className="semi-reboque-termico"
                          value={"SRT"}>
                          SRT
                        </MenuItem>
                        <MenuItem className="semi-reboque" value={"SR"}>
                          SR
                        </MenuItem>
                        <MenuItem
                          className="semi-reboque-frigorifica"
                          value={"SRF"}>
                          SRF
                        </MenuItem>
                        <MenuItem
                          className="Caminhao-trator-cavalo"
                          value={"CTC"}>
                          CTC
                        </MenuItem>
                        <MenuItem
                          className="Caminhao-leve-frigorifica"
                          value={"CLF"}>
                          CLF
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* CAMPO TIPO CARROCERIA */}
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 120,
                      }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Tipo Carroceria
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        margin="dense"
                        label="Tipo Carroceria"
                        name="tipocarroceria"
                        value={tipocarroceria}
                        onChange={this.changeHandler}>
                        <MenuItem value="">
                          <em>Tipo Carroceria</em>
                        </MenuItem>

                        <MenuItem value={"Indefinido"}>Indefinido</MenuItem>
                        <MenuItem value={"Bau"}>Bau</MenuItem>
                        <MenuItem value={"Sider"}>Sider</MenuItem>
                        <MenuItem value={"Frigorifico"}>Frigorifico</MenuItem>
                        <MenuItem value={"Termico"}>Termico</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* CAMPO TIPO VINCULO */}
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 130,
                      }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Vinculo
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        margin="dense"
                        label="Status"
                        name="vinculo"
                        value={vinculo}
                        onChange={this.changeHandler}>
                        <MenuItem value="">
                          <em>Vinculo</em>
                        </MenuItem>

                        <MenuItem value={"Frota"}>Frota</MenuItem>
                        <MenuItem value={"Agregado"}>Agregado</MenuItem>
                        <MenuItem value={"Carreteiro"}>Carreteiro</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* CAMPO TIPO VINCULO */}
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 110,
                        marginTop: 10,
                      }}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Status
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        margin="dense"
                        label="Status"
                        name="status"
                        value={status}
                        onChange={this.changeHandler}>
                        <MenuItem value="">
                          <em>Status</em>
                        </MenuItem>

                        <MenuItem value={"Ativo"}>Ativo</MenuItem>
                        <MenuItem value={"Manutencao"}>Manutencao</MenuItem>
                        <MenuItem value={"Off"}>Off</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Button
                  style={{ marginLeft: 360, height: 30, marginTop: 60 }}
                  variant="contained"
                  color="primary"
                  type="submit">
                  Salvar
                </Button>
              </form>
            </Paper>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </body>
    );
  }
}

export default veiculos;
