import React from 'react';

//APIS MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';

import Grid from '../componentes/grid';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    // backgroundColor: 'ghostwhite ', cor antiga
    backgroundColor: '#E6E6E6',
  },
}));

export default function DashFrota() {
  const classes = useStyles();

  return (
    <>
      <container>
        <div className={classes.root}>
          <Grid />
        </div>
      </container>
    </>
  );
}
