import React from 'react';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import StorageIcon from '@material-ui/icons/Storage';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Link } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout, getTipoUsuario } from '../service/authService';

export default function MainListItems() {
  const [open, setOpen] = React.useState(false);
  const [exportacoes, setExportacoes] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickexportacoes = () => {
    setExportacoes(!exportacoes);
  };

  return (
    <div>
      {/* PRIMEIRO NOME */}
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/Dashaboard' tyle={{ color: '#fff' }}>
          Dashboard Frota
        </Link>
      </ListItem>

      {/* PRIMEIRO NOME */}
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/DashaboardCarga' style={{ color: '#fff' }}>
          Dashboard Carga
        </Link>
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <StorageIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/listakoch' style={{ color: '#fff' }}>
          Entregas
        </Link>
      </ListItem>

      {/* SEGUNDO NOME */}
      <ListItem button>
        <ListItemIcon>
          <StorageIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/relatorios' style={{ color: '#fff' }}>
          Entregas Pendentes
        </Link>
      </ListItem>

      {/* TERCEIRO NOME */}
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/manifesto' style={{ color: '#fff' }}>
          Manifesto
        </Link>
      </ListItem>

      {/* QUARTO  NOME */}
      <ListItem button>
        <ListItemIcon>
          <NoteAddIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/PainelProcessamento' style={{ color: '#fff' }}>
          Painel Processamento
        </Link>
      </ListItem>

      {/* QUIANTO NOME */}
      <ListItem button>
        <ListItemIcon>
          <NoteAddIcon style={{ color: '#fff' }} />
        </ListItemIcon>
        <Link to='/Mapa' style={{ color: '#fff' }}>
          Mapa
        </Link>
      </ListItem>

      <div>
        {/* TERCEIRO NOME */}
        <ListItem button>
          <ListItemIcon></ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider></Divider>
      </div>
    </div>
  );
}
