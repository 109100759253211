//FALTA FAZER//

//COMPONENSTE REACT
import React, { useState, useEffect } from "react";
//COMPONENTES MATERIAL
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import LinearProgress from "@mui/material/LinearProgress";

import api from "../../api";

export default function Origem() {
  const history = useHistory("");
  const [names, setNames] = useState([]); //set principal

  const [loading, setLoading] = useState(false); //set para antes de carregar a pagina aparecer loading
  const [page, setPage] = React.useState(0); //set para paginas
  const [rowsPerPage, setRowsPerPage] = React.useState(50); //set para numeros de paginas.

  const [open, setOpen] = React.useState(false);

  //FORMATAR DATA
  const formatDate = () => {
    let data = new Date();
    let data2 = new Date(data.valueOf() - data.getTimezoneOffset() * 60000);
    return data2.toISOString().replace(/\.\d{3}Z$/, "");
  };
  const [date, setDate] = useState(new Date(formatDate()));
  //handle de paginas
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  //incluir origem
  useEffect(() => {
    api
      .get("/exportcsvstatusControllers")
      .then((res) => {
        let dest = res.data;

        setNames(dest);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // FAZ O LOADING ANTES DE CARREGAR OS DADOS
  if (loading) {
    return <p>Loading Entregas.......</p>;
  }

  const columns = [
    { field: "ids", headerName: "ID viagem", width: 90 },
    { field: "operacao", headerName: "Operacao", width: 160 },
    { field: "descricao", headerName: "Descricao da origem ", width: 160 },
    { field: "placa", headerName: "Placa", width: 90 },
    {
      field: "nrfrota",
      headerName: "Nº Frota",
      width: 80,

      //editable: true,
    },
    {
      field: "loja",
      headerName: "Loja",
      // type: 'number',
      width: 80,
      //   editable: true,
    },
    {
      field: "perfil",
      headerName: "Perfil",
      width: 100,
      // editable: true,
    },
    {
      field: "qtdpallet",
      headerName: "Qtd Pallet",
      width: 80,
      // editable: true,
    },

    {
      field: "entradacd",
      headerName: "Dt Entrada CD",
      width: 170,
      // editable: true,
    },
    {
      field: "creatAt",
      headerName: "Dt Criacao Entrega",
      width: 170,
      // editable: true,
    },
    {
      field: "saidacd",
      headerName: "Dt Saida CD",
      // type: 'number',
      width: 170,
      //   editable: true,
    },

    {
      field: "entradaloja",
      headerName: "Dt Entrada Loja",
      // type: 'number',
      width: 170,
      //  editable: true,
    },
    {
      field: "Dtentrega",
      headerName: "Dt Entrega Loja",
      //   type: 'number',
      width: 170,
      //  editable: true,
    },
    {
      field: "Dtsaidaloja",
      headerName: "Dt Saida Loja",
      //   type: 'number',
      width: 170,
      //  editable: true,
    },
    {
      field: "retornobase",
      headerName: "Dt Retorno CD",
      // type: 'number',
      width: 170,
      //   editable: true,
    },

    {
      field: "tempocd",
      headerName: "Tempo no CD",
      //  type: 'number',
      width: 110,
      //   editable: true,
    },
    {
      field: "tempoloja",
      headerName: "Tempo Loja",
      //  type: 'number',
      width: 110,
      //   editable: true,
    },
  ];

  const teste = () => {
    var results = [];
    var len = names.length;
    for (var i = 0; i < len; i++) {
      //  console.log(names[i].idstatuscd);
      //formatar data entrada cd
      const format1 = "DD/MM/YYYY HH:mm:ss";
      let horseteste = new Date(names[i].dtentrada);

      let formatarentradacd = moment.utc(horseteste).format(format1);

      //data saida cd
      let timesaidacd = new Date(names[i].dtsaida);
      let formatarsaidacd = moment.utc(timesaidacd).format(format1);

      //data entrada loja
      let timeentradaloja = new Date(names[i].dtchegadaloja);
      let formatarentradaloja = moment.utc(timeentradaloja).format(format1);

      //data saida de loja
      let timesaidaloja = new Date(names[i].dtsaidaloja);
      let formatasaidaloja = moment.utc(timesaidaloja).format(format1);

      //data retorno cd
      let timeretornocd = new Date(names[i].retornobase);
      let formataretornocd = moment.utc(timeretornocd).format(format1);

      //formatar data criacao da entrega
      let timecreate = new Date(names[i].created_at);
      let formatacreate = moment.utc(timecreate).format(format1);

      //formatar data entrega loja
      let timeentrega = new Date(names[i].dataentrega);
      let formataentrega = moment.utc(timeentrega).format(format1);

      //teste de calculo tempo de cd
      var ms = moment(formatarsaidacd, "DD/MM/YYYY HH:mm:ss").diff(
        moment(formatarentradacd, "DD/MM/YYYY HH:mm:ss")
      );
      var d = moment.duration(ms);
      var formatar = Math.floor(d.asHours()) + moment.utc(ms).format(":mm");

      //teste de calculo tempo de cd
      let entradaloja = new Date(timeentradaloja);
      let saidaloja = new Date(timesaidaloja);
      let diferencaloja = new Date(saidaloja - entradaloja);
      var resultadoloja =
        ("0" + diferencaloja.getUTCHours()).slice(-2) +
        ":" +
        ("0" + diferencaloja.getUTCMinutes()).slice(-2);

      //teste de calculo tempo de cd
      let entradaloja1 = new Date(timeentradaloja);
      let saidaloja1 = new Date();
      let diferencaloja1 = new Date(saidaloja1 - entradaloja1);
      var resultadoloja1 =
        ("0" + diferencaloja1.getUTCHours()).slice(-2) +
        ":" +
        ("0" + diferencaloja1.getUTCMinutes()).slice(-2);

      results.push({
        id: names[i].id,
        ids: names[i].viagem,
        operacao: names[i].operacao,
        descricao: names[i].descricao,
        placa: names[i].placa,
        nrfrota: names[i].nfrota,
        loja: names[i].lojas,
        perfil: names[i].perfil,
        qtdpallet: names[i].qtdpallet,
        entradacd: formatarentradacd,
        creatAt: formatacreate,
        saidacd: formatarsaidacd,
        entradaloja: formatarentradaloja,
        Dtentrega: formataentrega,
        Dtsaidaloja: names[i].dtsaidaloja == null ? "" : formatasaidaloja,
        retornobase: names[i].retornobase == null ? "" : formataretornocd,

        tempocd: formatar,
        tempoloja:
          names[i].dtsaidaloja == null ? resultadoloja1 : resultadoloja,
      });
    }
    return results;
  };
  const rows = teste();

  return (
    <div className="Destino">
      <div>
        <AppBar position="relative" color="default">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Tempo de Entregas
            </Typography>
            <ListItem
              button
              style={{
                width: 25,
                height: 25,
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></ListItem>
          </Toolbar>
        </AppBar>
        <br></br>
      </div>
      {/* 
      <Paper
        style={{
          marginLeft: 10,
          marginRight: 10,
          height: 40,
          marginBottom: 15,
          flexdirection: 'row',
        }}
      ></Paper>
       */}
      <Paper style={{ marginLeft: 10, marginRight: 10 }}>
        <Box sx={{ height: 500, width: "100%" }}>
          {names.length <= 0 ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection
              disableSelectionOnClick
              localeText={{
                toolbarColumns: "Colunas",
                toolbarFilters: "Filtros",
                toolbarDensity: "Densidade",
                toolbarExport: "Exportar",
              }}
              components={{ Toolbar: GridToolbar }}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
}
