import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import Popover from "@mui/material/Popover";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";

//filtro
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FilterListIcon from "@material-ui/icons/FilterList";

//COMPONENTES INTERNOS

import Cardmanutencao from "../componentes/Card_manutencao";
import CardcarretaPatio from "../componentes/Card_carretaPatio";
import Carddocas from "../componentes/Card_carretaDoca";
import CardTransito from "../componentes/Card_carretaTransito";
import Cardlojas from "../componentes/Card_carretaLojas";
import CardcarretasSR from "../componentes/Card_carretasSR";
import CardCarretaDisponiveis from "../componentes/card"; //verificar se vai utilizar mais tarde caso contrario excluir
import CardVeiculos from "../componentes/card_veiculos"; //verificar se vai utilizar mais tarde caso contrario excluir
import CardcarretasSRF from "../componentes/Card_carretasrf"; //verificar se vai utilizar mais tarde caso contrario excluir
import CardcarretasSRT from "../componentes/Card_CarretasSRT"; //verificar se vai utilizar mais tarde caso contrario excluir

//TABELAS DASH
import Dashtabelaveiculoscd from "./Dashtabelaveiculoscd";
import Dashtabelaveiculotransito from "./Dashtabelaveiculostransito";
import Dashtabelaveiculosdisponiveiscd from "./Dashtabelaveiculodisponiveiscd";
import Dashtabelaveiculoslojas from "./Dashtabelaveiculoslojas";
import Dashtabelaveiculoretorno from "./Dashtabelaveiculoretorno";

import Mapa from "../paginas/Mapa";

import { logout, getOperacao } from "../service/authService";
import api from "../api";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#BEC3C9" : "#ffff",
  ...theme.typography.body2,
  // padding: 2,
  textAlign: "center",
  boxShadow: "15px",
  color: theme.palette.text.secondary,
  borderRadius: 11,
}));

const useStyles = makeStyles((theme) => ({
  box1: {
    backgroundColor: "#E6E6E6",
  },
  gridveiculos: {
    marginTop: 10,
    padding: 1,
  },
  gridcard: {
    padding: 6,
    boxShadow: "15px",
    borderRadius: 11,
  },
  box: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "400px",
  },
  innerContainer: {
    height: "100%",
  },
  item: {
    flex: 1,
  },

  gridcd: {
    minHeight: "570px",
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",
    marginBottom: 10,

    margin: 5,
  },
  gridcdPassarela: {
    minHeight: "820px",
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",
    marginBottom: 10,

    margin: 5,
  },
  gridkoch: {
    minHeight: "570px",
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",
    marginBottom: 10,

    margin: 5,
  },
  gridtransitos: {
    minHeight: "573px",
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",
    marginBottom: 10,
    //  maxWidth: 710,
    margin: 5,
  },
  gridlojas: {
    minHeight: "813px",
    // width: 390,
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",
    margin: 5,

    // Media query para dispositivos menores que 680px
    "@media (max-width: 480px)": {
      minWidth: "690px", // Ocupará 95% da largura da tela
      width: "690px", // Definindo o width também para garantir ajuste
    },
  },
  griddisponiveiscd: {
    minHeight: "232px",
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",
    // width: 390,
    margin: 5,
  },
  gridretorno: {
    minHeight: "233px",
    // maxWidth: 660,
    //  maxWidth: 710,
    borderRadius: 11,
    boxShadow: "1px 5px 10px #595959",

    margin: 5,
  },
  img: {
    height: 160,
    width: 230,
    marginLeft: -2,
    marginTop: -30,
    marginBottom: -25,
    // alignContent: 'center',
    //justifyContent: 'center',
  },
}));

export default function ResponsiveGrid() {
  const [cadOperacoes, setCadOperacoes] = useState([]);
  const [operacaoSelecionada, setOperacaoSelecionada] = useState("");
  const [origem, setOrigem] = useState();
  const [origemfiltro, setOrigemfiltro] = useState();

  //incluir origem
  useEffect(() => {
    api
      .get("/origem")
      .then((res) => {
        let carg = res.data;

        let filterCerca = carg.filter((x) => x.cerca !== null);

        let pegaid = filterCerca.map(function (x) {
          return {
            id: x.id,
            descricao: x.descricao,
            Cad_operacoes: x.Cad_operacoes,
          };
        });

        setOrigem(pegaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const operacoes = getOperacao();
    if (operacoes) {
      setCadOperacoes(operacoes);

      // Encontra a operação com o nome "Koch - CD"
      const kochCdOperacao = operacoes.find((op) => op.id === 3);
      if (kochCdOperacao) {
        // Define o ID da operação selecionada como o ID da operação "Koch - CD"
        setOperacaoSelecionada(kochCdOperacao.id);
      } else {
        const operacaoPadrao = operacoes[0];
        setOperacaoSelecionada(operacaoPadrao.id);
      }
    }
  }, []);

  const handleOperacaoChange = (event) => {
    setOperacaoSelecionada(event.target.value);
  };

  const handleOrigemChange = (event) => {
    setOrigemfiltro(event.target.value);
  };

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //origem
  const [anchororigem, setAnchororigem] = React.useState(null);

  const handleClickorigem = (event) => {
    setAnchororigem(event.currentTarget);
  };

  const handleCloseorigem = () => {
    setAnchororigem(null);
  };

  const openorigem = Boolean(anchororigem);
  const idorigem = openorigem ? "simple-popover" : undefined;

  // Recupera os dados do localStorage
  //const cadOperacoes = getOperacao();

  var ConverteOperacaotipo = Number(operacaoSelecionada);

  const filtraorigem = () => {
    let op = Number(operacaoSelecionada);
    let todos = [{ id: 999, descricao: "Todas", Cad_operacoes: [] }];

    // Filtra os itens que possuem `fkoperacoes` igual ao valor de `op`
    let resultado = origem?.filter((item) =>
      item.Cad_operacoes.some(
        (operacao) => operacao.agrupaoperacoesorigens.fkoperacoes === op
      )
    );

    // Garantindo que 'filtrado' seja sempre um array válido
    const filtradoValido = Array.isArray(resultado) ? resultado : [];

    // Agrupando 'todos' e o resultado filtrado
    const resultadoAgrupado = [...todos, ...filtradoValido];

    return resultadoAgrupado;
  };

  let filtroorigem = filtraorigem();

  //ajuste do tamanho do dash
  const className =
    operacaoSelecionada === 3 ? classes.gridkoch : classes.gridcdPassarela;

  return (
    <Box sx={{ flexGrow: 1 }} className={classes.box1}>
      <Grid
        //justifyContent='space-between'
        container
        spacing={{ xs: 2, md: 3 }}
        // columns={{ xs: 2, sm: 7,  md: 7 }}
      >
        <Grid item xs className={classes.gridcard}>
          <Item
            style={{
              height: 102,
              background: "#191E4D",
              justifyItems: "center",
              justifyContent: "center",
              alignItems: "center",
              border: " 2px solid  #F4F6F9",
              borderRadius: 11,
              boxShadow: "1px 10px 10px #595959",
            }}
          >
            <img src={require("../imagem/Logo.png")} className={classes.img} />
          </Item>
        </Grid>
        {/*
           OPERACAO 3 É REFERENTE A OPERACAO KOCH -CD EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 3 && (
          <>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Cardmanutencao
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardcarretasSR
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
                {/*card patio str */}
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardVeiculos
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
                {/*card caminhao trator disponivel*/}
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardcarretaPatio
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Carddocas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardTransito
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Cardlojas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
          </>
        )}
        {/*
           OPERACAO 1 É REFERENTE A OPERACAO PANIFICACAO EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 1 && (
          <>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Cardmanutencao
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>

            <Grid item xs className={classes.gridcard}>
              <Item>
                <Carddocas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardTransito
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Cardlojas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
          </>
        )}
        {/*
           OPERACAO 2 É REFERENTE A OPERACAO PASSARELA EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 2 && (
          <>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Cardmanutencao
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>

            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardVeiculos
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
                {/*card caminhao trator disponivel*/}
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardcarretaPatio
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Carddocas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <CardTransito
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs className={classes.gridcard}>
              <Item>
                <Cardlojas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
          </>
        )}
      </Grid>

      {/*EXIBICAO DE TABELAS  */}
      <Box sx={{ flexGrow: 1, marginTop: 1, backgroundColor: "#E6E6E6" }}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="success"
            style={{ marginLeft: 10, height: 20 }}
            onClick={handleClick}
            startIcon={<FilterListIcon />}
          >
            Filtro Operacao
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPaper-root": {
                width: 300, // largura do Popover
                maxHeight: 700, // altura máxima do Popover
                paddingLeft: 3,
              },
            }}
          >
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Operacoes
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={operacaoSelecionada}
                onChange={handleOperacaoChange}
                name="radio-buttons-group"
              >
                {cadOperacoes.map((op, index) => (
                  <FormControlLabel
                    key={index}
                    value={op.id}
                    control={<Radio />}
                    label={op.tpoperacao}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Popover>
          <Button
            variant="contained"
            color="success"
            style={{ marginLeft: 10, height: 20 }}
            onClick={handleClickorigem}
            startIcon={<FilterListIcon />}
          >
            Filtro Origem
          </Button>
          <Popover
            id={idorigem}
            open={openorigem}
            anchorEl={anchororigem}
            onClose={handleCloseorigem}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              "& .MuiPaper-root": {
                width: 300, // largura do Popover
                maxHeight: 700, // altura máxima do Popover
                paddingLeft: 3,
              },
            }}
          >
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Origens</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={origemfiltro}
                onChange={handleOrigemChange}
                name="radio-buttons-group"
              >
                {filtroorigem?.map((op, index) => (
                  <FormControlLabel
                    key={index}
                    value={op.id}
                    control={<Radio />}
                    label={op.descricao}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Popover>
        </Stack>
      </Box>
      <Grid
        //  sx={{ height: '100%' }}
        style={{
          marginTop: 10,
        }}
        container
        spacing={{ xs: 1, sm: 2, lg: 2 }}
        //  columns={{ xs: 2, sm: 4, md: 4, lg: 4 }}
      >
        {/*
           OPERACAO 3 É REFERENTE A OPERACAO KOCH -CD EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 3 && (
          <>
            <Grid>
              <Item className={classes.gridlojas}>
                <Dashtabelaveiculoslojas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs>
              <Item className={classes.gridtransitos}>
                <Dashtabelaveiculotransito
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>

              <Item className={classes.gridretorno}>
                <Dashtabelaveiculoretorno
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs>
              <Item className={className}>
                <Dashtabelaveiculoscd
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>

              <Item className={classes.griddisponiveiscd}>
                <Dashtabelaveiculosdisponiveiscd
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
          </>
        )}
        {/*
           OPERACAO 2 É REFERENTE A OPERACAO PASSARELA EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 2 && (
          <>
            <Grid>
              <Item className={classes.gridlojas}>
                <Dashtabelaveiculoslojas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs>
              <Item className={classes.gridtransitos}>
                <Dashtabelaveiculotransito
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>

              <Item className={classes.gridretorno}>
                <Dashtabelaveiculoretorno
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs>
              <Item className={className}>
                <Dashtabelaveiculoscd
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
          </>
        )}

        {/*
           OPERACAO 1 É REFERENTE A OPERACAO PANIFICACAO EM PRODUÇÃO
             */}
        {ConverteOperacaotipo === 1 && (
          <>
            <Grid>
              <Item className={classes.gridlojas}>
                <Dashtabelaveiculoslojas
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs>
              <Item className={classes.gridtransitos}>
                <Dashtabelaveiculotransito
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>

              <Item className={classes.gridretorno}>
                <Dashtabelaveiculoretorno
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
            <Grid item xs>
              <Item className={className}>
                <Dashtabelaveiculoscd
                  operacoes={operacaoSelecionada}
                  gridorigem={origemfiltro}
                />
              </Item>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
