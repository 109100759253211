//FALTA FAZER//
//1 COLOCAR UPERCASE NO CAMPO PLACA(CAIXA ALTA)
//

import React, { Component } from "react";
import api from "../api";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import Style_login from "../style/style_login.css";

class origem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codigo: "",
      razao: "",
      cnpj: "",
      descricao: "",
      rua: "",
      numero: "",
      cep: "",
      municipio: "",
      bairro: "",
      uf: "",
      longitude: "",
      latitude: "",
      cerca: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    api
      .post("/origem", this.state)
      .then((response) => {
        console.log(response);
        alert("ORIGEM SALVA COM SUCESSO!!! ");
        window.location = "/ListarOrigem";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      codigo,
      razao,
      cnpj,
      descricao,
      rua,
      numero,
      cep,
      municipio,
      bairro,
      uf,
      longitude,
      latitude,
      cerca,
    } = this.state;

    return (
      <body className={Style_login.body}>
        <div>
          <div>
            <br></br>
            <br></br>
            <Paper
              variant="outlined"
              elevation={4}
              style={{
                backgroundColor: "#ffff",
                width: 890,
                height: 450,
                marginTop: -20,
                marginLeft: 230,
              }}
            >
              <form
                style={{
                  backgroundColor: "#ffff",
                  width: 800,
                  marginLeft: 10,
                  marginTop: 40,
                }}
                onSubmit={this.submitHandler}
              >
                <InputLabel
                  style={{ marginLeft: 320, fontSize: 25 }}
                  id="demo-simple-select-helper-label"
                >
                  Adicionar Origem
                </InputLabel>

                <Grid container spacing={3}>
                  <Grid item xs={6} sm={1}>
                    <TextField
                      style={{
                        width: 80,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 75,
                        marginTop: 40,
                      }}
                      required
                      id="standard-required"
                      label="Codigo"
                      placeholder="00"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="codigo"
                      value={codigo}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <TextField
                      style={{
                        width: 340,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 140,
                        marginTop: 40,
                      }}
                      required
                      id="standard-required"
                      label="Razao Social"
                      placeholder="******"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="razao"
                      value={razao}
                      onChange={this.changeHandler}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 400,
                        marginTop: 40,
                      }}
                      required
                      id="standard-required"
                      label="CNPJ"
                      placeholder="00.000.000/0001-00"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="cnpj"
                      value={cnpj}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>

                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 350,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 75,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Rua"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="rua"
                      value={rua}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 70,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 275,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Nº"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="numero"
                      value={numero}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 195,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Bairro"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="bairro"
                      value={bairro}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 75,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="CEP"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="cep"
                      value={cep}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 260,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 85,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Municipio"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="municipio"
                      value={municipio}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 195,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="UF"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="uf"
                      value={uf}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 170,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 75,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Descricao"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="descricao"
                      value={descricao}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 260,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 85,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Longitude"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="longitude"
                      value={longitude}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      style={{
                        width: 200,
                        backgroundColor: "#f5f5dc",
                        marginLeft: 195,
                        marginTop: 5,
                      }}
                      required
                      id="standard-required"
                      label="Latitude"
                      placeholder="xxx"
                      multiline
                      variant="outlined"
                      margin="dense"
                      type="number"
                      name="latitude"
                      value={latitude}
                      onChange={this.changeHandler}
                    />
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        style={{
                          width: 200,
                          backgroundColor: "#f5f5dc",
                          marginLeft: 78,
                          marginTop: 5,
                        }}
                        id="standard-required"
                        label="Cerca"
                        placeholder="xxx"
                        multiline
                        variant="outlined"
                        margin="dense"
                        type="number"
                        name="cerca"
                        value={cerca}
                        onChange={this.changeHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br></br>
                <br></br>

                <Button
                  style={{
                    marginLeft: 370,
                    height: 30,
                    marginTop: 20,
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Salvar
                </Button>
              </form>
            </Paper>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </body>
    );
  }
}

export default origem;
