import React from "react";
import Typography from "@material-ui/core/Typography";
import { getTipoUsuario } from "../service/authService";

import Administrador from "../paginas/TelaInicial/listar"; // tipo 1
import Motorista from "../paginas/AvisoEntrada"; // tipo 2
import Koch from "../paginas/Koch/ListaCargaKoch"; // tipo 3
import Conferentestrkoch from "../paginas/ConferentestrKoch"; // tipo 4
import ADMSTR from "../paginas/Manifesto"; // tipo 5
import CordenadorKoch from "../paginas/Koch/ListaCargaKoch"; // tipo 6

export default function () {
  function getDash() {
    switch (getTipoUsuario()) {
      case "1":
        return <Administrador />;
      case "2":
        return <Motorista />;
      case "3":
        return <Koch />;
      case "4":
        return <Conferentestrkoch />;
      case "5":
        return <ADMSTR />;
      case "6":
        return <CordenadorKoch />;
    }
  }
  return (
    <div>
      <Typography></Typography>

      {getDash()}
    </div>
  );
}
