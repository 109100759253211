import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { FaCubes } from "react-icons/fa";
import Paper from "@material-ui/core/Paper";

import api from "../api";
import { getEntregasPendentes } from "../service/CRUD_Service";

const useStyles = makeStyles({
  title: {
    fontSize: 12,
    marginTop: -6,
    // marginLeft: 40,
    color: "#ffff",
  },
  icon: {
    fontSize: 30,
    color: "	#008000",
    marginTop: 10,
    marginLeft: -100,
  },
  dividir: {
    border: 10,
    backgroundColor: "#ffff",
    marginTop: -15,
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -60,
    marginLeft: 42,
  },
});

export default function CardTotalEntreguesPendente({ datafiltra, operacoes }) {
  const classes = useStyles();
  const [totalCarga, setTotalCarga] = useState();

  //incluir veiculo
  useEffect(() => {
    api
      .get("/CardEntregaspendentes")
      .then((res) => {
        let carg = res.data[0];

        setTotalCarga(carg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let id = setInterval(() => {
      Buscaautomatico().then();
    }, 60000);
    return () => clearInterval(id);
  });

  async function Buscaautomatico() {
    let busca = await getEntregasPendentes();
    let dados1 = busca[0];

    return setTotalCarga(dados1);
  }

  function filtraManualOperacaoes() {
    let d = totalCarga; // recebe os dados apos filtro de data
    let Op = Number(operacoes); // garante que o tipo de dado vai ser sempre numero
    let filtro = d?.filter((x) => x.id === Op); // manipula a operacao de acordo com filtro
    // Somar as quantidades de 'count'
    let totalCount = filtro?.reduce((sum, item) => {
      return sum + parseInt(item.qtd_entrega);
    }, 0);

    return totalCount;
  }

  let DadosFiltroOperacao = filtraManualOperacaoes();
  setInterval(DadosFiltroOperacao, 60000);

  return (
    <Paper
      elevation={16}
      style={{
        height: 98,
        background: "#191E4D",
        justifyItems: "center",
        justifyContent: "center",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <FaCubes className={classes.icon} />
      <CardContent>
        <Typography className={classes.qtd}>
          {DadosFiltroOperacao == 0 ? 0 : DadosFiltroOperacao}
        </Typography>
      </CardContent>

      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Typography className={classes.title}>Entregas Pendentes</Typography>
      </CardActions>
    </Paper>
  );
}
